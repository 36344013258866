import { Observable, Subject, of, ReplaySubject } from 'rxjs';
import * as jwt_decode from 'jwt-decode';
import { TOKENCONSTANTS } from './token.constants';

export class AuthenticationBase {
  public authenticated$: Observable<boolean>;
  protected authenticatedSubject: Subject<boolean> = new ReplaySubject(1);

  constructor() {
    this.authenticated$ = this.authenticatedSubject.asObservable();
  }

  isAuthenticated = (): boolean => {
    if (!localStorage) {
      console.log('no localStorage');
      return false;
    }
    if (!localStorage.getItem('expires_at')) {
      return false;
    }
    // Check whether the current time is past the
    // Access Token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));

    return new Date().getTime() < expiresAt;
  };

  public getAuth0UserInfo = () => {
    return this.getUserInfo();
  };

  public getAuth0Id() {
    try {
      return this.getUserInfo()[TOKENCONSTANTS.AUTH0ID];
    } catch (err) {
      return undefined;
    }
  }

  public getUserId() {
    try {
      return this.getUserInfo()[TOKENCONSTANTS.USERID];
    } catch (err) {
      return undefined;
    }
  }

  private getUserInfo() {
    try {
      const token = localStorage.getItem('id_token');
      return jwt_decode(token);
    } catch (err) {
      return undefined;
    }
  }
}
