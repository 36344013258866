import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'sonorus-previous-next',
  templateUrl: './previous-next.component.html',
  styleUrls: ['./previous-next.component.scss']
})
export class PreviousNextComponent implements OnInit {

  @Input()
  hasNext: boolean;
  @Input()
  hasPrevious: boolean;

  @Output()
  next = new EventEmitter();
  @Output()
  previous = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onNextClicked(){
    this.next.emit();
  }

  onPreviousClicked(){
    this.previous.emit();
  }

}
