<!--
  Generated template for the GridsterTestSettingsPage page.

  See http://ionicframework.com/docs/components/#navigation for more info on
  Ionic pages and navigation.
-->

<ion-header>
  <ion-toolbar>
    <ion-title>{{'PAGE_EDIT_STRUCTURE'| translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-list lines="none">
    <form [formGroup]="
            settingsForm">
      <ion-item lines="full">
        <ion-label color="primary"
                   position="fixed">{{'PAGE_EDIT_COLUMNS' | translate }}</ion-label>
        <ion-input type="number"
                   [max]="gridConfig.maxCols"
                   [min]="1"
                   formControlName="columns"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label color="primary"
                   position="fixed">{{'PAGE_EDIT_ROWS' | translate }}</ion-label>
        <ion-input type="number"
                   [max]="gridConfig.maxRows"
                   [min]="1"
                   formControlName="rows"></ion-input>
      </ion-item>
    </form>
  </ion-list>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="primary">

      <ion-button color="primary"
                  [disabled]="!settingsForm.valid"
                  (click)="save()"
                  fill="solid">{{ 'PAGE_EDIT_SAVE' | translate }}</ion-button>
      <ion-button color="danger"
                  (click)="cancel()"
                  fill="solid">{{ 'PAGE_EDIT_CANCEL' | translate }}</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
