import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  CanLoad,
  Route,
  UrlSegment
} from '@angular/router';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { UserState } from '@sonorus/state';
import { SubscriptionLevel, SubscriptionStatus } from '@sonorus/api';
import { first, map, tap, switchMap } from 'rxjs/operators';
import { AppBootstrapService } from '@sonorus/core';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionCheckGuard
  implements CanActivate, CanActivateChild, CanLoad {
  @Select(UserState.subscriptionLevel)
  subscriptionLevel$: Observable<SubscriptionLevel>;

  @Select(UserState.subscriptionStatus)
  subscriptionStatus$: Observable<SubscriptionStatus>;

  constructor(
    private router: Router,
    private appBootstrapService: AppBootstrapService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.hasActiveSubscription();
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.hasActiveSubscription();
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.hasActiveSubscription();
  }

  private hasActiveSubscription(): Observable<boolean> {
    return this.appBootstrapService.userLoaded$.pipe(
      first(),
      switchMap(() => this.subscriptionStatus$),
      first(),
      map(
        status =>
          status === SubscriptionStatus.Active ||
          status === SubscriptionStatus.Trial
      ),
      tap(access => {
        if (!access) {
          this.router.navigate(['/invalid-subscription']);
        }
      })
    );
  }
}
