<ion-header>
  <ion-toolbar>
    <ion-title> {{ 'WIDGET_SEARCH_IMAGES' | translate }} </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-item [formGroup]="searchForm">
    <ion-label position="floating">{{
      'SEARCH_BUTTON_TEXT' | translate
    }}</ion-label>
    <ion-input type="text" formControlName="search"></ion-input>
    <ion-icon slot="end" name="search"></ion-icon>
  </ion-item>

  <ion-item *ngIf="images">
    <ion-grid>
      <ion-row>
        <ion-col size="12" size-md="4" *ngFor="let image of images"
          ><img
            [src]="image.thumbnailUrl"
            [alt]="image.displayText"
            class="responsive-image clickable"
            style="max-height: 250px"
            (click)="selectImage(image)"
        /></ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <sonorus-previous-next [hasPrevious]="hasPreviousPage"
                                 [hasNext]="hasNextPage"
                                 (previous)="getPrevious()"
                                 (next)="getNext()"></sonorus-previous-next>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-item>
</ion-content>
