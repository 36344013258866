import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminPageEditPage } from './admin-page-edit/admin-page-edit';
import { AdminBookPagesPage } from './admin-book-pages/admin-book-pages';
import { UnsavedChangesGuard } from './guards/unsaved-changes.guard';
import { LogbookViewerComponent } from './logbook-viewer/logbook-viewer.component';
import { LogbookEditorComponent } from './logbook-editor/logbook-editor.component';

const routes: Routes = [
  {
    path: 'product/:productId/book/:bookId/pages',
    component: AdminBookPagesPage
  },
  {
    path: ':bookId/pages',
    component: AdminBookPagesPage
  },
  {
    path: 'product/:productId/book/:bookId/pages/:pageId',
    canDeactivate: [UnsavedChangesGuard],
    component: AdminPageEditPage
  },
  {
    path: ':bookId/pages/:pageId',
    canDeactivate: [UnsavedChangesGuard],
    component: AdminPageEditPage
  },
  {
    path: ':bookId/logbook',
    component: LogbookViewerComponent
  },
  {
    path: ':bookId/logbook/edit',
    component: LogbookEditorComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BookEditorRoutingModule {}
