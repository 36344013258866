import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import { LoggedIn, ClearLoginState } from './login.actions';

export interface LoginStateModel {
  userId: string;
  auth0Id: string;
  auth0Email: string;
}

@State<LoginStateModel>({
  name: 'loginState',
  defaults: {
    userId: undefined,
    auth0Id: undefined,
    auth0Email: undefined
  }
})
@Injectable()
export class LoginState {
  @Action(LoggedIn)
  loggedIn(ctx: StateContext<LoginStateModel>, action: LoggedIn) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      auth0Id: action.auth0Id,
      userId: action.userId,
      auth0Email: action.auth0Email
    });
  }

  @Action(ClearLoginState)
  clearLoginState(ctx: StateContext<LoginStateModel>, action: LoggedIn) {
    ctx.setState({
      auth0Id: undefined,
      userId: undefined,
      auth0Email: undefined
    });
  }
}
