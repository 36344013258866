<div [formGroup]="quickChatForm">
  <div class="container">
    <div class="item-toggle">
      <ion-toggle formControlName="enabled"
                  color="primary"></ion-toggle>
    </div>
    <div class="item-title">
      {{'BOOKS.ADMIN.WIDGETS.QUICKCHAT_TITLE' | translate}}
    </div>
  </div>
  <div>

    <ion-list>
      <ion-item-group *ngIf="quickChatEnabled">

        <ion-item
                  [title]="('BOOKS.ADMIN.WIDGETS.QUICKCHAT_TITLE' | translate) + ' ' +(!menuRight ? ('BOOKS.ADMIN.WIDGETS.LEFT' | translate) : ('BOOKS.ADMIN.WIDGETS.RIGHT' | translate))">
          <ion-icon *ngIf="!menuRight"
                    name="arrow-forward"
                    slot="start"></ion-icon>
          <!-- <ion-label *ngIf="!menuRight">
          {{ 'BOOKS.ADMIN.WIDGETS.LEFT' | translate }}
        </ion-label> -->
          <ion-icon *ngIf="menuRight"
                    name="arrow-back"
                    slot="start"></ion-icon>
          <!-- <ion-label *ngIf="menuRight">
          {{ 'BOOKS.ADMIN.WIDGETS.RIGHT' | translate }}
        </ion-label> -->

          <ion-toggle formControlName="opensRightSide"
                      color="primary"></ion-toggle>
        </ion-item>
        <ion-reorder-group (ionItemReorder)="onReorder($event)"
                           disabled="false">
          <ion-item *ngFor="let item of items">
            <ion-label class="ion-text-wrap">
              <widget [item]="toGridsterItem(item)"
                      [fontCssClass]="fontCssClass"
                      [editMode]="true"
                      [quickChatMode]="true"
                      (edit)="onEditWidget(item)"
                      (delete)="onDeleteWidget(item)"></widget>
            </ion-label>
            <ion-reorder slot="end"></ion-reorder>
          </ion-item>
        </ion-reorder-group>
        <ion-item button="true"
                  color="primary"
                  (click)="onAddWidget()">
          <ion-icon name="add"
                    slot="start"></ion-icon>
          <ion-label class="ion-text-wrap">
            {{ 'BOOKS.ADMIN.WIDGETS.ADD_BUTTON' | translate }}
          </ion-label>
        </ion-item>
      </ion-item-group>
    </ion-list>
  </div>
</div>
