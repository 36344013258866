import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { ButtonWidget } from './../button-widget';

/**
 * Generated class for the WidgetButtonComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'widget-button',
  templateUrl: 'widget-button.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetButtonComponent /*implements ButtonWidget */ {
  @Input()
  editMode: boolean;
  @Input()
  previewMode: boolean;
  @Input()
  quickChatMode: boolean;

  @Output()
  edit = new EventEmitter();
  @Output()
  delete = new EventEmitter();
  @Output()
  executeAction = new EventEmitter();

  // borderSize - 'none' | 'small' | 'large'
  // borderColor - hex
  // backgroundColor - hex
  // textColor - hex
  // textSize - 'small' | 'regular' | 'big'
  // textAlign - 'top' | 'middle' | 'bottom'
  get titleOrder() {
    if (this.model.textAlign === 'top') {
      return 1;
    }

    if (this.model.textAlign === 'bottom') {
      return 2;
    }
  }

  get imageOrder() {
    if (this.model.textAlign === 'top') {
      return 2;
    }

    if (this.model.textAlign === 'bottom') {
      return 1;
    }
  }

  model: ButtonWidget;

  constructor(private changeDetector: ChangeDetectorRef) {}

  initialize(model: ButtonWidget) {
    this.model = model;
    this.changeDetector.markForCheck();
  }

  editClick() {
    if (this.editMode) {
      this.edit.emit();
    }
  }

  deleteClick() {
    if (this.editMode) {
      this.delete.emit();
    }
  }

  executeActionClick() {
    if (this.editMode) {
      return;
    }

    this.executeAction.emit(this.model);
  }
}
