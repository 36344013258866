import {WidgetStyleStateModel} from "./widget-style.state";

export namespace WidgetStyleActions {

  export class Load {
    static readonly type = '[WidgetStyle] Load from storage';

    constructor() {
    }
  }

  export class Update {
    static readonly type = '[WidgetStyle] Update';

    constructor(public widgetStyle: WidgetStyleStateModel) {
    }
  }

}
