<ion-grid *ngIf="(currentState$ | async) === STATE_EMPTY">
    <ion-row>
        <ion-col>
            <ion-item button="true"
                      color="primary"
                      (click)="onUploadClicked()">
                <ion-icon name="cloud-upload-outline"
                          slot="start"></ion-icon>
                <ion-label>{{'AUDIO_UPLOADER.UPLOAD' | translate}}</ion-label>
            </ion-item>
        </ion-col>
    </ion-row>
</ion-grid>

<input #browserFileInput
       style="display: none"
       type="file"
       [accept]="AUDIO_FILE_EXTENSIONS"
       (change)="onUpload($event)" />

<!-- playback -->
<ion-grid *ngIf="(currentState$ | async) === STATE_PLAYBACK">
    <ion-row>
        <ion-col size="2"
                 size-md="3"
                 size-lg="2">
            <ion-button *ngIf="isPlaying === false"
                        (click)="play()">
                <ion-icon name="play"></ion-icon>
            </ion-button>
            <ion-button *ngIf="isPlaying === true"
                        (click)="stopPlaying()">
                <ion-icon name="square"></ion-icon>
            </ion-button>
        </ion-col>
        <ion-col size="3"
                 size-md="3"
                 size-lg="2">
            <ion-label>{{audioSeekFormatted}}</ion-label>
        </ion-col>
        <ion-col>
            <ion-progress-bar style="margin-top: 20px;"
                              color="primary"
                              [value]="audioPlaybackProgress"></ion-progress-bar>
        </ion-col>
        <ion-col size="2"
                 size-md="3"
                 size-lg="2">
            <ion-label>{{audioDurationFormatted}}</ion-label>
        </ion-col>
        <ion-col size="1"
                 size-md="2"
                 size-lg="1">
            <ion-button (click)="deleteAudio()">
                <ion-icon name="trash"></ion-icon>
            </ion-button>
        </ion-col>
    </ion-row>
</ion-grid>
