<ion-header>
    <ion-toolbar>
        <ion-title>{{ 'BOOKS.ADMIN.PAGES.REORDER' | translate}}
        </ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="cancel()">
                <ion-icon name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
    <div cdkDropList
         class="example-list"
         (cdkDropListDropped)="drop($event)">
        <div class="example-box"
             *ngFor="let page of pages"
             cdkDrag>
            <div class="example-custom-placeholder"
                 *cdkDragPlaceholder></div>
            {{page.title}}
        </div>
    </div>
</ion-content>

<ion-footer>
    <ion-toolbar>
        <ion-buttons slot="primary">
            <ion-button color="primary"
                        (click)="save()"
                        type="submit"
                        fill="solid">{{ 'SAVE_BUTTON_TEXT' | translate }}</ion-button>
            <ion-button color="danger"
                        (click)="cancel()"
                        type="button"
                        fill="solid">{{ 'CANCEL_BUTTON_TEXT' | translate }}</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
