<style type="text/css">
  .loader-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loader {
    border: 6px solid #f3f3f3; /* Light grey */
    border-top: 6px solid #3d60ac; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>

<div class="loader-container"><div class="loader"></div></div>
