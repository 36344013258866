import {
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  SimpleChanges,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CultureModel, SUPPORTED_CULTURES } from '@sonorus/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sonorus-culture-picker',
  templateUrl: 'culture-picker.component.html'
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CulturePickerComponent implements OnChanges, OnInit, OnDestroy {
  @Input()
  culture: string;

  @Output()
  change: EventEmitter<CultureModel> = new EventEmitter();

  supportedCultures: CultureModel[] = SUPPORTED_CULTURES;

  selectedCultureValue: string;
  selectedCulture: CultureModel;

  private readonly defaultCulture = 'en-GB';
  private sub: Subscription;

  constructor(
    private translate: TranslateService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    // if (!this.culture) {
    //   this.setCulture(this.defaultCulture, false);
    // }

    this.sub = this.translate.onLangChange.subscribe(langChangeEvent => {
      this.changeDetector.markForCheck();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.culture && changes.culture.currentValue) {
      if (this.supportedCultures.some(cult => cult.isoCode === this.culture)) {
        let culture;
        if (window && localStorage && localStorage.getItem('culture')) {
          culture = localStorage.getItem('culture');
        } else {
          culture = this.culture;
        }

        //TODO: localstorage in app?

        this.setCulture(culture || this.defaultCulture, false);
      }
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onCultureChange = (changeEvent: any) => {
    const isoCode = changeEvent.detail.value;
    if (isoCode !== this.selectedCultureValue) {
      this.setCulture(isoCode);
    }
  };

  private setCulture(isoCode: string, emit = true) {
    this.selectedCultureValue = isoCode;
    this.selectedCulture = this.supportedCultures.find(
      c => c.isoCode === isoCode
    );

    if (emit) {
      this.change.emit(this.selectedCulture);
    }
  }
}
