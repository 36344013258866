import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';
import { debounceTime, filter, mergeMap, tap } from 'rxjs/operators';
import {
  AssetsApiService,
  PagedImagesResult,
  Query2,
  SearchEngineImageResult
} from '@sonorus/api';
import { LoaderService } from '@sonorus/core';

interface IState {
  search: string;
  offset: number;
  nextOffset: number;
  offsetHistory: number[];
}

/**
 * Generated class for the AdminWidgetEditImageSearchPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-admin-widget-edit-image-search',
  templateUrl: 'admin-widget-edit-image-search.html'
})
export class AdminWidgetEditImageSearchPage implements OnInit {
  searchForm: FormGroup;
  images: SearchEngineImageResult[];

  private limit = 8;
  private offset = 0;
  private nextOffset: number;

  private offsetHistory: number[];

  private get searchValue() {
    return this.searchForm.get('search').value;
  }

  get hasNextPage(): boolean {
    return !!this.nextOffset;
  }

  get hasPreviousPage() {
    return this.offset > 0;
  }

  constructor(
    private loader: LoaderService,
    private viewCtrl: ModalController,
    private assetsApiService: AssetsApiService,
    private changeDetector: ChangeDetectorRef,
    private fb: FormBuilder
  ) { }

  async ngOnInit() {
    this.searchForm = this.fb.group({
      search: ''
    });


    this.searchForm
      .get('search')
      .valueChanges.pipe(
        debounceTime(1000),
        filter((v: string) => v && v.length >= 3),
        tap(async () => await this.loader.show()),
        mergeMap(v => this.assetsApiService.searchBingImages(v, this.limit, this.offset))
      )
      .subscribe(async (imageSearchResult: PagedImagesResult) => {
        this.offsetHistory = [this.offset || 0];
        this.images = imageSearchResult.images;
        this.nextOffset = imageSearchResult.nextOffset;
        await this.loader.hide();
      });

    await this.restoreState();
  }

  selectImage = async (image: SearchEngineImageResult) => {
    await this.loader.show();

    const result = await this.assetsApiService
      .getExternalImageAsBase64(
        new Query2({
          maxWidth: 500,
          url: image.imageUrl
        })
      ).toPromise();

    this.storeState();

    await this.loader.hide();

    this.viewCtrl.dismiss(result);
  };

  cancel() {
    this.storeState();

    this.viewCtrl.dismiss();
  }

  async getPrevious() {
    await this.loader.show();

    //clear latest
    this.offsetHistory.pop();
    this.offset = this.offsetHistory.pop();

    const response: PagedImagesResult = await this.assetsApiService.searchBingImages(this.searchValue, this.limit, this.offset).toPromise();
    this.images = response.images;
    this.nextOffset = response.nextOffset;

    this.changeDetector.markForCheck();

    await this.loader.hide();
  }

  async getNext() {
    await this.loader.show();

    this.offset = this.nextOffset;
    const response: PagedImagesResult = await this.assetsApiService.searchBingImages(this.searchValue, this.limit, this.offset).toPromise();
    this.images = response.images;
    this.nextOffset = response.nextOffset;
    this.offsetHistory.push(this.offset);

    this.changeDetector.markForCheck();

    await this.loader.hide();
  }


  private storeState() {
    const state: IState = {
      search: this.searchForm.value.search,
      offset: this.offset,
      nextOffset: this.nextOffset,
      offsetHistory: this.offsetHistory
    };

    window.localStorage.setItem('AdminWidgetEditImageSearchPage', JSON.stringify(state));
  }

  private async restoreState() {
    const restoredState = JSON.parse(window.localStorage.getItem('AdminWidgetEditImageSearchPage')) as IState;
    if (restoredState) {
      // pagination
      this.offset = restoredState.offset;
      this.nextOffset = restoredState.nextOffset;
      this.offsetHistory = restoredState.offsetHistory;

      // trigger search
      this.searchForm.patchValue({
        search: restoredState.search
      }, { emitEvent: false });

      const searchResult = await this.assetsApiService.searchBingImages(restoredState.search, this.limit, this.offset).toPromise();
      this.images = searchResult.images;

      this.changeDetector.markForCheck();
    }
  }
}
