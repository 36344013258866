<div style="width: 100%; display: flex; justify-content: center;">
  <ion-button fill="clear"
              *ngIf="hasPrevious"
              (click)="previous?.emit()">
    <ion-icon slot="icon-only"
              name="arrow-back"></ion-icon>
  </ion-button>
  <ion-button fill="clear"
              *ngIf="hasNext"
              (click)="next?.emit()">
    <ion-icon slot="icon-only"
              name="arrow-forward"></ion-icon>
  </ion-button>
</div>
