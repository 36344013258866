import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationProvider } from '@sonorus/core';
import { Select } from '@ngxs/store';
import { UserState, UserStateModel } from '@sonorus/state';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

let installEvent = null;
(window as Window).addEventListener('beforeinstallprompt', (event) => {
  // Do not show the install prompt quite yet.
  event.preventDefault();
  // Stash the `BeforeInstallPromptEvent` for later.
  installEvent = event;
  // Enable the install button.
  // installButton.disabled = false;
});

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss']
})
export class HomePage implements OnInit, OnDestroy {
  @Select(UserState)
  userState$: Observable<UserStateModel>;

  userStateModel: UserStateModel;
  authenticated: boolean;

  clock: string;

  get showInstallButton() {
    return !!installEvent;
  }

  private clockIntervalHandle;
  private sub: Subscription;

  constructor(private authService: AuthenticationProvider) {

  }

  ngOnInit() {
    this.clock = new Date().toLocaleTimeString();
    this.clockIntervalHandle = setInterval(
      () => (this.clock = new Date().toLocaleTimeString()),
      1000
    );

    this.sub = this.userState$
      .pipe(filter(userState => !!userState.id))
      .subscribe(userState => {
        this.userStateModel = userState;
      });

    this.sub.add(
      this.authService.authenticated$.subscribe(
        authenticated => (this.authenticated = authenticated)
      )
    );
  }

  ngOnDestroy() {
    window.clearInterval(this.clockIntervalHandle);
    this.sub.unsubscribe();
  }

  login() {
    this.authService.login();
  }

  async install() {
    // If there is no stashed `BeforeInstallPromptEvent`, return.
    if (!installEvent) {
      return;
    }
    // Use the stashed `BeforeInstallPromptEvent` to prompt the user.
    installEvent.prompt();
    const result = await installEvent.userChoice;
    // If the user installs the app, run `onInstall()`.
    if (result.outcome === 'accepted') {
      // onInstall();
    }
  }
}


