<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'SHARE.TITLE' | translate}}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="shareForm">
    <ion-list padding>
      <ion-item>
        <ion-label position="stacked">{{'SHARE.TO' | translate }}</ion-label>
        <ion-input placeholder="{{'SHARE.EXAMPLE_EMAIL' | translate}}"
                   formControlName="to"></ion-input>
      </ion-item>
      <ion-item>
        <ion-textarea rows="10"
                      placeholder="{{'SHARE.MESSAGE' | translate}}"
                      formControlName="message">
        </ion-textarea>
      </ion-item>
    </ion-list>
  </form>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="primary">
      <ion-button color="primary"
                  [disabled]="!shareForm.valid"
                  (click)="shareContent()"
                  type="submit"
                  fill="solid">{{ 'SHARE.SEND' | translate }}</ion-button>
      <ion-button color="danger"
                  (click)="cancel()"
                  type="button"
                  fill="solid">{{ 'CANCEL_BUTTON_TEXT' | translate }}</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
