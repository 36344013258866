<ion-header>
  <ion-toolbar>
    <ion-title>{{ entry ? 'BOOKS.ADMIN.LOGBOOK.UPDATEENTRY' : 'BOOKS.ADMIN.LOGBOOK.ADDENTRY' | translate}}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="entryForm">
    <ion-list padding>
      <ion-item>
        <ion-label position="stacked">{{'BOOKS.ADMIN.LOGBOOK.AUTHOR' | translate }}</ion-label>
        <ion-input placeholder="{{'BOOKS.ADMIN.LOGBOOK.AUTHOR' | translate}}"
                   formControlName="author"></ion-input>
      </ion-item>
      <ion-item lines="full">
        <ion-label>{{createdOn | date: 'dd/MM/yyyy HH:mm'}}</ion-label>
      </ion-item>
      <ion-item>
        <ion-textarea rows="10"
                      placeholder="{{'BOOKS.ADMIN.LOGBOOK.ENTRYPLACEHOLDER' | translate}}"
                      formControlName="entry">
        </ion-textarea>
      </ion-item>
    </ion-list>
  </form>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="primary">
      <ion-button color="primary"
                  [disabled]="!entryForm.valid"
                  (click)="saveEntry()"
                  type="submit"
                  fill="solid">{{ 'SAVE_BUTTON_TEXT' | translate }}</ion-button>
      <ion-button color="danger"
                  (click)="cancel()"
                  type="button"
                  fill="solid">{{ 'CANCEL_BUTTON_TEXT' | translate }}</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
