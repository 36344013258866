import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy, OnInit, OnChanges, ViewChild, Input, Output, SimpleChanges } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Howl } from 'howler';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { AudioHelperService } from '../audio-helper.service';

@Component({
  selector: 'sonorus-mini-audio-uploader',
  templateUrl: './mini-audio-uploader.component.html',
  styleUrls: ['./mini-audio-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MiniAudioUploaderComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('browserFileInput', { static: true })
  browserFileInput: ElementRef;

  @Input()
  audioDataUri: string;

  @Output()
  newAudio = new EventEmitter<File>();

  readonly STATE_EMPTY = MiniAudioUploaderState.Empty;
  readonly STATE_PLAYBACK = MiniAudioUploaderState.Playback;
  readonly AUDIO_FILE_EXTENSIONS = this.audioHelper.getAllowedFileExtensions();

  currentState$: Observable<MiniAudioUploaderState>;
  audioDurationFormatted: string;
  audioSeekFormatted: string;
  audioPlaybackProgress: number;
  audioDuration: number;

  currentAudio: Howl;

  private currentStateSubject: BehaviorSubject<MiniAudioUploaderState>;

  constructor(
    private cd: ChangeDetectorRef,
    private audioHelper: AudioHelperService,
    private translate: TranslateService,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController
  ) {
    this.currentStateSubject = new BehaviorSubject<MiniAudioUploaderState>(this.STATE_EMPTY);
    this.currentState$ = this.currentStateSubject.asObservable();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.audioDataUri && changes.audioDataUri.currentValue) {
      this.load(this.audioDataUri);
    }
  }

  ngOnDestroy() {
    if (this.isPlaying) {
      this.stopPlaying();
    }

    if (this.currentAudio && this.currentAudio.state !== 'unloaded') {
      this.currentAudio.unload();
    }

    this.currentStateSubject.complete();
  }

  private async load(audioDataUri: string) {
    const loading = await this.loadingCtrl.create();
    await loading.present();

    if (this.currentAudio && this.currentAudio.state !== 'unloaded') {
      this.currentAudio.unload();
    }

    // console.log('audio data url', audioDataUri);

    this.audioSeekFormatted = '0:00';
    this.currentAudio = new Howl({
      src: audioDataUri,
      onload: async () => {

        this.audioDuration = this.currentAudio.duration();
        this.audioDurationFormatted = this.audioHelper.formatSecondsToTimeString(this.audioDuration);

        if (this.audioDuration > 15) {
          this.deleteAudio();

          const translations = await this.translate.get(['AUDIO_UPLOADER.SIZE_WARNING', 'OK_BUTTON_TEXT']).toPromise();
          // debugger;
          const alert = await this.alertCtrl.create({
            message: translations['AUDIO_UPLOADER.SIZE_WARNING'],
            buttons: [
              {
                text: translations['OK_BUTTON_TEXT'],
                role: 'leave'
              }
            ]
          });
          await alert.present();
          // const result: OverlayEventDetail<any> = await alert.onDidDismiss();
          // if (result.role === 'stay') {
          //   return false;
          // } else {
          //   return true;
          // }
        } else {
          this.currentStateSubject.next(MiniAudioUploaderState.Playback);
        }

        await loading.dismiss();

        this.cd.detectChanges();
      },
      onplay: () => {
        this.isPlaying = true;
        console.log('sound started playing');

        // todo: start timer
        timer(0, 100).pipe(takeWhile(() => this.isPlaying)).subscribe(val => {
          console.log(val);
          const amountInSeconds = val / 10;
          this.audioPlaybackProgress = amountInSeconds / this.audioDuration;
          console.log(this.audioPlaybackProgress)
          this.audioSeekFormatted = this.audioHelper.formatSecondsToTimeString(amountInSeconds);
          this.cd.detectChanges();
        })
        // -- update slider
        // -- update start time
        this.cd.detectChanges();
      },
      onstop: () => {
        this.isPlaying = false;
        console.log('sound stopped playing');

        this.cd.detectChanges();
      },
      onend: () => {
        this.isPlaying = false;
        this.audioPlaybackProgress = 1;
        console.log('sound stopped playing');

        this.cd.detectChanges();
      }

    });

    console.log("setting state to PLAYBACK");
  }

  onUploadClicked() {
    this.browserFileInput.nativeElement.click();
  }

  async onUpload($event) {
    const loading = await this.loadingCtrl.create();
    await loading.present();

    const file: File = $event.target.files[0];
    const reader: FileReader = new FileReader();

    reader.onloadend = async e => {
      const audioURL = reader.result as string;
      this.newAudio.emit(file);

      await loading.dismiss();

      await this.load(audioURL);
    };
    reader.readAsDataURL(file);
  }

  isPlaying: boolean = false;
  play() {
    if (this.currentAudio && !this.isPlaying) {
      this.currentAudio.play();
    }
  }

  stopPlaying() {
    if (this.currentAudio) {
      this.currentAudio.stop();
    }
  }

  deleteAudio() {
    if (this.currentAudio) {
      this.isPlaying = false;
      if (this.currentAudio && this.currentAudio.state() === 'unloaded') {
        this.currentAudio.unload();
        this.currentAudio = undefined;
      }

      this.newAudio.emit(undefined);

      this.currentStateSubject.next(MiniAudioUploaderState.Empty);
    }
  }
}

enum MiniAudioUploaderState {
  Empty = 1,
  Playback
}