<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div
       style="display: flex; justify-content: space-around; align-items: center; height: 90%; width: 60%;  margin: auto; flex-wrap: wrap;">
    <ion-button *ngFor="let colorValue of colorValues"
                size="large"
                (click)="chooseColor(colorValue)"
                [style]="sanitizer.bypassSecurityTrustStyle('--background: ' + colorValue + '; --background-hover: ' + colorValue + '; --box-shadow: 1px 1px 1px #AAAAAA; flex: 0 1 auto;')">
      &nbsp;&nbsp;&nbsp;&nbsp;
    </ion-button>
  </div>
</ion-content>

<ion-footer>
  <ion-toolbar></ion-toolbar>
</ion-footer>
