import {
  IQuickChatWithAssets,
  IPageWithAssets,
  IScanningOptions,
  BookAudioCue,
  IBookStyleDefinition
} from '@sonorus/api';
import { State, Selector, Action, StateContext, Select } from '@ngxs/store';
import { BookPlayer } from './book-player.actions';
import * as _ from 'lodash';
import { StorageService } from '../_hybrid-wrappers/storage';
import { Injectable } from '@angular/core';

export interface BookPlayerStateModel {
  bookId: string;
  currentPageId: string;
  quickChat: IQuickChatWithAssets;
  audioCues: { [key: string]: BookAudioCue };
  scanningOptions: IScanningOptions;
  currentPage: IPageWithAssets;
  style: IBookStyleDefinition;
}

const createDefaultState = (): BookPlayerStateModel => {
  return {
    bookId: undefined,
    currentPageId: undefined,
    quickChat: undefined,
    audioCues: undefined,
    scanningOptions: undefined,
    currentPage: undefined,
    style: undefined
  };
};

@State<BookPlayerStateModel>({
  name: 'bookPlayer',
  defaults: createDefaultState()
})
@Injectable()
export class BookPlayerState {
  @Selector()
  static page(state: BookPlayerStateModel) {
    if (state && state.currentPage) {
      return state.currentPage;
    }

    return null;
  }

  @Selector()
  static audioCues(state: BookPlayerStateModel) {
    if (state && state.audioCues) {
      return state.audioCues;
    }

    return undefined;
  }

  @Selector()
  static scanningOptions(state: BookPlayerStateModel) {
    if (state && state.scanningOptions) {
      return state.scanningOptions;
    }

    return undefined;
  }

  @Selector()
  static scanningEnabled(state: BookPlayerStateModel) {
    if (state && state.scanningOptions && state.scanningOptions.enabled) {
      return state.scanningOptions.enabled;
    }

    return false;
  }

  @Selector()
  static quickChatEnabled(state: BookPlayerStateModel) {
    if (state && state.quickChat && state.quickChat.enabled) {
      return state.quickChat.enabled;
    }

    return false;
  }

  @Selector()
  static quickChatRightSide(state: BookPlayerStateModel) {
    if (state && state.quickChat && state.quickChat.opensRightSide) {
      return state.quickChat.opensRightSide;
    }

    return false;
  }

  @Selector()
  static quickChatWidgets(state: BookPlayerStateModel) {
    if (state && state.quickChat && state.quickChat.widgetsWithAssets) {
      return state.quickChat.widgetsWithAssets;
    }

    return [];
  }

  @Selector()
  static style(state: BookPlayerStateModel) {
    if (state) {
      return state.style;
    }
  }

  constructor(private storage: StorageService) { }

  @Action(BookPlayer.Load)
  async loadBookPlayerState(
    ctx: StateContext<BookPlayerStateModel>,
    action: BookPlayer.Load
  ) {
    const state: BookPlayerStateModel = createDefaultState();

    state.bookId = action.bookId;

    if (action.bookId) {

      const bookStorageDTO = await this.storage.getBooksRepo().getById(action.bookId);
      state.style = bookStorageDTO.style;

      const quickChatStorageDTO = await this.storage
        .getBooksRepo()
        .getQuickChatById(action.bookId);

      state.quickChat =
        quickChatStorageDTO && quickChatStorageDTO.quickChat
          ? _.cloneDeep(quickChatStorageDTO.quickChat)
          : undefined;

      const scanningDTO = await this.storage
        .getBooksRepo()
        .getScanningOptionsById(action.bookId);

      state.scanningOptions =
        scanningDTO && scanningDTO.scanningOptions
          ? _.cloneDeep(scanningDTO.scanningOptions)
          : undefined;

      if (
        state.scanningOptions &&
        state.scanningOptions.enabled &&
        state.scanningOptions.audio
      ) {
        const audioCuesStorageDTO = await this.storage
          .getBooksRepo()
          .getAudioCuesById(action.bookId);

        state.audioCues =
          audioCuesStorageDTO && audioCuesStorageDTO.audioCues
            ? _.cloneDeep(audioCuesStorageDTO.audioCues)
            : undefined;
      }
    }

    ctx.setState(state);
  }

  @Action(BookPlayer.SetCurrentPage)
  setCurrentPage(
    ctx: StateContext<BookPlayerStateModel>,
    action: BookPlayer.SetCurrentPage
  ) {
    ctx.patchState({
      currentPageId: action.pageId
    });
  }

  @Action(BookPlayer.LoadPage)
  async loadPage(
    ctx: StateContext<BookPlayerStateModel>,
    action: BookPlayer.LoadPage
  ) {
    const pageStorageDTO = await this.storage
      .getPagesRepo()
      .getPageContentById(action.bookId, action.pageId);

    const state = ctx.getState();
    const newState = _.cloneDeep(state) as BookPlayerStateModel;
    newState.bookId = action.bookId;
    newState.currentPageId = action.pageId;
    newState.currentPage = pageStorageDTO.pageContent;

    ctx.setState(newState);
  }

  @Action(BookPlayer.Clear)
  clearBookPlayerState(
    ctx: StateContext<BookPlayerStateModel>,
    _action: BookPlayer.Clear
  ) {
    ctx.setState(createDefaultState());
  }
}
