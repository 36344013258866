<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'WIDGET_SEARCH_YOUTUBE' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-item [formGroup]="searchForm">
    <ion-label position="floating">
      {{ 'SEARCH_BUTTON_TEXT' | translate }}
    </ion-label>
    <ion-input type="text"
               formControlName="search"></ion-input>
    <ion-icon slot="end"
              name="search"></ion-icon>
  </ion-item>

  <ion-item>
    <ion-icon name="information-circle-outline"
              slot="start"></ion-icon>
    <ion-label style="font-size: 12px;">
      {{ 'WIDGET_SEARCH_YOUTUBE_UNLISTED' | translate }}
    </ion-label>
  </ion-item>

  <ion-item *ngIf="ytResults">
    <ion-grid>
      <ion-row>
        <ion-col size="12"
                 size-md="4"
                 *ngFor="let youtubeVideo of ytResults.items">
          <div class="yt-title">{{youtubeVideo.snippet.title}}</div>

          <img [src]="youtubeVideo.snippet.thumbnails.medium.url"
               [alt]="youtubeVideo.snippet.title"
               (click)="selectVideo(youtubeVideo)"
               class="responsive-image clickable" />
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-item>
  <!--
    <ion-list *ngIf="ytResults">
      <ion-list-header>{{ 'WIDGET_SEARCH_VIDEOS_TITLE' | translate }}</ion-list-header>
      <a ion-item
         detail-none
         *ngFor="let youtubeVideoId of ytResults.items"
         (click)="selectVideo(youtubeVideoId)">
        <img [src]="youtubeVideoId.snippet.thumbnails.default.url" />
        <h4>{{youtubeVideoId.snippet.title}}</h4>
      </a>
    </ion-list>
  -->
  <!--  -->
</ion-content>
