import { IUserDTO } from '@sonorus/api';

export class ClearUserState {
  static readonly type = '[App] Clear User State';
}

export class LoadUserById {
  static readonly type = '[App Initialization] Loading User By Sonorus Id';
  constructor(public userId: string) { }
}

export class LoadUserByAuth0Id {
  static readonly type = '[App Initialization] Loading User By Auth0 Id';
  constructor(public auth0Id: string) { }
}

export class SaveUser {
  static readonly type = '[User Module] Save User Data';
  constructor(
    public userId: string,
    public auth0Id: string,
    public user: IUserDTO
  ) { }
}

export class ChangeCulture {
  static readonly type = '[Language] Change User Culture';
  constructor(public userId: string, public culture: string) { }
}

// export class LoadSubscriptionStatusFromStripe {
//   static readonly type = '[Subscription] Load Subscription Status From Stripe';
//   constructor(public userId: string) {}
// }

// export class SetUserActionRequiredResolved {
//   static readonly type =
//     '[Subscription] Stripe JS SDK - User Action Required Resolved';
//   constructor(public userId: string) {}
// }

// export class CancelSubscription {
//   static readonly type = '[Subscription] Cancel Subscription';
//   constructor(public userId: string) {}
// }

// export class RenewSubscription {
//   static readonly type = '[Subscription] Renew Subscription';
//   constructor() {}
// }
