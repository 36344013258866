<ion-app>
  <ion-split-pane when="(min-width: 1500px)"
                  contentId="main-content">
    <ion-menu side="start"
              menuId="mainMenu"
              contentId="main-content"
              swipeGesture="true"
              type="overlay">
      <ion-header>
        <ion-toolbar color="primary">
          <ion-title>
            <sonorus-menu-header></sonorus-menu-header>
          </ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list *ngIf="authenticated"
                  lines="full">
          <ion-item *ngFor="let p of authPages"
                    [routerDirection]="'root'"
                    [routerLink]="[p.url]">
            <ion-icon slot="start"
                      [name]="p.icon"></ion-icon>
            <ion-label> {{ p.title | translate }} </ion-label>
          </ion-item>
        </ion-list>
        <ion-list *ngIf="!authenticated">
          <ion-item-group>
            <ion-item style="cursor: pointer"
                      lines="full"
                      (click)="auth.login()">
              <ion-icon slot="start"
                        name="log-in"> </ion-icon>
              <ion-label>{{ 'PROFILE.LOGIN' | translate }} </ion-label>
            </ion-item>
            <!-- <ion-item style="cursor: pointer"
                      (click)="auth.login()">
              <ion-icon slot="start"
                        name="create"> </ion-icon>
              <ion-label>{{ 'PROFILE.SIGNUP' | translate }} </ion-label>
            </ion-item> -->
          </ion-item-group>
        </ion-list>
        <ion-list class="bottom-list" *ngIf="authenticated">
          <sonorus-culture-picker [culture]="culture$ | async"
                                  (change)="onCultureChanged($event)"></sonorus-culture-picker>
          <ion-item lines="full"
                    (click)="onLogoutClicked()"
                    style="cursor: pointer">
            <ion-icon slot="start"
                      name="log-out"> </ion-icon>
            <ion-label>{{ 'PROFILE.LOGOUT' | translate }}</ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>

</ion-app>
