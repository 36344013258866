import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationBrowserProvider } from './authentication-browser';
import { AuthenticationDeviceProvider2 } from './authentication-device2';
import { IAuthenticationProvider } from './authentication.interface';
import { DeviceDetectorService } from '../device-detector.service';
// import { AuthenticationDeviceProvider } from './authentication-device';

/*
  Generated class for the AuthenticationProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class AuthenticationProvider {
  public authenticated$: Observable<boolean>;
  private auth: IAuthenticationProvider;

  constructor(
    public authBrowser: AuthenticationBrowserProvider,
    // public authDevice: AuthenticationDeviceProvider,
    public authDevice: AuthenticationDeviceProvider2,
    private deviceDetector: DeviceDetectorService // private plt: Platform
  ) {
    this.auth = this.deviceDetector.isApp() ? authDevice : authBrowser;

    this.authenticated$ = this.auth.authenticated$;
  }

  login = () => this.auth.login();
  logout = () => this.auth.logout();
  handleAuthentication = () => this.auth.handleAuthentication();
  isAuthenticated = () => this.auth.isAuthenticated();
  getAuth0UserInfo = () => this.auth.getAuth0UserInfo();
  isLoading = () => this.auth.loading;
  getAuth0Id = () => this.auth.getAuth0Id();
  getUserId = () => this.auth.getUserId();
}
