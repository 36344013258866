import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { GridsterItem } from 'angular-gridster2';
import { ButtonWidget } from '../button-widget';
import { WidgetButtonComponent } from '../widget-button/widget-button';

/**
 * Generated class for the WidgetComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'widget',
  styleUrls: ['./widget.scss'],
  templateUrl: 'widget.html'
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetComponent implements OnChanges {
  @ViewChild('dynamic', { static: true, read: ViewContainerRef })
  public widgetTarget: ViewContainerRef;
  @Input()
  item: GridsterItem;
  @Input()
  fontCssClass: string;
  @Input()
  editMode = false;
  @Input()
  previewMode = false;
  @Input()
  quickChatMode = false;

  @Output()
  edit = new EventEmitter();
  @Output()
  delete = new EventEmitter();
  @Output()
  executeAction = new EventEmitter();

  constructor(
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('DEV: WidgetComponent -> changes', changes);
    if (
      changes['item'] &&
      changes['item'].currentValue !== changes['item'].previousValue
    ) {
      this.constructWidget();
    }
  }

  constructWidget() {
    // TODO: make dynamic widget type resolver
    let componentType = WidgetButtonComponent;
    
    if (this.widgetTarget.length > 0) {
      this.widgetTarget.clear();
    }
    let componentRef = this.widgetTarget.createComponent(componentType);

    // TODO: make widget data dynamic
    componentRef.instance.editMode = this.editMode;
    componentRef.instance.previewMode = this.previewMode;
    componentRef.instance.quickChatMode = this.quickChatMode;
    componentRef.instance.edit.subscribe(() => this.edit.emit());
    componentRef.instance.delete.subscribe(() => this.delete.emit());
    componentRef.instance.executeAction.subscribe(() =>
      this.executeAction.emit()
    );
    componentRef.instance.initialize(this.item as ButtonWidget);

    this.changeDetector.markForCheck();
  }
}
