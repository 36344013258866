import { IBooksRepository } from '../repositories.interfaces';
import { BookStorageDTO, AudioCuesStorageDTO } from '../models';
import { QuickChatStorageDTO } from '../models/quickchat.model';
import { FileSystemHelper } from './filesystem.helpers';
import { ScanningOptionsStorageDTO } from '../models/scanningoptions.model';

export class BooksFileSystemRepository implements IBooksRepository {
  private readonly DIR_NAME = 'books';
  private readonly BOOK_SUFFIX = 'book';
  private readonly QUICKCHAT_SUFFIX = 'quickchat';
  private readonly AUDIOCUES_SUFFIX = 'audiocues';
  private readonly SCANNING_SUFFIX = 'scanning';

  private fileHelper: FileSystemHelper;

  // private dirName = (bookId: string) => `${this.DIR_SUFFIX}_${bookId}`;

  private bookFileName = (bookId: string) =>
    `${this.BOOK_SUFFIX}.${bookId}.json`;

  private quickchatFileName = (bookId: string) =>
    `${this.QUICKCHAT_SUFFIX}.${bookId}.json`;

  private audiocuesFileName = (bookId: string) =>
    `${this.AUDIOCUES_SUFFIX}.${bookId}.json`;

  private scanningFileName = (bookId: string) =>
    `${this.SCANNING_SUFFIX}.${bookId}.json`;

  constructor() {
    this.fileHelper = new FileSystemHelper();
  }

  async add(book: BookStorageDTO): Promise<void> {
    console.log('enter add book');

    await this.fileHelper.mkdir(this.DIR_NAME);

    await this.fileHelper.fileWrite(
      this.DIR_NAME,
      this.bookFileName(book.bookId),
      JSON.stringify(book)
    );

    console.log('leave add book');
  }

  async update(book: BookStorageDTO): Promise<void> {
    console.log('enter update book');

    const fileName = this.bookFileName(book.bookId);

    await this.fileHelper.fileDelete(this.DIR_NAME, fileName);
    await this.fileHelper.fileWrite(
      this.DIR_NAME,
      fileName,
      JSON.stringify(book)
    );

    console.log('leave update book');
  }

  async getById(bookId: string): Promise<BookStorageDTO> {
    console.log('enter get book by id');

    try {
      const fileReadResult = await this.fileHelper.fileRead(
        this.DIR_NAME,
        this.bookFileName(bookId)
      );
      console.log('leave get book by id');

      return JSON.parse(fileReadResult.data);
    } catch (err) {
      // does not exist
      return undefined;
    }
  }

  async getAll(): Promise<BookStorageDTO[]> {
    console.log('enter get all books');

    const dirReadResult = await this.fileHelper.readdir(this.DIR_NAME);
    if (!dirReadResult.files) {
      return [];
    }

    const bookFileNames = dirReadResult.files.filter(
      filename => filename.indexOf(this.BOOK_SUFFIX) > -1
    );

    const books = [];
    let readResult;
    for (const bookFileName of bookFileNames) {
      readResult = await this.fileHelper.fileRead(this.DIR_NAME, bookFileName);
      if (readResult.data) {
        books.push(JSON.parse(readResult.data));
      }
    }
    console.log('leave get all books');

    return books;
  }

  async getOfflineBooks(): Promise<BookStorageDTO[]> {
    console.log('enter get offline books');

    const books = await this.getAll();
    console.log('leave get offline books');

    if (!books) {
      return [];
    }

    return books.filter(b => b.offline);
  }

  async getNotOfflineBooks(): Promise<BookStorageDTO[]> {
    console.log('enter get not offline books');

    const books = await this.getAll();

    console.log('leave get not offline books');

    if (!books) {
      return [];
    }

    return books.filter(b => !b.offline);
  }

  async deleteBooksNotOffline(): Promise<void> {
    console.log('enter delete books not offline');

    const notOfflineBooks = await this.getNotOfflineBooks();
    for (let i = 0; i < notOfflineBooks.length; i++) {
      await this.remove(notOfflineBooks[i].bookId);
    }
    console.log('leave delete books not offline');
  }
  remove(bookId: string): Promise<void> {
    console.log('enter remove');

    return this.fileHelper.fileDelete(this.DIR_NAME, this.bookFileName(bookId));
  }

  async exists(bookId: string): Promise<boolean> {
    console.log('enter book exists');

    const readResult = await this.fileHelper.fileRead(
      this.DIR_NAME,
      this.bookFileName(bookId)
    );
    console.log('leave book exists');

    return readResult && readResult.data && readResult.data.length > 0;
  }

  async addQuickChat(quickChat: QuickChatStorageDTO): Promise<void> {
    console.log('enter add quickchat');
    try {
      await this.fileHelper.mkdir(this.DIR_NAME);
    } catch (error) {
      console.log('making directory', error);
    }

    await this.fileHelper.fileWrite(
      this.DIR_NAME,
      this.quickchatFileName(quickChat.bookId),
      JSON.stringify(quickChat)
    );

    console.log('leave add quickchat');
  }

  async getQuickChatById(bookId: string): Promise<QuickChatStorageDTO> {
    console.log('enter get quickchat by id');

    const fileReadResult = await this.fileHelper.fileRead(
      this.DIR_NAME,
      this.quickchatFileName(bookId)
    );

    if (!fileReadResult) {
      return undefined;
    }

    console.log('leave get quickchat by id');

    return JSON.parse(fileReadResult.data);
  }

  deleteQuickChatById(bookId: string): Promise<void> {
    return this.fileHelper.fileDelete(
      this.DIR_NAME,
      this.quickchatFileName(bookId)
    );
  }

  async addAudioCues(audioCues: AudioCuesStorageDTO): Promise<void> {
    try {
      await this.fileHelper.mkdir(this.DIR_NAME);
    } catch (error) {
      console.log('making directory', error);
    }

    await this.fileHelper.fileWrite(
      this.DIR_NAME,
      this.audiocuesFileName(audioCues.bookId),
      JSON.stringify(audioCues)
    );
  }

  async getAudioCuesById(bookId: string): Promise<AudioCuesStorageDTO> {
    const fileReadResult = await this.fileHelper.fileRead(
      this.DIR_NAME,
      this.audiocuesFileName(bookId)
    );

    if (!fileReadResult) {
      return undefined;
    }

    return JSON.parse(fileReadResult.data);
  }

  deleteAudioCuesById(bookId: string): Promise<void> {
    return this.fileHelper.fileDelete(
      this.DIR_NAME,
      this.audiocuesFileName(bookId)
    );
  }

  async addScanningOptions(
    scanningOptionsDTO: ScanningOptionsStorageDTO
  ): Promise<void> {
    try {
      await this.fileHelper.mkdir(this.DIR_NAME);
    } catch (error) {
      console.log('making directory', error);
    }

    await this.fileHelper.fileWrite(
      this.DIR_NAME,
      this.scanningFileName(scanningOptionsDTO.bookId),
      JSON.stringify(scanningOptionsDTO)
    );
  }

  async getScanningOptionsById(
    bookId: string
  ): Promise<ScanningOptionsStorageDTO> {
    const fileReadResult = await this.fileHelper.fileRead(
      this.DIR_NAME,
      this.scanningFileName(bookId)
    );

    if (!fileReadResult) {
      return undefined;
    }

    return JSON.parse(fileReadResult.data);
  }

  deleteScanningOptionbsById(bookId: string): Promise<void> {
    return this.fileHelper.fileDelete(
      this.DIR_NAME,
      this.scanningFileName(bookId)
    );
  }
}
