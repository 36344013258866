import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {debounceTime, skipWhile, map, mergeMap} from 'rxjs/operators';
import {Select, Store} from '@ngxs/store';
import {
  Picto,
  SystemPictosetApiService,
  PictosetListItem,
  Command7, UserPictosetApiService, UserPicto
} from '@sonorus/api';
import {UserState} from '@sonorus/state';
import {LoaderService} from '@sonorus/core';

interface IState {
  pictoset: string;
  // search: string;
  // offset: number;
}

@Component({
  selector: 'sonorus-pictoset-picker',
  templateUrl: 'pictoset-picker.html',
  styleUrls: ['pictoset-picker.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PictosetPickerComponent implements OnInit {
  @ViewChild('c', {static: true})
  canvas: ElementRef;

  @Select(UserState.culture)
  culture$: Observable<string>;

  get pictosetValue() {
    return this.pictoSearchForm.get('pictoset').value;
  }

  get searchValue() {
    return this.pictoSearchForm.get('search').value;
  }

  public loaded: boolean = false;

  public pictosetListItems: PictosetListItem[];

  public pictos: Picto[];
  public pictoSearchForm: FormGroup;

  public userPictosetListItems: PictosetListItem[];
  public filteredUserPictos: PictosetListItem[];
  public userPictos: UserPicto[];
  public sasReadtoken: string;
  private isUserPictoset: boolean;

  private readonly limit = 9;
  private offset = 0;

  get hasNextPage() {
    return this.pictos && this.pictos.length === this.limit;
  }

  get hasPreviousPage() {
    return this.offset > 0;
  }

  private stateRestoreInProgress: boolean;

  constructor(
    private loader: LoaderService,
    private changeDetector: ChangeDetectorRef,
    private modalCtrl: ModalController,
    private pictosetApi: SystemPictosetApiService,
    private userPictosetApi: UserPictosetApiService,
    private store: Store,
    private fb: FormBuilder
  ) {
    this.pictoSearchForm = this.fb.group({
      pictoset: undefined,
      search: ''
    });
  }

  async ngOnInit() {
    await this.loader.show();

    const iso = this.store.selectSnapshot(UserState.culture);
    const langIso2 = iso.split('-')[0];

    const [pictosets, userPictosets] = await Promise.all([
      this.pictosetApi.get(langIso2).toPromise(),
      this.userPictosetApi.getForCurrentUser().toPromise()
    ]);

    this.restoreState();
    // this.changeDetector.markForCheck();

    this.pictoSearchForm
      .get('search')
      .valueChanges.pipe(
      skipWhile(s => s.length < 3),
      debounceTime(500)
    )
      .subscribe(async (search: string) => {
        this.filterPictos(search);
      });

    this.pictosetListItems = pictosets;
    this.userPictosetListItems = userPictosets;

    if (!this.pictosetValue) {
      if (userPictosets && userPictosets.length > 0) {
        this.pictoSearchForm.get('pictoset').setValue(userPictosets[0].id);
      } else {
        this.pictoSearchForm.get('pictoset').setValue(pictosets[0].id);
      }
    }

    await this.loadPictoset(this.pictosetValue);

    this.loaded = true;
    await this.loader.hide();
  }

  async filterPictos(search: string) {
    this.pictoSearchForm.get('search').setValue(search, {emitEvent: false});

    await this.loader.show();

    this.offset = 0;

    if (this.isUserPictoset) {
      if(!search || search === ''){
        this.filteredUserPictos = undefined;
      } else {
        // search for all pictosets that contain the search var as name, using a case insensitive search as a regex
        const searchLower = search.toLowerCase();
        this.filteredUserPictos = this.userPictos.filter(item => item.name.toLowerCase().includes(searchLower));
      }

      const pictosetPage = this.filteredUserPictos || this.userPictos;
      this.pictos = pictosetPage.slice(this.offset, this.offset + this.limit).map(this.mapUserPictoToPicto);
    } else {
      const pictosetPage = await this.pictosetApi.search(this.pictosetValue, search, this.offset, this.limit).toPromise();
      this.pictos = pictosetPage.pictos;
    }

    this.changeDetector.markForCheck();
    await this.loader.hide();
  }

  async getPrevious() {
    await this.loader.show();

    this.offset = this.offset - this.limit;

    if (this.isUserPictoset) {
      const pictosetPage = this.filteredUserPictos || this.userPictos;
      this.pictos = pictosetPage.slice(this.offset, this.offset + this.limit).map(this.mapUserPictoToPicto);
    } else {
      const pictosetPage = await this.pictosetApi.search(this.pictosetValue, this.searchValue, this.offset, this.limit).toPromise();
      this.pictos = pictosetPage.pictos;
    }

    this.changeDetector.markForCheck();
    await this.loader.hide();
  }

  async getNext() {
    await this.loader.show();

    this.offset = this.offset + this.limit;

    if (this.isUserPictoset) {
      const pictosetPage = this.filteredUserPictos || this.userPictos;
      this.pictos = pictosetPage.slice(this.offset, this.offset + this.limit).map(this.mapUserPictoToPicto);
    } else {
      const pictosetPage = await this.pictosetApi.search(this.pictosetValue, this.searchValue, this.offset, this.limit).toPromise();
      this.pictos = pictosetPage.pictos;
    }
    this.changeDetector.markForCheck();

    await this.loader.hide();
  }

  private async loadPictoset(pictosetId: string) {
    if (!this.stateRestoreInProgress) {
      this.offset = 0;
      this.pictoSearchForm.patchValue({
        search: ''
      }, {
        emitEvent: false
      });
    } else {
      this.stateRestoreInProgress = false;
    }

    if (this.userPictosetListItems.findIndex(val => val.id === pictosetId) !== -1) {
      // load user pictoset
      this.isUserPictoset = true;
      const response = await this.userPictosetApi.getPictosForPictoset(pictosetId).toPromise();
      this.userPictos = response.pictos;
      this.sasReadtoken = '?' + response.readSasToken;
      this.pictos = this.userPictos.slice(this.offset, this.limit).map(this.mapUserPictoToPicto);
    } else {
      // load system pictoset
      this.isUserPictoset = false;
      const pictosetPage = await this.pictosetApi.search(pictosetId, this.searchValue, this.offset, this.limit).toPromise();
      this.pictos = pictosetPage.pictos;
    }
    this.changeDetector.markForCheck();
  }

  choosePicto = async (picto: Picto, index: number) => {
    await this.loader.show();
    this.pictosetApi
      .getBase64ImageData(
        new Command7({
          url: picto.url
        })
      )
      .subscribe(async base64 => {
        await this.loader.hide();
        this.modalCtrl.dismiss(base64);
      });
  };

  cancel() {
    this.modalCtrl.dismiss();
  }

  async onPictosetChanged(event) {
    const pictosetCode = event.detail.value;
    this.pictoSearchForm.get('pictoset').setValue(pictosetCode);

    await this.loader.show();
    this.storeState();
    await this.loadPictoset(pictosetCode);
    await this.loader.hide();
  }

  //
  private storeState() {
    const state: IState = {
      pictoset: this.pictoSearchForm.value.pictoset
      // search: this.pictoSearchForm.value.search,
      // offset: this.offset
    };

    window.localStorage.setItem('PictosetPickerComponent', JSON.stringify(state));
  }

  //
  private restoreState() {
    const restoredState = JSON.parse(window.localStorage.getItem('PictosetPickerComponent')) as IState;
    if (restoredState) {
      // pagination
      // this.offset = restoredState.offset;

      // trigger search
      this.pictoSearchForm.patchValue({
        // search: restoredState.search,
        pictoset: restoredState.pictoset,
      }, {emitEvent: false});

      // this.stateRestoreInProgress = true;

      // this.changeDetector.markForCheck();
    }
  }

  private mapUserPictoToPicto = (userPicto: UserPicto) => new Picto({
    id: userPicto.id,
    name: userPicto.name,
    url: userPicto.url + this.sasReadtoken
  })
}
