import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'sonorus-invalid-subscription',
  templateUrl: './invalid-subscription.component.html',
  styleUrls: ['./invalid-subscription.component.scss']
})
export class InvalidSubscriptionComponent implements OnInit {
  param = {
    lumosUrl: environment.lumos.url,
    email: environment.contact.email
  };

  constructor() {}

  ngOnInit() {}
}
