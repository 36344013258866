import {
  Plugins,
  FilesystemDirectory,
  FilesystemEncoding
} from '@capacitor/core';

const { Filesystem } = Plugins;

export class FileSystemHelper {
  async fileWrite(dir: string, fileName: string, data: string) {
    try {
      await Filesystem.writeFile({
        path: `${dir}/${fileName}`,
        data: data,
        directory: FilesystemDirectory.Data,
        encoding: FilesystemEncoding.UTF8
      });
    } catch (e) {
      console.error('Unable to write file', e);
    }
  }

  async fileRead(dir: string, fileName: string) {
    try {
      const readResult = await Filesystem.readFile({
        path: `${dir}/${fileName}`,
        directory: FilesystemDirectory.Data,
        encoding: FilesystemEncoding.UTF8
      });

      return readResult;
    } catch (err) {
      return undefined;
    }
  }

  // async fileAppend() {
  //   await Filesystem.appendFile({
  //     path: 'secrets/text.txt',
  //     data: 'MORE TESTS',
  //     directory: FilesystemDirectory.Documents,
  //     encoding: FilesystemEncoding.UTF8
  //   });
  // }

  async fileDelete(dir: string, fileName: string) {
    await Filesystem.deleteFile({
      path: `${dir}/${fileName}`,
      directory: FilesystemDirectory.Data
    });
  }

  async mkdir(dir: string) {
    try {
      let ret = await Filesystem.mkdir({
        path: dir,
        directory: FilesystemDirectory.Data,
        recursive: false // like mkdir -p
      });
    } catch (e) {
      console.error('Unable to make directory', e);
    }
  }

  async rmdir(dir: string) {
    try {
      let ret = await Filesystem.rmdir({
        path: dir,
        directory: FilesystemDirectory.Data,
        recursive: false
      });
    } catch (e) {
      console.error('Unable to remove directory', e);
    }
  }

  readdir(dir: string) {
    try {
      return Filesystem.readdir({
        path: dir,
        directory: FilesystemDirectory.Data
      });
    } catch (e) {
      console.error('Unable to read dir', e);
    }
  }
}
