<!--
  <ion-item>
    <ion-label color="primary" position="floating">{{
      'WIDGET_PICTURE_BUTTONIMAGE' | translate
    }}</ion-label>
    <ion-button slot="end" (click)="openPictosetPopup()">
      <ion-icon name="body"></ion-icon
      >{{ 'WIDGET_PICTURE_OPENPICTOSETPOPUP' | translate }}
    </ion-button>
    <ion-button slot="end" (click)="getImageFromCamera()">
      <ion-icon name="camera"></ion-icon>{{ 'WIDGET_PICTURE_CAMERA' | translate }}
    </ion-button>
    <ion-button slot="end" (click)="getImageFromDevice()">
      <ion-icon name="image"></ion-icon>{{ 'WIDGET_PICTURE_LIBRARY' | translate }}
    </ion-button>

  </ion-item>
-->
<!-- <ion-item *ngIf="imageURI">
  <ion-label>
    <img src="{{imageURI}}" style="display: block" width="300" />
    <ion-button (click)="rotateImage()">{{
      'WIDGET_PICTURE_ROTATE' | translate
    }}</ion-button>
  </ion-label>
</ion-item> -->
<!-- END: CAMERA / PICTURE CHOOSER -->

<input #smartphoneCameraFileInput
       style="display: none;"
       type="file"
       accept="image/*"
       capture="environment"
       (change)="smartphonePictureTaken($event)" />

<input #smartphoneGalleryFileInput
       style="display: none"
       type="file"
       accept="image/*"
       (change)="smartphonePictureTaken($event)" />

<input #browserFileInput
       style="display: none"
       type="file"
       accept="image/*"
       (change)="smartphonePictureTaken($event)" />

<canvas #c
        style="display:none"></canvas>
