<div [formGroup]="scanningFormGroup"
     class="container">
  <div class="item-toggle">
    <ion-toggle formControlName="enabled"
                color="primary"></ion-toggle>
  </div>

  <div class="item-title">
    {{'BOOKS.ADMIN.WIDGETS.SCANNING_TITLE' | translate}}
  </div>

  <div class="item-buttons">
    <ion-button (click)="openSettings()"
                [title]="'BOOKS.ADMIN.WIDGETS.SCANNING_SETTINGS' | translate">
      <ion-icon name="settings"></ion-icon>
    </ion-button>
    <ion-button color="light">
      <ion-icon name="information-circle-outline"></ion-icon>
    </ion-button>
  </div>

  <!-- TOON OVERLAY MET UITLEG MAPPING KNOPPEN

  <ion-list>
    <ion-item>
      <ion-icon name="power"
                slot="start"></ion-icon>
      <ion-toggle formControlName="enabled"
                  color="primary"></ion-toggle>
    </ion-item>

  </ion-list> -->
</div>
