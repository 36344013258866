import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ILogbookEntry, LogbookEntry } from '@sonorus/api';

import * as _ from 'lodash';

export interface EntryModalProps {
  entry: ILogbookEntry;
}

export interface EntryModalResult {
  entry: ILogbookEntry;
}

@Component({
  selector: 'sonorus-entry-modal',
  templateUrl: './entry-modal.component.html',
  styleUrls: ['./entry-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogbookEntryModalComponent implements OnInit, EntryModalProps {
  entryForm: FormGroup;

  entry: ILogbookEntry;

  createdOn: Date;

  constructor(private fb: FormBuilder, private modalCtrl: ModalController) {
    this.entryForm = this.fb.group({
      author: ['', Validators.required],
      entry: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.createdOn = this.entry ? this.entry.createdOn : new Date();

    if (this.entry) {
      this.entryForm.patchValue({
        author: this.entry.author,
        entry: this.entry.content
      });
    }
  }

  saveEntry() {
    if (!this.entryForm.valid) {
      return;
    }

    const response: EntryModalResult = {
      entry: undefined
    };

    response.entry = _.cloneDeep(this.entry) || {};
    response.entry.author = this.entryForm.value.author;
    response.entry.content = this.entryForm.value.entry;

    this.modalCtrl.dismiss(response);
  }

  cancel() {
    const response: EntryModalResult = {
      entry: undefined
    };

    this.modalCtrl.dismiss(response);
  }
}
