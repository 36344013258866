import { BookDefinition } from '@sonorus/api';

export namespace UserBooks {
  export class Load {
    static readonly type = '[Book Overview] load user books';
    constructor(public userId: string) {}
  }

  export class Add {
    static readonly type = '[Books] add user book';
    constructor() {}
  }

  export class Edit {
    static readonly type = '[Books] edit user book';
    constructor(public bookDefinition: BookDefinition) {}
  }

  export class Remove {
    static readonly type = '[Books] remove user book';
    constructor() {}
  }
}
