import { Injectable } from '@angular/core';
import { PageContentStorageDTO, PageMetadataStorageDTO } from '../models';
import { booksDatabase } from './books.database';
import { IPagesRepository } from '../repositories.interfaces';
import { last } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IndexedDbPagesRepository implements IPagesRepository {
  async exists(bookId: string, pageId: string): Promise<boolean> {
    const contentExists = await booksDatabase.pageContents
      .where('pageId')
      .equals(pageId)
      .count();
    const metadataExists = await booksDatabase.pageMetadatas
      .where('pageId')
      .equals(pageId)
      .count();
    return contentExists > 0 && metadataExists > 0;
  }

  async isOutdated(
    bookId: string,
    pageId: string,
    lastModified: number
  ): Promise<boolean> {
    const metadata = await this.getPageMetadataById(bookId, pageId);

    if (metadata) {
      return metadata.lastModified < lastModified;
    }

    return true;
  }

  addPageContent(pageContent: PageContentStorageDTO) {
    return booksDatabase.transaction(
      'rw',
      booksDatabase.pageContents,
      async () => {
        await booksDatabase.pageContents.put(pageContent);
      }
    );
  }

  updatePageContent(pageContent: PageContentStorageDTO) {
    return this.addPageContent(pageContent);
  }

  addPageMetaData(pageMetadata: PageMetadataStorageDTO) {
    return booksDatabase.transaction(
      'rw',
      booksDatabase.pageMetadatas,
      async () => {
        await booksDatabase.pageMetadatas.put(pageMetadata);
      }
    );
  }

  updatePageMetaData(pageMetadata: PageMetadataStorageDTO) {
    return this.addPageMetaData(pageMetadata);
  }

  getPageContentById(bookId: string, pageId: string) {
    return booksDatabase.pageContents
      .where('pageId')
      .equals(pageId)
      .first();
  }

  getPageMetadataById(
    bookId: string,
    pageId: string
  ): Promise<PageMetadataStorageDTO> {
    return booksDatabase.pageMetadatas
      .where('pageId')
      .equals(pageId)
      .first();
  }

  getBookPageContents(bookId: string) {
    return booksDatabase.pageContents
      .where('bookId')
      .equals(bookId)
      .toArray();
  }

  getBookPageMetadata(bookId: string) {
    return booksDatabase.pageMetadatas
      .where('bookId')
      .equals(bookId)
      .toArray();
  }

  deleteBookPages(bookId: string) {
    return booksDatabase
      .transaction('rw', booksDatabase.pageContents, async () => {
        await booksDatabase.pageContents
          .where('bookId')
          .equals(bookId)
          .delete();
      })
      .then(() =>
        booksDatabase.transaction(
          'rw',
          booksDatabase.pageMetadatas,
          async () => {
            await booksDatabase.pageMetadatas
              .where('bookId')
              .equals(bookId)
              .delete();
          }
        )
      );
  }
}
