import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PIPES } from './pipes';
import { ComponentsModule } from './components/components.module';

const MODULES = [ComponentsModule];

@NgModule({
  imports: [TranslateModule.forChild(), ...MODULES],
  declarations: [...PIPES],
  exports: [...MODULES, ...PIPES]
})
export class UISharedModule {}
