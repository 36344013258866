import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { UnsavedChangesNotificationService } from './unsaved-changes-notifications.service';
import { AdminPageEditPage } from '../admin-page-edit/admin-page-edit';

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuard implements CanDeactivate<AdminPageEditPage> {
  constructor(
    private unsavedChangesNotifications: UnsavedChangesNotificationService
  ) {}

  canDeactivate(component: AdminPageEditPage): Promise<boolean> | boolean {
    if (typeof component.isDirty === 'function' && component.isDirty()) {
      return this.unsavedChangesNotifications.confirm();
    }
    return true;
  }
}
