import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnDestroy, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IVoiceParams } from '@sonorus/api';
import { LoaderService } from '@sonorus/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TtsSamplerService } from '../tts/tts-sampler.service';

@Component({
  selector: 'sonorus-text-to-speech-field',
  templateUrl: './text-to-speech-field.component.html',
  styleUrls: ['./text-to-speech-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextToSpeechFieldComponent implements OnInit, OnDestroy {

  @Input()
  text: string;
  @Input()
  voiceParams: IVoiceParams;

  @Output()
  textChanged: EventEmitter<string> = new EventEmitter<string>();

  ttsForm: FormGroup;

  private sub: Subscription;

  constructor(private ttsSampler: TtsSamplerService,
    private loader: LoaderService,
    private fb: FormBuilder) {
    this.ttsForm = this.fb.group({
      text: ''
    });

    this.sub = this.ttsForm.get('text').valueChanges.pipe(debounceTime(250)).subscribe(value => this.textChanged.emit(value));
  }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.text) {
      this.ttsForm.get('text').setValue(this.text);
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  async testVoice() {
    const buttonText = this.ttsForm.get('text').value;

    if (buttonText && buttonText.length > 0) {
      await this.loader.show();

      await this.ttsSampler
        .playTest(buttonText, this.voiceParams).toPromise();

      await this.loader.hide();
    }
  }
}
