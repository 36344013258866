import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DeviceDetectorService, LoaderService } from '@sonorus/core';
import { WidgetApiService, PictureRotateCommand } from '@sonorus/api';
import { PictosetPickerComponent } from '../pictoset-picker/pictoset-picker';
import { SmartCameraProvider } from '../services/smart-camera/smart-camera';

/**
 * Generated class for the WidgetButtonComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'sonorus-picture-picker',
  templateUrl: 'picture-picker.html'
})
export class PicturePickerComponent implements OnChanges {
  @Input()
  imageURI: string;

  @Output()
  change = new EventEmitter<string>();

  @Output()
  cancelled = new EventEmitter<void>();

  @ViewChild('smartphoneCameraFileInput', { static: true })
  smartphoneCameraFileInput: ElementRef;

  @ViewChild('smartphoneGalleryFileInput', { static: true })
  smartphoneGalleryFileInput: ElementRef;

  @ViewChild('browserFileInput', { static: true })
  browserFileInput: ElementRef;

  @ViewChild('c', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;

  public smartphoneWebMode: boolean;
  public browserWebMode: boolean;

  constructor(
    private camera: SmartCameraProvider,
    private deviceDetector: DeviceDetectorService,
    private widget: WidgetApiService,
    private loader: LoaderService,
    private modalCtrl: ModalController
  ) {
    this.deviceDetector.isNative().then(isNative => {
      this.smartphoneWebMode = this.deviceDetector.isWeb() && isNative;
      this.browserWebMode = this.deviceDetector.isWeb() && !isNative;
    });
  }

  ngOnChanges(changes: SimpleChanges) { }

  openPictosetPopup = () => {
    this.modalCtrl
      .create({
        component: PictosetPickerComponent,
        cssClass: 'big-modal',
        backdropDismiss: true
      })
      .then(popover => {
        popover.present();

        popover.onDidDismiss().then(result => {
          if (result.data) {
            this.change.emit(result.data);
          } else {
            this.cancelled.emit();
          }
        });
      });
  };

  // add input controls for mobile camera and gallery

  async getImageFromCamera() {
    if (this.smartphoneWebMode) {
      this.smartphoneCameraFileInput.nativeElement.click();
    } else {
      try {
        const base64Image = await this.camera.getImageFromCamera();
        this.imageURI = base64Image;
        this.change.emit(base64Image);
      } catch (err) {
        console.log(err);
      }
    }
  }

  async getImageFromDevice() {
    if (this.smartphoneWebMode) {
      this.smartphoneGalleryFileInput.nativeElement.click();
    } else if (this.browserWebMode) {
      this.browserFileInput.nativeElement.click();
    } else {
      const base64Image = await this.camera.getImageFromDevice();
      this.imageURI = base64Image;
      this.change.emit(base64Image);
    }
  }

  smartphonePictureTaken = $event => {
    const file: File = $event.target.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = e => {
      this.imageURI = myReader.result as string;
      this.change.emit(this.imageURI);
    };
    myReader.readAsDataURL(file);
  };

  rotateImage = async () => {
    await this.loader.show();

    const rotateResponse = await this.widget
      .rotateWidgetPicture(
        new PictureRotateCommand({
          imageDataUri: this.imageURI
        })
      )
      .toPromise();

    this.imageURI = rotateResponse.imageDataUri;

    await this.loader.hide();

    this.change.emit(this.imageURI);
  };
}
