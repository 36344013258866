import {Action, Selector, State, StateContext} from "@ngxs/store";
import {Injectable} from "@angular/core";

import {BorderSize} from "@sonorus/core";
import {COLOR_BLACK, COLOR_WHITE} from "../color-picker/color-picker.service";
import {WidgetStyleActions} from "./widget-style.actions";

export interface WidgetStyleStateModel {
  displayTextHidden?: boolean;
  borderSize?: BorderSize;
  borderColor?: string;
  backgroundColor?: string;
  textSize?: string;
  textColor?: string;
  textAlign?: string;
}

const defaults = () => {
  return {
    displayTextHidden: false,
    borderSize: BorderSize.None,
    borderColor: COLOR_WHITE,
    backgroundColor: COLOR_WHITE,
    textSize: 'regular',
    textColor: COLOR_BLACK,
    textAlign: 'top'
  };
};
@State<WidgetStyleStateModel>({
  name: 'widgetStyle',
  defaults: defaults()
})
@Injectable()
export class WidgetStyleState {
  @Selector()
  static widgetStyle(state: WidgetStyleStateModel): WidgetStyleStateModel {
    return state;
  }

  // handle WidgetStyleActions.Load action
  @Action(WidgetStyleActions.Load)
  load(ctx: StateContext<WidgetStyleStateModel>, action: WidgetStyleActions.Load) {
    // get widgetStyle from localstorage
    const widgetStyle = JSON.parse(window.localStorage.getItem('widget-style'));

    if (widgetStyle) {
      ctx.patchState(widgetStyle);
    }
  }

  @Action(WidgetStyleActions.Update)
  update(ctx: StateContext<WidgetStyleStateModel>, action: WidgetStyleActions.Update) {
    window.localStorage.setItem('widget-style', JSON.stringify(action.widgetStyle));

    // save widgetStyle to state
    ctx.patchState(action.widgetStyle);
  }
}
