import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { IPagesRepository, IBooksRepository } from './repositories.interfaces';
import { IndexedDbPagesRepository } from './indexedDb/pages.repository';
import { IndexedDbBooksRepository } from './indexedDb/books.repository';
import { PagesFileSystemRepository } from './fileSystem/pages.filesystem.repository';
import { BooksFileSystemRepository } from './fileSystem/books.filesystem.repository';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  // decide between
  // https://capacitor.ionicframework.com/docs/apis/filesystem
  // https://capacitor.ionicframework.com/docs/apis/storage
  // https://capacitor.ionicframework.com/docs/community/plugins#storage

  private useIndexedDb: boolean;

  private idbPagesRepo: IndexedDbPagesRepository;
  private get IndexedDbPagesRepository(): IndexedDbPagesRepository {
    if (!this.idbPagesRepo) {
      this.idbPagesRepo = new IndexedDbPagesRepository();
    }

    return this.idbPagesRepo;
  }

  private idbBooksRepo: IndexedDbBooksRepository;
  private get IndexedDbBooksRepository(): IndexedDbBooksRepository {
    if (!this.idbBooksRepo) {
      this.idbBooksRepo = new IndexedDbBooksRepository();
    }

    return this.idbBooksRepo;
  }

  // private storagePagesRepo: StoragePagesRepository;
  // private get SqliteStoragePagesRepository(): StoragePagesRepository {
  //   if (!this.storagePagesRepo) {
  //     this.storagePagesRepo = new StoragePagesRepository();
  //   }

  //   return this.storagePagesRepo;
  // }

  // private storageBooksRepo: StorageBooksRepository;
  // private get SqliteStorageBooksRepository(): StorageBooksRepository {
  //   if (!this.storageBooksRepo) {
  //     this.storageBooksRepo = new StorageBooksRepository();
  //   }

  //   return this.storageBooksRepo;
  // }

  // private capacitorStoragePagesRepo: CapacitorStoragePagesRepository;
  // private get CapacitorStoragePagesRepository(): CapacitorStoragePagesRepository {
  //   if (!this.capacitorStoragePagesRepo) {
  //     this.capacitorStoragePagesRepo = new CapacitorStoragePagesRepository();
  //   }

  //   return this.capacitorStoragePagesRepo;
  // }

  // private capacitorStorageBooksRepo: CapacitorStorageBooksRepository;
  // private get CapacitorStorageBooksRepository(): CapacitorStorageBooksRepository {
  //   if (!this.capacitorStorageBooksRepo) {
  //     this.capacitorStorageBooksRepo = new CapacitorStorageBooksRepository();
  //   }

  //   return this.capacitorStorageBooksRepo;
  // }

  private pagesFileSystemRepo: PagesFileSystemRepository;
  private get PagesFileSystemRepository(): PagesFileSystemRepository {
    if (!this.pagesFileSystemRepo) {
      this.pagesFileSystemRepo = new PagesFileSystemRepository();
    }

    return this.pagesFileSystemRepo;
  }

  private booksFileSystemRepo: BooksFileSystemRepository;
  private get BooksFileSystemRepository(): BooksFileSystemRepository {
    if (!this.booksFileSystemRepo) {
      this.booksFileSystemRepo = new BooksFileSystemRepository();
    }

    return this.booksFileSystemRepo;
  }

  constructor() {
    if (Capacitor.platform === 'web') {
      // do the web things
      // indexeddb
      this.useIndexedDb = true;
    } else {
      // Native
      // do the native thing with one of the plugins
      this.useIndexedDb = false;
    }
  }

  getPagesRepo(): IPagesRepository {
    return this.useIndexedDb
      ? this.IndexedDbPagesRepository
      : this.PagesFileSystemRepository;
    // : this.SqliteStoragePagesRepository;
  }

  getBooksRepo(): IBooksRepository {
    return this.useIndexedDb
      ? this.IndexedDbBooksRepository
      : this.BooksFileSystemRepository;
    // : this.SqliteStorageBooksRepository;
  }
}
