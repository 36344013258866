<!--
  Generated template for the Html5CameraPage page.

  See http://ionicframework.com/docs/components/#navigation for more info on
  Ionic pages and navigation.
-->
<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'BOOKS.ADMIN.CAMERA.TITLE' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div *ngIf="!isSmartphone">
    <video autoplay
           #myCamera></video> <img src=""
         #myImage />
    <canvas style="display:none;"
            #myCanvas></canvas>
  </div>

  <div *ngIf="isSmartphone">
    <input #smartphoneCamera
           type="file"
           accept="image/*;capture=camera"
           (change)="smartphonePictureTaken($event)" />

    <img src=""
         #myImage2 />
  </div>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons *ngIf="isSmartphone"
                 slot="primary">
      <ion-button color="primary"
                  fill="solid"
                  (click)="approvePicture()">{{
        'HTML5_CAMERA_CHOOSE_SCREENSHOT' | translate
        }}</ion-button>
    </ion-buttons>
    <ion-buttons *ngIf="!isSmartphone"
                 slot="primary">
      <ion-button *ngIf="!base64Image"
                  color="success"
                  fill="solid"
                  (click)="takePicture()">
        <ion-icon slot="start"
                  name="camera"></ion-icon>
        {{ 'HTML5_CAMERA_TAKE_SCREENSHOT' | translate }}
      </ion-button>
      <ion-button *ngIf="!base64Image"
                  color="success"
                  fill="solid"
                  (click)="takeTimerPicture()">
        <ion-icon slot="start"
                  name="camera"></ion-icon>
        <ion-icon slot="end"
                  name="time"></ion-icon>
        {{ countdown }}
      </ion-button>
      <ion-button *ngIf="base64Image"
                  color="success"
                  fill="solid"
                  (click)="approvePicture()">
        <ion-icon slot="start"
                  name="checkmark"></ion-icon>
        {{ 'HTML5_CAMERA_CHOOSE_SCREENSHOT' | translate }}
      </ion-button>

      <ion-button *ngIf="base64Image"
                  color="danger"
                  fill="solid"
                  (click)="reset()">
        <ion-icon slot="start"
                  name="close"></ion-icon>
        {{ 'HTML5_CAMERA_RESET' | translate }}
      </ion-button>
    </ion-buttons>
    <ion-buttons *ngIf="!isSmartphone"
                 slot="secondary">
      <ion-button color="primary"
                  *ngIf="!base64Image && webcamCount > 1"
                  fill="solid"
                  (click)="onSwitchCamera()">
        <ion-icon slot="start"
                  name="camera-reverse"></ion-icon>
        {{ 'HTML5_CAMERA_SWITCH' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
