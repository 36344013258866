<ion-header>
  <ion-toolbar color="primary">
    <ion-title *ngIf="!isEdit">{{ 'WIDGET_ADD_NEW' | translate }}</ion-title>
    <ion-title *ngIf="isEdit">{{ 'WIDGET_EDIT' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div [hidden]="!showBackdrop"
       style="position: fixed; top: 0px; left: 0px; width: 100vw; height: 100vh; background: #000; opacity: 0.32; z-index: 3;">
  </div>

  <form [formGroup]="widgetForm">
    <ion-grid>
      <ion-row>
        <ion-col size-xl="4"
                 size-lg="5"
                 size-md="6"
                 size="12">

          <mat-accordion class="example-headers-align">

            <mat-expansion-panel expanded="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <!-- <ion-item>
                    <ion-icon name="images"
                              slot="start"></ion-icon>
                    <ion-label>{{ 'BOOKS.ADMIN.WIDGETS.BUTTON_IMAGES' | translate}}</ion-label>
                  </ion-item> -->
                  <h5>
                    <ion-icon name="images"></ion-icon>
                  </h5>
                  <h5>
                    {{ 'BOOKS.ADMIN.WIDGETS.BUTTON_IMAGES' | translate}}
                  </h5>
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
              </mat-expansion-panel-header>

              <!-- <ion-item (click)="searchImages()"
                        button="true"
                        lines="full">
                <ion-icon slot="start"
                          name="search"></ion-icon>
                <ion-label class="ion-text-wrap"> {{ 'WIDGET_SEARCH_IMAGES' | translate }} </ion-label>
              </ion-item> -->
              <ion-item (click)="searchGifs()"
                        button="true"
                        lines="full">
                <ion-icon slot="start"
                          name="search"></ion-icon>
                <ion-label class="ion-text-wrap"> {{ 'WIDGET_SEARCH_GIFS' | translate }} </ion-label>
              </ion-item>
              <ion-item (click)="picturePicker.openPictosetPopup()"
                        button="true"
                        lines="full">
                <ion-icon slot="start"
                          name="body"></ion-icon>
                <ion-label class="ion-text-wrap">
                  {{ 'WIDGET_PICTURE_OPENPICTOSETPOPUP' | translate }}
                </ion-label>
              </ion-item>
              <ion-item (click)="picturePicker.getImageFromCamera()"
                        button="true"
                        lines="full">
                <ion-icon slot="start"
                          name="camera"></ion-icon>
                <ion-label class="ion-text-wrap"> {{ 'WIDGET_PICTURE_CAMERA' | translate }} </ion-label>
              </ion-item>
              <ion-item (click)="picturePicker.getImageFromDevice()"
                        button="true"
                        lines="full">
                <ion-icon name="image"
                          slot="start"></ion-icon>
                <ion-label class="ion-text-wrap">
                  {{ 'WIDGET_PICTURE_LIBRARY' | translate }}
                </ion-label>
              </ion-item>
              <ion-item *ngIf="imageURI"
                        color="danger"
                        button
                        (click)="onDeleteImageClicked()">
                <ion-icon slot="start"
                          name="trash"></ion-icon>
                <ion-label class="ion-text-wrap">
                  {{ 'WIDGET_PICTURE_DELETE' | translate }}
                </ion-label>
              </ion-item>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h5>
                    <ion-icon name="text"></ion-icon>
                  </h5>
                  <h5>{{'BOOKS.ADMIN.WIDGETS.TEXT_PROPERTIES' | translate}}
                  </h5>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <ion-item class="widget-text">
                <ion-label position="stacked">{{ 'WIDGET_BUTTON_TEXT' | translate }}</ion-label>
                <ion-input type="text"
                           formControlName="text"></ion-input>
                <!-- <ion-button slot="end"
                            (click)="testVoice()"
                            type="button">
                  <ion-icon name="megaphone"></ion-icon>
                </ion-button> -->
                <ion-button slot="end"
                            type="button"
                            (click)="toggleDisplayTextVisibility()">
                  <ion-icon *ngIf="!buttonTextHidden"
                            name="eye"></ion-icon>
                  <ion-icon *ngIf="buttonTextHidden"
                            name="eye-off"></ion-icon>
                </ion-button>
              </ion-item>
              <ion-item>
                <ion-label position="stacked">
                  {{ 'BOOKS.ADMIN.WIDGETS.TEXT_SIZE_TITLE' | translate }}
                </ion-label>
                <ion-select formControlName="textSize"
                            [okText]="'OK_BUTTON_TEXT' | translate"
                            [cancelText]="'CANCEL_BUTTON_TEXT' | translate"
                            [placeholder]="'BOOKS.ADMIN.WIDGETS.TEXT_SIZE_PLACEHOLDER' | translate"
                            itemEnd>
                  <ion-select-option value="small">
                    {{ 'BOOKS.ADMIN.WIDGETS.TEXT_SIZE_1' | translate }}
                  </ion-select-option>
                  <ion-select-option value="regular">
                    {{ 'BOOKS.ADMIN.WIDGETS.TEXT_SIZE_2' | translate }}
                  </ion-select-option>
                  <ion-select-option value="big">
                    {{ 'BOOKS.ADMIN.WIDGETS.TEXT_SIZE_3' | translate }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ 'BOOKS.ADMIN.WIDGETS.TEXT_ALIGN_TITLE' | translate }}
                </ion-label>
                <ion-select formControlName="textAlign"
                            [okText]="'OK_BUTTON_TEXT' | translate"
                            [cancelText]="'CANCEL_BUTTON_TEXT' | translate"
                            [placeholder]="'BOOKS.ADMIN.WIDGETS.TEXT_ALIGN_PLACEHOLDER' | translate"
                            itemEnd>
                  <ion-select-option value="top">
                    {{ 'BOOKS.ADMIN.WIDGETS.TEXT_ALIGN_1' | translate }}
                  </ion-select-option>
                  <ion-select-option value="middle">
                    {{ 'BOOKS.ADMIN.WIDGETS.TEXT_ALIGN_2' | translate }}
                  </ion-select-option>
                  <ion-select-option value="bottom">
                    {{ 'BOOKS.ADMIN.WIDGETS.TEXT_ALIGN_3' | translate }}
                  </ion-select-option>
                </ion-select>
              </ion-item>

            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h5>
                    <ion-icon name="radio"></ion-icon>
                  </h5>
                  <h5>{{ 'BOOKS.ADMIN.WIDGETS.SOUND_PROPERTIES' | translate }}</h5>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <ng-template matExpansionPanelContent>
                <sonorus-admin-widget-edit-audio [audioUri]="audioURI"
                                                 [spokenText]="spokenText"
                                                 [speechType]="speechType"
                                                 (audioChanged)="onAudioChanged($event)"
                                                 (audioDataUriChanged)="onAudioDataUriChanged($event)"
                                                 (speechTypeChanged)="onSpeechTypeChanged($event)"
                                                 (spokenTextChanged)="onSpokenTextChanged($event)">
                </sonorus-admin-widget-edit-audio>
              </ng-template>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h5>
                    <ion-icon name="color-palette"></ion-icon>
                  </h5>
                  <h5>{{ 'BOOKS.ADMIN.WIDGETS.COLOR_PROPERTIES' |
                    translate}}
                  </h5>
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
              </mat-expansion-panel-header>

              <ion-item button="true"
                        (click)="changeTextColor()">
                <!-- Text color -->
                <ion-label>
                  {{ 'BOOKS.ADMIN.WIDGETS.TEXT_COLOR_TITLE' | translate}}
                </ion-label>
                <ion-icon name="color-palette"
                          slot="end"></ion-icon>
                <ion-button
                            [style]="sanitizer.bypassSecurityTrustStyle('--background: ' + textColor + '; --background-hover: ' + textColor + '; --box-shadow: 1px 1px 1px #AAAAAA;')">
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </ion-button>
              </ion-item>
              <ion-item button="true"
                        (click)="changeBackgroundColor()">
                <!-- Background color -->
                <ion-label>
                  {{ 'BOOKS.ADMIN.WIDGETS.TEXT_COLOR_BACKGROUND' | translate}}
                </ion-label>
                <ion-icon name="color-fill"
                          slot="end"></ion-icon>
                <ion-button
                            [style]="sanitizer.bypassSecurityTrustStyle('--background: ' + backgroundColor + '; --background-hover: ' + backgroundColor + '; --box-shadow: 1px 1px 1px #AAAAAA;')">
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </ion-button>
              </ion-item>
              <ion-item button="true"
                        (click)="changeBorderColor()">
                <ion-label>
                  {{ 'BOOKS.ADMIN.WIDGETS.BORDER_COLOR_TITLE' | translate }}
                </ion-label>
                <ion-icon name="color-palette"
                          slot="end"></ion-icon>
                <ion-button
                            [style]="sanitizer.bypassSecurityTrustStyle('--background: ' + borderColor + '; --background-hover: ' + borderColor + '; --box-shadow: 1px 1px 1px #AAAAAA;')">
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </ion-button>
              </ion-item>
              <ion-item>
                <ion-label position="stacked">
                  {{ 'BOOKS.ADMIN.WIDGETS.BORDER_SIZE_TITLE' | translate}}
                </ion-label>
                <ion-select formControlName="borderSize"
                            [okText]="'OK_BUTTON_TEXT' | translate"
                            [cancelText]="'CANCEL_BUTTON_TEXT' | translate"
                            [placeholder]="'BOOKS.ADMIN.WIDGETS.BORDER_SIZE_PLACEHOLDER' | translate"
                            itemEnd>
                  <ion-select-option value="none">
                    {{'BOOKS.ADMIN.WIDGETS.BORDER_SIZE_1' | translate}}
                  </ion-select-option>
                  <ion-select-option value="small">
                    {{'BOOKS.ADMIN.WIDGETS.BORDER_SIZE_2' | translate}}
                  </ion-select-option>
                  <ion-select-option value="large">
                    {{'BOOKS.ADMIN.WIDGETS.BORDER_SIZE_3' | translate}}
                  </ion-select-option>
                </ion-select>
              </ion-item>

            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>

                  <h5>
                    <ion-icon name="color-wand"></ion-icon>
                  </h5>
                  <h5>{{ 'BOOKS.ADMIN.WIDGETS.ACTION_PROPERTIES' |
                    translate}}
                  </h5>
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
              </mat-expansion-panel-header>

              <ion-item>
                <ion-label position="stacked">{{ 'WIDGET_ACTION' | translate }}</ion-label>
                <ion-select formControlName="clickActionType"
                            [okText]="'OK_BUTTON_TEXT' | translate"
                            [cancelText]="'CANCEL_BUTTON_TEXT' | translate"
                            [placeholder]="'BOOKS.ADMIN.WIDGETS.CHOOSE_ACTION_TYPE' | translate"
                            itemEnd>
                  <ion-select-option [value]="CLICK_ACTION_NOTHING">
                    {{ 'WIDGET_ACTION_DEFAULT' | translate }}
                  </ion-select-option>
                  <ion-select-option [value]="CLICK_ACTION_PAGE">
                    {{ 'WIDGET_ACTION_PAGE' | translate }}
                  </ion-select-option>
                  <ion-select-option [value]="CLICK_ACTION_YOUTUBE">
                    {{ 'WIDGET_ACTION_YOUTUBE' | translate }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
              <ion-item *ngIf="clickActionPage">
                <ion-label position="stacked">
                  {{'WIDGET_SEARCH_PAGES_TITLE' | translate}}
                </ion-label>
                <ion-input readonly="true"
                           type="text"
                           [value]="goToPageTitle"
                           (click)="searchPages()"></ion-input>

                <ion-button *ngIf="clickActionPage && goToPageId"
                            slot="end"
                            color="danger"
                            (click)="removeGoToPage()">
                  <ion-icon name="trash"></ion-icon>
                </ion-button>
                <ion-button *ngIf="clickActionPage && !goToPageId"
                            slot="end"
                            (click)="searchPages()">
                  <ion-icon name="search"></ion-icon>
                </ion-button>
              </ion-item>
              <ion-item *ngIf="clickActionYoutube && !youtubeVideoId">
                <ion-label position="stacked">
                  {{ 'WIDGET_SEARCH_YOUTUBE' | translate }}
                </ion-label>
                <ion-input readonly="true"
                           type="text"
                           (click)="searchYoutube()"></ion-input>
                <ion-button slot="end"
                            (click)="searchYoutube()">
                  <ion-icon name="search"></ion-icon>
                </ion-button>
              </ion-item>
              <ion-item *ngIf="clickActionYoutube && youtubeVideoId">
                <img [src]="youtubeVideoThumbnailUrl" />
              </ion-item>
              <ion-item *ngIf="clickActionYoutube && youtubeVideoId">
                <ion-button slot="start"
                            (click)="chooseYTThumbnailForButton()"
                            [title]="'WIDGET_YOUTUBE_CHOOSE_THUMBNAIL' | translate">
                  <ion-icon name="logo-youtube"></ion-icon>
                  <ion-icon name="image"></ion-icon>
                </ion-button>
                <ion-label></ion-label>
                <ion-button slot="end"
                            color="danger"
                            (click)="removeVideo()">
                  <ion-icon name="trash"></ion-icon>
                </ion-button>
              </ion-item>
            </mat-expansion-panel>

          </mat-accordion>
        </ion-col>
        <ion-col size-xl="8"
        size-lg="7"
        size-md="6"
        size="12">
          <ion-list>
            <sonorus-picture-picker #picturePicker
                                    [imageURI]="imageURI"
                                    (change)="showBackdrop = true; pictureChanged($event);"
                                    (cancelled)="showBackdrop = false"></sonorus-picture-picker>
          </ion-list>

          <div style="display: flex; justify-content: center; align-items: flex-start; height: 100%; width: 100%;">
            <div style="min-height: 300px; min-width: 400px; max-width: 60%; flex: 0 1 auto;">
              <widget [item]="gridsterItem"
                      [fontCssClass]="fontCssClass"></widget>
            </div>
          </div>

        </ion-col>
      </ion-row>
    </ion-grid>
  </form>

  <!-- <p>
    DEBUG:</p>
  <p>WIDGET FORM: {{ widgetForm.value | json }}</p>
  <p>WIDGET VALID: {{ widgetForm.valid }}</p>
  <p>GO TO PAGE: goToPageId: {{this.goToPageId}} goToPageTitle: {{this.goToPageTitle}}</p> -->
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="primary">
      <ion-button color="primary"
                  [disabled]="!widgetForm.valid"
                  (click)="save()"
                  fill="solid">{{ 'WIDGET_SAVE' | translate }}</ion-button>
      <ion-button color="danger"
                  (click)="cancel()"
                  fill="solid">
        {{ 'WIDGET_CANCEL' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
