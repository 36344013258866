import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'sonorus-share-content-modal',
  templateUrl: './share-content-modal.component.html',
  styleUrls: ['./share-content-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareContentModalComponent implements OnInit {
  shareForm: FormGroup;

  constructor(private fb: FormBuilder, private modalCtrl: ModalController) {
    this.shareForm = this.fb.group({
      to: ['', Validators.compose([Validators.required, Validators.email])],
      message: ['', Validators.required]
    });
  }

  ngOnInit() {
  }

  shareContent() {
    if (this.shareForm.valid) {
      const response: ShareContentModalResponse = {
        to: this.shareForm.value.to,
        message: this.shareForm.value.message
      };

      this.modalCtrl.dismiss(response);
    }
  }

  cancel() {
    this.modalCtrl.dismiss();
  }
}

export interface ShareContentModalResponse {
  to: string;
  message: string;
}