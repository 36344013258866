<!-- <p>admin-widget-edit-audio works!</p> -->
<ion-segment [scrollable]="true"
             [value]="speechType"
             (ionChange)="onSpeechTypeSelected($event)">
    <ion-segment-button [value]="TEXTTOSPEECH">
        <!-- text to speech -->
        <ion-icon name="chatbubble-ellipses-outline"></ion-icon>
    </ion-segment-button>
    <ion-segment-button [value]="RECORDING">
        <!-- record your own -->
        <ion-icon name="mic-outline"></ion-icon>
    </ion-segment-button>
    <ion-segment-button [value]="UPLOAD">
        <!-- upload your own -->
        <ion-icon name="cloud-upload-outline"></ion-icon>
    </ion-segment-button>
    <ion-segment-button [value]="MUTE">
        <!-- no sound -->
        <ion-icon name="volume-mute-outline"></ion-icon>
    </ion-segment-button>
</ion-segment>


<sonorus-text-to-speech-field *ngIf="speechType === TEXTTOSPEECH"
                              [text]="spokenText"
                              [voiceParams]="voiceParams"
                              (textChanged)="onSpokenTextChanged($event)"></sonorus-text-to-speech-field>
<sonorus-mini-audio-recorder *ngIf="speechType === RECORDING"
                             [audioDataUri]="initialRecordingAudioUri"
                             (newAudio)="onRecordedAudioChanged($event)"
                             (newDataUri)="onRecordedDataUriChanged($event)"></sonorus-mini-audio-recorder>
<sonorus-mini-audio-uploader *ngIf="speechType === UPLOAD"
                             [audioDataUri]="initialUploadAudioUri"
                             (newAudio)="onUploadedAudioChanged($event)"></sonorus-mini-audio-uploader>
