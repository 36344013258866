import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { AudioComponent } from './audio-recorder.component';
import { MiniAudioRecorderComponent } from './mini-audio-recorder/mini-audio-recorder.component';
import { MiniAudioUploaderComponent } from './mini-audio-uploader/mini-audio-uploader.component';
import { TextToSpeechFieldComponent } from './text-to-speech-field/text-to-speech-field.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [AudioComponent, MiniAudioRecorderComponent, MiniAudioUploaderComponent, TextToSpeechFieldComponent],
  imports: [
    TranslateModule,
    IonicModule,
    CommonModule,
    ReactiveFormsModule
  ],
  exports: [AudioComponent, MiniAudioRecorderComponent, MiniAudioUploaderComponent, TextToSpeechFieldComponent]
})
export class SonorusAudioModule { }
