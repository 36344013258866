import { Injectable, Inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { LoadUserByAuth0Id } from "@sonorus/state";

import { IAuthenticationProvider } from './authentication.interface';
import {CORE_CONFIGURATION, CoreConfiguration} from '../../config/core-configuration';
import {
  Plugins
  // registerWebPlugin
  // , AppLaunchUrl
} from '@capacitor/core';
import { OAuth2AuthenticateOptions } from '@byteowls/capacitor-oauth2';
import { AuthSecureStorageWrapper } from './device/capacitor/secure-storage.service';
import { AuthenticationBase } from './authentication-base';

// import { RequestorService } from './device/angular/requestor.service';
// import { CapacitorBrowserService } from './device/capacitor/browser.service';
// import { AuthSecureStorageWrapper } from './device/capacitor/secure-storage.service';

const { OAuth2Client } = Plugins;

// import { registerWebPlugin } from '@capacitor/core';

/*
  Generated class for the AuthenticationProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: 'root'
})
export class AuthenticationDeviceProvider2 extends AuthenticationBase
  implements IAuthenticationProvider {
  public loading = true;

  // private oauth2Client: OAuth2ClientPlugin;
  private oauth2Options: OAuth2AuthenticateOptions;

  // private auth0;
  // private cordovaClient;
  // private accessToken: string;
  // private user: any;
  // private loggedIn: boolean;

  constructor(
    @Inject(CORE_CONFIGURATION) private coreConfig: CoreConfiguration,
    private store: Store,
    private secureStorage: AuthSecureStorageWrapper
  ) {
    super();

    this.addConfig();
  }

  private addConfig() {
    if (!this.coreConfig.auth || !this.coreConfig.auth.app) {
      return;
    }

    this.oauth2Options = {
      appId: this.coreConfig.auth.app.clientId,
      authorizationBaseUrl: 'https://sonorus.eu.auth0.com/authorize',
      accessTokenEndpoint: 'https://sonorus.eu.auth0.com/oauth/token',
      responseType: 'code',
      // resourceUrl: 'https://sonorus.eu.auth0.com/userinfo',
      scope: 'openid profile email offline_access',
      additionalParameters: {
        audience: this.coreConfig.api.audience
      },
      web: {
        redirectUrl: 'com.sonorus.app://callback' //window.location.origin
      },
      android: {
        customScheme: 'com.sonorus.app://callback',
        responseType: 'code',
        additionalParameters: {
          audience: this.coreConfig.api.audience
        }
      },
      ios: {
        customScheme: 'com.sonorus.app://callback',
        responseType: 'code'
      }
    };
  }

  login = () => {
    console.log('auth device - login');
    OAuth2Client.authenticate(this.oauth2Options)
      .then(async resourceUrlResponse => {
        console.log('auth device - authenticated - token', resourceUrlResponse);
        const {
          access_token,
          expires_in,
          expires_at,
          id_token,
          refresh_token,
          scope,
          token_type
        } = resourceUrlResponse;

        await this.secureStorage.setItem('refresh_token', refresh_token);
        const expires = expires_in || expires_at;
        const expiresAt = JSON.stringify(expires * 1000 + new Date().getTime());
        localStorage.setItem('access_token', access_token);
        localStorage.setItem('id_token', id_token);
        localStorage.setItem('expires_at', expiresAt);

        await this.store
          .dispatch(new LoadUserByAuth0Id(this.getAuth0Id()))
          .toPromise();
        this.authenticatedSubject.next(true);
      })
      .catch(reason => {
        console.error('OAuth rejected', reason);
      });
  };

  logout = () => {
    console.log('auth device - logout');
    OAuth2Client.logout(this.oauth2Options)
      .then(async () => {
        // do something
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        this.secureStorage.clear();

        this.authenticatedSubject.next(false);
      })
      .catch(reason => {
        console.error('OAuth logout failed', reason);
      });
  };

  handleAuthentication(): Promise<boolean> {
    console.log('auth device - handleAuthentication');
    const amIAuthenticated = this.isAuthenticated();

    console.log('auth device - handleAuthentication - auth', amIAuthenticated);

    this.authenticatedSubject.next(amIAuthenticated);

    return Promise.resolve(amIAuthenticated);
  }
}
