import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'sonorus-duplicate-page-dialog',
  templateUrl: './duplicate-page-dialog.component.html',
  styleUrls: ['./duplicate-page-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DuplicatePageDialogComponent implements OnInit, DuplicatePageDialogParams {

  duplicatePageForm: FormGroup;

  constructor(private fb: FormBuilder, private modalCtrl: ModalController) {
    this.duplicatePageForm = this.fb.group({
      title: ['', Validators.required]
    });
   }

  ngOnInit() {
  }

  duplicatePage(){
    if(this.duplicatePageForm.valid){
      const response: DuplicatePageDialogResult = {
        title: this.duplicatePageForm.value.title
      };
      this.modalCtrl.dismiss(response);
    }
  }

  cancel(){
    this.modalCtrl.dismiss(undefined);
  }
}

export interface DuplicatePageDialogParams{}

export interface DuplicatePageDialogResult{
  title: string
}
