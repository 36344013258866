export const environment = {
  production: '#{env.production}#'.toLowerCase() === 'true',
  envName: '#{env.envName}#',
  contact: {
    email: '#{env.contact.email}#'
  },
  lumos: {
    url: '#{env.lumos.url}#'
  },
  api: {
    url: '#{env.api.url}#',
    audience: '#{env.api.audience}#'
  },
  auth: {
    app: {
      // Needed for Auth0 (capitalization: ID):
      clientID: '#{env.auth.app.clientId}#',
      // Needed for Auth0Cordova (capitalization: Id):
      clientId: '#{env.auth.app.clientId}#',
      domain: '#{env.auth.domain}#',
      // callbackURL: location.href,
      packageIdentifier: '#{env.auth.app.packageIdentifier}#', // config.xml widget ID, e.g., com.auth0.ionic
      scope: '#{env.auth.scope}#'
    },
    browser: {
      clientID: '#{env.auth.app.clientId}#',
      domain: '#{env.auth.domain}#',
      responseType: 'token id_token',
      // audience: "https://dev-sonorus-api.azurewebsites.net/",
      // redirectUri: "http://localhost:8100",
      scope: '#{env.auth.scope}#',
      //https://dev-sonorus-api.azurewebsites.net/
      redirectUri: '/login-callback'
    }
  },
  youtubeApiKey: '#{env.youtubeApiKey}#'
};
