import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavParams, ModalController } from '@ionic/angular';
import { LoaderService } from '@sonorus/core';
import { UserState } from '@sonorus/state';
import { TtsSamplerService } from '@sonorus/audio';

import {
  BookDefinition,
  TTSApiService,
  IVoiceParams,
  BookWithAssets, VoiceParams
} from '@sonorus/api';
import * as _ from 'lodash';
import { Store } from '@ngxs/store';

/**
 * Generated class for the AdminEditBookPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-admin-book-voice',
  templateUrl: 'admin-book-voice.html'
})
export class AdminBookVoicePage implements OnInit, OnDestroy {
  public book: BookWithAssets;
  public voiceSettings: VoiceParams[];
  public bookForm: FormGroup;
  public get isNew() {
    return !this.book.id || this.book.id === '';
  }

  constructor(
    private ttsSampler: TtsSamplerService,
    private modalCtrl: ModalController,
    private ttsApiService: TTSApiService,
    private store: Store,
    private navParams: NavParams,
    private fb: FormBuilder,
    private loader: LoaderService
  ) {
    this.bookForm = this.fb.group({
      id: 0,
      // title: [null, Validators.required],
      bookVoiceSetting: [null, Validators.required],
      bookVoicePitch: [0, Validators.required],
      bookVoiceRate: [0, Validators.required],
      testSentence: ''
    });
  }

  async ngOnInit() {
    await this.loader.show();

    console.log('ng on init', this.navParams.data);
    if (this.navParams.data && this.navParams.data.book) {
      console.log('edit book', this.navParams.data.book);
      this.book = this.navParams.data.book;
    } else {
      console.log('new book');
      this.book = new BookWithAssets({
        id: '',
        startPageId: '',
        // goToHomeButton: true,
        // sentenceBox: false,
        // title: '',
        pages: []
      });
    }

    const voiceSettings = await this.ttsApiService.getOptions().toPromise();
    const language = this.store.selectSnapshot(UserState.language);

    this.voiceSettings = voiceSettings.filter(
      setting => setting.language === language
    );

    this.fillForm(this.book);

    await this.loader.hide();
  }

  ngOnDestroy() { }

  saveBook() {
    if (this.bookForm.valid) {
      // this.book.title = this.bookForm.get('title').value;
      this.book.voiceParams = this.getVoiceSettingsFromForm();

      this.modalCtrl.dismiss(this.book);
    }
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  constructVoiceSettingsId(voiceParams: IVoiceParams) {
    return voiceParams.culture + '_' + voiceParams.name;
  }

  deconstructVoiceSettingsId(voiceSettingsId: string) {
    return voiceSettingsId.split('_');
  }

  async testVoice() {
    const sentence = this.bookForm.get('testSentence').value;
    if (!sentence) {
      return;
    }

    await this.loader.show();
    const voiceParams = this.getVoiceSettingsFromForm();

    this.ttsSampler
      .playTest(sentence, voiceParams)
      .subscribe(async () => this.loader.hide());
  }

  private getVoiceSettingsFromForm() {
    const [culture, voiceName] = this.bookForm
      .get('bookVoiceSetting')
      .value.split('_');

    const voiceParams = _.cloneDeep(
      this.voiceSettings.find(
        v => v.name === voiceName && v.culture === culture
      )
    );

    voiceParams.rate = this.bookForm.get('bookVoiceRate').value;
    voiceParams.pitch = this.bookForm.get('bookVoicePitch').value;

    return voiceParams;
  }

  private fillForm(book: BookDefinition) {
    let voiceSettingKey: string;
    let currentVoiceSetting;

    if (!book.voiceParams) {
      currentVoiceSetting = this.voiceSettings[0];
    } else {
      currentVoiceSetting = this.voiceSettings.find(
        setting =>
          setting.culture === book.voiceParams.culture &&
          setting.name === book.voiceParams.name
      );

      if (!currentVoiceSetting) {
        currentVoiceSetting = this.voiceSettings[0];
      }
    }

    voiceSettingKey = `${currentVoiceSetting.culture}_${currentVoiceSetting.name
      }`;

    this.bookForm.patchValue({
      id: book.id,
      // title: book.title,
      bookVoiceSetting: voiceSettingKey,
      bookVoiceRate: book.voiceParams ? book.voiceParams.rate : 0,
      bookVoicePitch: book.voiceParams ? book.voiceParams.pitch : 0,
      testSentence: ''
    });
  }
}
