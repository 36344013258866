import { LoadingController } from '@ionic/angular';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loader: HTMLIonLoadingElement;

  constructor(private loadingController: LoadingController) {}

  async show() {
    // await this.hide();
    if (!this.loader) {
      this.loader = await this.loadingController.create({
        translucent: true,
        spinner: 'circles'
        // spinner: 'crescent'
        // spinner: 'dots'
        // spinner: 'lines'
        // spinner: 'lines-small'
        // spinner: 'bubbles' // "bubbles" | "circles" | "circular" | "crescent" | "dots" | "lines" | "lines-small" | null | undefined
      });
      await this.loader.present();
    }
  }

  async hide() {
    if (this.loader) {
      await this.loader.dismiss();
      this.loader = undefined;
    }
  }
}
