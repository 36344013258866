import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { YoutubeDataAPI } from 'youtube-v3-api';

import { CORE_CONFIGURATION } from '../../config/core-configuration';

import { YoutubeSearchResponse } from './searchResponse.model';

/*
  Generated class for the YtProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class YoutubeProvider {
  private readonly apiKey = this.coreConfig.youtubeApiKey;

  constructor(
    public http: HttpClient,
    @Inject(CORE_CONFIGURATION) private coreConfig
  ) {
    // console.log('Hello YtProvider Provider');
  }

  async getPlaylistsForChannel(channel) {
    const url =
      'https://www.googleapis.com/youtube/v3/playlists?key=' +
      this.apiKey +
      '&channelId=' +
      channel +
      '&part=snippet,id&maxResults=50';

    const response = await fetch(url, {
      cache: 'no-cache'
    });

    return response.json()['items'];

    // return this.http
    //   .get(
    //     'https://www.googleapis.com/youtube/v3/playlists?key=' +
    //       this.apiKey +
    //       '&channelId=' +
    //       channel +
    //       '&part=snippet,id&maxResults=20'
    //   )
    //   .pipe(
    //     map((res: Response) => {
    //       return res.json()['items'];
    //     })
    //   );
  }

  async getListVideos(listId) {
    const url =
      'https://www.googleapis.com/youtube/v3/playlistItems?key=' +
      this.apiKey +
      '&playlistId=' +
      listId +
      '&part=snippet,id&maxResults=50';

    const response = await fetch(url, {
      cache: 'no-cache'
    });

    return response.json()['items'];

    // return this.http
    //   .get(
    //     'https://www.googleapis.com/youtube/v3/playlistItems?key=' +
    //       this.apiKey +
    //       '&playlistId=' +
    //       listId +
    //       '&part=snippet,id&maxResults=20'
    //   )
    //   .pipe(
    //     map((res: Response) => {
    //       return res.json()['items'];
    //     })
    //   );
  }

  // https://developers.google.com/youtube/v3/docs/search/list#usage
  async searchVideos(search: string): Promise<YoutubeSearchResponse> {
    const api = new YoutubeDataAPI(this.apiKey);
    const searchResults = await api.searchAll(encodeURI(search), 25, {
      part: 'snippet',
      type: 'video', // video, channel, playlist
      maxResults: 50,
      safeSearch: 'none',
      videoEmbeddable: 'true',
      videoSyndicated: 'true' // only return videos playable outside of yt
    });

    return searchResults as YoutubeSearchResponse;

    // const url = `https://www.googleapis.com/youtube/v3/search?key=${
    //   this.apiKey
    // }&part=snippet&maxResults=25&type=video&videoEmbeddable=true&videoSyndicated=true&q=${encodeURI(
    //   search
    // )}`;

    // const response = await fetch(url);
    // return response.json();

    // return this.http.get<YoutubeSearchResponse>(
    //   'https://www.googleapis.com/youtube/v3/search',
    //   {
    //     params: {
    //       key: this.apiKey,
    //       part: 'snippet',
    //       maxResults: '25',
    //       type: 'video', // video, channel, playlist
    //       videoEmbeddable: 'true',
    //       videoSyndicated: 'true', // only return videos playable outside of yt
    //       q: search
    //     }
    //   }
    // );
  }
}
