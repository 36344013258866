import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { BookTemplate, BookTemplateApiService } from '@sonorus/api';
import { BookTemplatesActions } from './book-templates.actions';

export class BookTemplatesStateModel {
  public tagBookTemplates: Map<string, Set<BookTemplate>>;
}

const defaults = {
  tagBookTemplates: new Map<string, Set<BookTemplate>>()
};

@State<BookTemplatesStateModel>({
  name: 'bookTemplates',
  defaults
})
@Injectable()
export class BookTemplatesState {

  @Selector()
  static tags(state: BookTemplatesStateModel) {
    return Array.from(state.tagBookTemplates.keys()).sort();
  }

  constructor(private bookTemplateApi: BookTemplateApiService) { }

  @Action(BookTemplatesActions.LoadIntoState)
  async loadStateFromServer({ getState, setState }: StateContext<BookTemplatesStateModel>, action: BookTemplatesActions.LoadIntoState) {
    const bookTemplates = await this.bookTemplateApi.getAll(undefined, true).toPromise();

    const tagBookTemplates = new Map<string, Set<BookTemplate>>();

    for (let bookTemplate of bookTemplates) {
      if (bookTemplate.enabled) {
        for (let tag of bookTemplate.tags) {
          if (!tagBookTemplates.has(tag)) {
            tagBookTemplates.set(tag, new Set<BookTemplate>([bookTemplate]));
          } else {
            tagBookTemplates.get(tag).add(bookTemplate);
          }
        }
      }
    }

    // const state = getState();
    setState({ tagBookTemplates });
  }
}
