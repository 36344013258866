import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  SimpleChanges,
  OnChanges,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import {
  ScanningOptions,
  ScanMode,
  BookDefinitionApiService
} from '@sonorus/api';

import { BorderSize } from '@sonorus/core';
import { AdminScanningModalComponent } from '../admin-scanning-modal/admin-scanning-modal.component';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

interface ScanningOptionsFormValue {
  enabled: boolean;
  audio: boolean;
  speed: number;
  scanMode: ScanMode;
  rowBorderSize: BorderSize;
  rowBorderColor: string;
  widgetBorderSize: BorderSize;
  widgetBorderColor: string;
}

@Component({
  selector: 'sonorus-admin-scanning',
  templateUrl: './admin-scanning.component.html',
  styleUrls: ['./admin-scanning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminScanningComponent implements OnChanges, OnInit, OnDestroy {
  @Input()
  bookId: string;

  @Input()
  scanning: ScanningOptions;

  scanningFormGroup: FormGroup;

  get scanningEnabled() {
    return (
      this.scanningFormGroup &&
      (<ScanningOptionsFormValue>this.scanningFormGroup.value).enabled
    );
  }

  private subscription: Subscription;
  private showSettingsOnFirstTime = true;

  constructor(
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private bookDefinitionApi: BookDefinitionApiService,
    private modalCtrl: ModalController
  ) {
    this.scanningFormGroup = this.fb.group(<ScanningOptionsFormValue>{
      enabled: false
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.scanning && this.scanning) {
      this.scanningFormGroup.patchValue(
        <ScanningOptionsFormValue>{
          enabled: this.scanning.enabled
        },
        {
          emitEvent: false
        }
      );

      this.changeDetector.markForCheck();
    }
  }

  ngOnInit() {
    this.subscription = this.scanningFormGroup.valueChanges
      .pipe(
        filter(
          formValue => formValue !== undefined && this.scanning !== undefined
        )
      )
      .subscribe(formValue => {
        this.scanning = new ScanningOptions({
          ...this.scanning,
          enabled: formValue.enabled
        });
        this.save(this.scanning);
      });

    this.subscription.add(
      this.scanningFormGroup.get('enabled').valueChanges.subscribe(value => {
        if (value && this.showSettingsOnFirstTime) {
          this.openSettings();
          this.showSettingsOnFirstTime = false;
        }
      })
    );
  }

  ngOnDestroy() {
    if (!this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  public async openSettings() {
    const modal = await this.modalCtrl.create({
      component: AdminScanningModalComponent,
      componentProps: {
        scanning: this.scanning
      }
      // cssClass: 'big-modal'
    });

    await modal.present();

    const result = await modal.onDidDismiss();

    if (result.data) {
      const modalValue: ScanningOptionsFormValue = result.data;

      const formValue = this.scanningFormGroup.value;

      this.scanning = new ScanningOptions({
        enabled: formValue.enabled,
        speed: modalValue.speed,
        audio: modalValue.audio,
        rowBorderColor: modalValue.rowBorderColor,
        rowBorderSize: modalValue.rowBorderSize,
        widgetBorderColor: modalValue.widgetBorderColor,
        widgetBorderSize: modalValue.widgetBorderSize,
        mode: modalValue.scanMode
      });

      await this.save(this.scanning);
    }
  }

  private async save(scanningOptions: ScanningOptions) {
    await this.bookDefinitionApi
      .saveScanningOptions(this.bookId, scanningOptions)
      .toPromise();
  }
}
