import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef
} from '@angular/core';
import {
  WidgetWithAssets,
  QuickChatDefinition,
  WidgetApiService,
  BookDefinitionApiService,
  Command2,
  WidgetDefinition,
  BookApiService,
  QuickChatWithAssets,
  IBookStyleDefinition,
  IWidgetWithAssets,
  Command6
} from '@sonorus/api';
import { FormGroup, FormBuilder } from '@angular/forms';

import * as _ from 'lodash';
import { ModalController, AlertController } from '@ionic/angular';
import { LoaderService } from '@sonorus/core';
import {
  AdminWidgetEditPage,
  WidgetEditorOptions
} from '../admin-widget-edit/admin-widget-edit';
import { TranslateService } from '@ngx-translate/core';
import { FontFamily, mapFontFamilyToCssClass } from '@sonorus/features';
import { GridsterItem } from 'angular-gridster2';

@Component({
  selector: 'sonorus-admin-quickchat',
  templateUrl: './admin-quickchat.component.html',
  styleUrls: ['./admin-quickchat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminQuickchatComponent implements OnInit, OnChanges {
  @Input()
  bookId: string;
  @Input()
  styleDefinition: IBookStyleDefinition;
  @Input()
  quickChat: QuickChatDefinition;

  items: WidgetWithAssets[];
  fontCssClass: string;
  quickChatForm: FormGroup;

  get quickChatEnabled() {
    return this.quickChatForm.get('enabled').value;
  }

  get menuRight() {
    return this.quickChatForm.get('opensRightSide').value;
  }

  private dirty: boolean;

  constructor(
    private fb: FormBuilder,
    private bookApi: BookApiService,
    private bookDefinitionApi: BookDefinitionApiService,
    private translateService: TranslateService,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private widgetApi: WidgetApiService,
    private loader: LoaderService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.quickChatForm = this.fb.group({
      enabled: false,
      opensRightSide: false
    });

    this.quickChatForm.valueChanges.subscribe(() => this.markAsDirty());

    if (this.styleDefinition) {
      this.fontCssClass = mapFontFamilyToCssClass(<FontFamily> this.styleDefinition.fontFamily)
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.quickChat && this.quickChat && this.quickChat.widgets) {
      // TODO: comment this line after save works again
      // this.items = _.cloneDeep(this.quickChat.widgets);

      if (this.quickChat) {
        this.quickChatForm.patchValue(
          {
            enabled: this.quickChat.enabled || false,
            opensRightSide: this.quickChat.opensRightSide
          },
          {
            emitEvent: false
          }
        );
      }
    }

    if (changes.bookId) {
      if (this.bookId) {
        this.bookApi
          .getQuickChatReadOnlyUrl(this.bookId)
          .toPromise()
          .then(async url => {
            const response = await fetch(url, {
              cache: 'no-cache'
            });
            const quickChatWithAssets: QuickChatWithAssets = await response.json();
            this.items = quickChatWithAssets.widgetsWithAssets;
            this.changeDetector.markForCheck();
          })
          .catch(err => {
            console.error('Error downloading quickchat with assets.', err);
            this.changeDetector.markForCheck();
          });
      }
    }
  }

  onReorder($event) {
    // console.log(
    //   'Dragged from index',
    //   $event.detail.from,
    //   'to',
    //   $event.detail.to
    // );

    this.items = $event.detail.complete(this.items);

    this.markAsDirty();
  }

  toGridsterItem(widget: IWidgetWithAssets) {
    return <GridsterItem> <unknown> widget;
  }

  async onAddWidget() {
    const widgetDTO = new WidgetWithAssets();

    const createdWidget = await this.widgetApi
      .createQuickChatWidget(
        this.bookId,
        new Command2({
          bookId: this.bookId,
          widget: widgetDTO
        })
      )
      .toPromise();

    const result = await this.openQuickChatWidgetModal(createdWidget);
    if (!result) {
      return;
    }

    // add widget to quickchat definition
    if (!this.items) {
      this.items = [];
    }
    this.items.push(result);

    // mark as dirty
    this.markAsDirty();
  }

  async onEditWidget(widgetDTO: WidgetWithAssets) {
    const updatedWidget = await this.openQuickChatWidgetModal(widgetDTO);

    if (!updatedWidget) {
      return;
    }

    // update updated widget in collection
    const updatedIndex = this.items.findIndex(w => w.id === updatedWidget.id);
    this.items[updatedIndex] = updatedWidget;

    // mark as dirty
    this.markAsDirty();
  }

  async onDeleteWidget(widgetToDelete: WidgetWithAssets) {
    const translations = await this.translateService
      .get([
        'WIDGET_DELETE_TITLE',
        'WIDGET_DELETE_AREYOUSURE',
        'WIDGET_CANCEL',
        'WIDGET_DELETE'
      ])
      .toPromise();

    const alert = await this.alertCtrl.create({
      header: translations['WIDGET_DELETE_TITLE'],
      message: translations['WIDGET_DELETE_AREYOUSURE'],
      buttons: [
        {
          text: translations['WIDGET_CANCEL'],
          role: 'cancel',
          handler: () => {
            // leave widget be
          }
        },
        {
          text: translations['WIDGET_DELETE'],
          handler: async () => {
            // remove widget

            const indexToDelete = this.items.findIndex(
              widget => widgetToDelete.id === widget.id
            );
            this.items.splice(indexToDelete, 1);
            if (widgetToDelete.pictureUrl) {
              await this.widgetApi
                .deleteWidgetPicture(
                  this.bookId,
                  new Command6({
                    pictureUrl: widgetToDelete.pictureUrl
                  })
                )
                .toPromise();
              this.markAsDirty();
            } else {
              this.markAsDirty();
            }
          }
        }
      ]
    });

    await alert.present();
  }

  // TODO: when to call save??

  private async saveQuickChat() {
    await this.loader.show();

    // set other properties on quickchat
    const formValue = this.quickChatForm.value as {
      enabled: boolean;
      opensRightSide: boolean;
    };

    if (!this.quickChat) {
      this.quickChat = new QuickChatDefinition();
    } else {
      this.quickChat = _.cloneDeep(this.quickChat);
    }

    this.quickChat.enabled = formValue.enabled;
    this.quickChat.opensRightSide = formValue.opensRightSide;

    // copy collection to quickchat
    this.quickChat.widgets = this.items || [];

    const quickChatDefinition: QuickChatDefinition = _.cloneDeep(
      this.quickChat
    );
    quickChatDefinition.widgets = this.quickChat.widgets.map(
      w => new WidgetDefinition(w)
    );

    // save
    const updatedQuickChat = await this.bookDefinitionApi
      .saveQuickChat(this.bookId, quickChatDefinition)
      .toPromise();

    await this.loader.hide();

    this.markAsClean();
  }

  private async openQuickChatWidgetModal(createdWidget: WidgetWithAssets) {
    await this.loader.show();

    const modalData: WidgetEditorOptions = {
      bookId: this.bookId,
      widgetData: createdWidget,
      quickChatMode: true
    };

    const modal = await this.modalCtrl.create({
      component: AdminWidgetEditPage,
      componentProps: modalData,
      backdropDismiss: false,
      cssClass: 'full-screen-modal' // 'big-modal'
    });

    await modal.present();

    await this.loader.hide();

    const result = await modal.onDidDismiss();

    if (result.data) {
      return result.data;
    }

    return undefined;
  }

  isDirty() {
    return this.dirty;
  }

  private markAsDirty() {
    this.dirty = true;
    this.saveQuickChat();
    this.changeDetector.markForCheck();
  }

  private markAsClean() {
    this.dirty = false;
  }
}
