import { BookDefinition, BookStyleDefinition, PageDefinition } from '@sonorus/api';

export namespace BookDefinitions {
  export class Delete {
    static readonly type = '[Book Editor] delete book and assets';
    constructor(public bookId: string) { }
  }
  export class Load {
    static readonly type = '[Book Editor] load book and page definitions';
    constructor(public bookId: string) { }
  }

  export class EditBookTitle {
    static readonly type = '[Book Editor] edit book title';
    constructor(public bookId: string, public title: string) { }
  }

  export class EditBookStyle {
    static readonly type = '[Book Editor] edit book style';
    constructor(public bookId: string, public style: BookStyleDefinition) { }
  }

  export class EditBookSettings {
    static readonly type = '[Book Editor] edit book settings';
    constructor(public bookId: string, public bookDefinition: BookDefinition) { }
  }

  export class SetStarterPage {
    static readonly type = '[Book Editor] set starter page';
    constructor(public bookId: string, public pageId: string) { }
  }

  export class ChangePageOrder {
    static readonly type = '[Book Editor] change page order';
    constructor(public bookId: string, public pageIds: string[]) { }
  }

  export class AddPage {
    static readonly type = '[Book Editor] add page';
    constructor(
      public bookId: string,
      public pageDefinition: PageDefinition,
      public startPage: boolean
    ) { }
  }

  export class DeletePage {
    static readonly type = '[Book Editor] remove page';
    constructor(public bookId: string, public pageId: string) { }
  }

  export class EditPageTitle {
    static readonly type = '[Book Editor] edit page title';
    constructor(
      public bookId: string,
      public pageId: string,
      public title: string
    ) { }
  }

  export class Clear {
    static readonly type = '[Book Editor] clear book and page definitions';
    constructor(public bookId: string) { }
  }
}
