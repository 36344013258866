import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { TokenInterceptor } from './token.interceptor';
import {
  SystemPictosetApiService,
  UserApiService,
  BookApiService,
  TTSApiService,
  // PaymentMethodApiService,
  UserBookApiService
  // PaymentHistoryApiService
} from './services/sonorus-api-client.generated';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  imports: [CommonModule],
  declarations: []
  // providers: [
  //   PaymentHistoryApiService,
  //   BookApiService,
  //   PictosetApiService,
  //   TTSApiService,
  //   UserApiService,
  //   UserBookApiService,
  //   PaymentMethodApiService
  // ]
  // exports:[BookDefinition, ]
})
export class ApiModule {
  static forRoot(): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        // PaymentHistoryApiService,
        BookApiService,
        SystemPictosetApiService,
        TTSApiService,
        UserApiService,
        UserBookApiService,
        // PaymentMethodApiService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptor,
          multi: true
        }
      ]
    };
  }
}
