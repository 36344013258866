import { Injectable } from '@angular/core';
// import { Media } from '@ionic-native/media/ngx';
import { Howl } from 'howler';
// import { Platform } from '@ionic/angular';
import { AssetManagerProvider } from '../asset-manager/asset-manager';
// import { NativeAudio } from '@ionic-native/native-audio/ngx';
// how to use:
// https://www.joshmorony.com/sound-effects-using-html5-and-native-audio-in-ionic/
// simplepreload for small sounds
// complexpreload for longer files like songs

// todo:
// execute when connected to wifi

@Injectable({
  providedIn: 'root'
})
export class SmartAudioProvider {
  private audioType = 'html5';
  private sounds: { [key: string]: any } = {};

  // private html5AudioContext: AudioContext;

  constructor(
    // public nativeAudio: NativeAudio,
    // private platform: Platform,
    // private media: Media,
    private assetManager: AssetManagerProvider
  ) {
    // if (this.platform.is('cordova')) {
    //   this.audioType = 'native';
    // }
    /*else {
      const audioContextConstructor =
        window['AudioContext'] || window['webkitAudioContext'];
      this.html5AudioContext = new audioContextConstructor();
    }*/
  }

  preload(key, base64Asset) {
    let audio;
    if (this.audioType === 'html5' && !this.sounds[key]) {
      console.log('preload audio key ' + key)
      audio = {
        key: key,
        asset: base64Asset,
        type: 'html5',
        // mediaObject: new Audio(base64Asset)
        mediaObject: new Howl({ src: [base64Asset] })
      };

      this.sounds[key] = audio;
    }
    // else {
    //   const assetBlob = this.assetManager.dataURLtoBlob(base64Asset);
    //   this.assetManager
    //     .storeMediaFile(key, 'mp3', assetBlob)
    //     .then(filePathOrDataUri => {
    //       audio = {
    //         key: key,
    //         asset: filePathOrDataUri,
    //         type: 'native',
    //         mediaObject: this.media.create(filePathOrDataUri)
    //       };
    //       this.sounds[key] = audio;
    //     });
    // }
  }

  unload(key) {
    // console.log('DEV: SmartAudioProvider -> unload -> key', key);
    if (this.sounds && this.sounds[key]) {
      // this.assetManager.removeMediaFile(this.sounds[key].asset).then(() => {
      // clean up howl object
      if (
        this.sounds[key] &&
        this.sounds[key].mediaObject &&
        this.sounds[key].mediaObject.unload
      ) {
        this.sounds[key].mediaObject.unload();
        console.log(`${key} - unloaded, ${Object.keys(this.sounds).length} remaining`);
      } else {
        console.log('WEIRD - unload did not happen for key ' + key);
      }
      delete this.sounds[key];
      // this.sounds[key] = undefined;
      // });
    } else {
      console.log('WEIRD - key not found')
    }
  }

  play(key) {
    const audio = this.sounds[key];
    if (!audio) {
      console.warn('No sound registered for key!', key);
      return;
    }

    // console.log('PLAY AUDIO', audio);

    if (audio.type === 'html5') {
      (audio.mediaObject as Howl).play();
      return audio.mediaObject;
    }
    //  else {
    //   const audioAsset = audio.mediaObject;
    //   console.log('MEDIA OBJECT', audioAsset);

    //   audioAsset.onStatusUpdate.subscribe(status => console.log(status));
    //   audioAsset.onSuccess.subscribe(() => console.log('Action is successful'));
    //   audioAsset.onError.subscribe(error => console.log('Error!', error));
    //   audioAsset.setVolume(1.0);
    //   audioAsset.play();
    // }
  }

  constructBookAudioKey(itemId: string) {
    return `${itemId}`;
  }
}
