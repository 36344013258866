import { GridsterItem } from 'angular-gridster2';
import {
  SpeechMediaFile,
  WidgetWithAssets,
  WidgetDefinition,
  IWidgetWithAssets
} from '@sonorus/api';
// import { WidgetActionType } from './widget-action-type';

export interface ButtonWidget extends IWidgetWithAssets {
  // id: string;
  // cols: number;
  // rows: number;
  // x: number;
  // y: number;
  // displayText: string;
  // pictureBase64: string;
  // pictureUrl: string;
  // widgetActionType: WidgetActionType;
  // youtubeVideoId: string;
  // youtubeVideoThumbnailUrl: string;
  // mediaFile: SpeechMediaFile;
  // goToPageId: string;
  mediaFile: SpeechMediaFile;
}

export declare type FontFamily = 'Roboto' | 'CenturyGothic' | 'Courgette' | 'SourceCodePro';

export const mapFontFamilyToCssClass = (fontFamily: FontFamily) => {
  switch (fontFamily) {
    case 'CenturyGothic':
      return 'century-gothic-font';
    case 'Courgette':
      return 'courgette-font';
    case 'SourceCodePro':
      return 'source-code-pro-font';
    case 'Roboto':
    default:
      return '';
  }
}

export const mapWidgetToModel = (w: WidgetWithAssets): ButtonWidget => {
  return {
    ...w,
    mediaFile: new SpeechMediaFile(w.speechMediaFile)
  };
};

export const mapModelToWidgetDefinition = (
  m: ButtonWidget
): WidgetDefinition => {
  return new WidgetDefinition({
    ...m
  });
};

export const mapModelToWidget = (m: ButtonWidget) => {
  return new WidgetWithAssets({
    ...m,
    speechMediaFile: new SpeechMediaFile(m.mediaFile)
  });
};

export const copyModelToGridsterItem = (
  data: ButtonWidget,
  item: GridsterItem
): GridsterItem => {
  item = {
    ...item,
    ...data,
    speechMediaFile: new SpeechMediaFile(data.mediaFile)
  };
  // item.id = data.id;
  // item.cols = data.cols;
  // item.rows = data.rows;
  // item.x = data.x;
  // item.y = data.y;
  // item.displayText = data.displayText;
  // // item.
  // item.pictureUrl = data.pictureUrl;
  // item.pictureBase64 = data.pictureBase64;
  // item.pictureRevision = data.pictureRevision;
  // item.widgetActionType = data.widgetActionType;
  // item.youtubeVideoId = data.youtubeVideoId;
  // item.youtubeVideoThumbnailUrl = data.youtubeVideoThumbnailUrl;
  // item.goToPageId = data.goToPageId;
  // // item.speechUrl = data.speechUrl;
  // item.speechMediaFile = new SpeechMediaFile(data.mediaFile);

  // item.borderSize = data.borderSize;
  // item.borderColor = data.borderColor;
  // item.backgroundColor = data.backgroundColor;
  // item.textSize = data.textSize;
  // item.textColor = data.textColor;
  // item.textAlign = data.textAlign;

  return item;
};

export const copyGridsterItemToWidgetReadOnly = (
  data: GridsterItem,
  item: WidgetWithAssets
): WidgetWithAssets => {
  item.id = data.id;
  item.cols = data.cols;
  item.rows = data.rows;
  item.x = data.x;
  item.y = data.y;
  item.displayText = data.displayText;
  item.displayTextHidden = data.displayTextHidden;
  item.pictureUrl = data.pictureUrl;
  item.pictureBase64 = data.pictureBase64;
  item.pictureRevision = data.pictureRevision;
  item.widgetActionType = data.widgetActionType;
  item.youtubeVideoId = data.youtubeVideoId;
  item.youtubeVideoThumbnailUrl = data.youtubeVideoThumbnailUrl;
  item.goToPageId = data.goToPageId;
  item.speechMediaFile = new SpeechMediaFile(data.mediaFile);

  item.borderSize = data.borderSize;
  item.borderColor = data.borderColor;
  item.backgroundColor = data.backgroundColor;
  item.textSize = data.textSize;
  item.textColor = data.textColor;
  item.textAlign = data.textAlign;

  return item;
};
