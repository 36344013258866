import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { GridsterModule } from 'angular-gridster2';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from "@ngxs/store";

import { ApiModule } from '@sonorus/api';
import { SonorusAudioModule } from '@sonorus/audio';
import { UISharedModule } from '@sonorus/features';

import { BookEditorRoutingModule } from './book-editor-routing.module';
import { AdminBookVoicePage } from './admin-book-voice/admin-book-voice';
import { AdminPageEditPage } from './admin-page-edit/admin-page-edit';

import { AdminBookPagesPage } from './admin-book-pages/admin-book-pages';
import { AddPageDialogComponent } from './admin-book-pages/components/add-page-dialog/add-page-dialog.component';
import { DuplicatePageDialogComponent } from './admin-book-pages/components/duplicate-page-dialog/duplicate-page-dialog.component';
import { FontPickerComponent } from './admin-book-pages/components/font-picker/font-picker.component';

import { AdminPageSettingsPage } from './admin-page-settings/admin-page-settings';
import { AdminWidgetEditPage } from './admin-widget-edit/admin-widget-edit';
import { AdminWidgetEditGiphySearchPage } from './admin-widget-edit-giphy-search/admin-widget-edit-giphy-search';
import { AdminWidgetEditImageSearchPage } from './admin-widget-edit-image-search/admin-widget-edit-image-search';
import { AdminWidgetEditPageSearchPage } from './admin-widget-edit-page-search/admin-widget-edit-page-search';
import { AdminWidgetEditYoutubeSearchPage } from './admin-widget-edit-youtube-search/admin-widget-edit-youtube-search';
import { Html5CameraPage } from './html5-camera/html5-camera';
import { PictosetPickerComponent } from './pictoset-picker/pictoset-picker';

import { PicturePickerComponent } from './picture-picker/picture-picker';
import { SmartCameraProvider } from './services/smart-camera/smart-camera';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { AdminQuickchatComponent } from './admin-quickchat/admin-quickchat.component';
import { AdminScanningComponent } from './admin-scanning/admin-scanning.component';
import { AdminScanningModalComponent } from './admin-scanning-modal/admin-scanning-modal.component';
import { AdminQuickchatModalComponent } from './admin-quickchat-modal/admin-quickchat-modal.component';
import { LogbookViewerComponent } from './logbook-viewer/logbook-viewer.component';
import { LogbookEditorComponent } from './logbook-editor/logbook-editor.component';
import { LogbookEntryModalComponent } from './logbook-editor/entry-modal/entry-modal.component';
import { LogbookEntryRowComponent } from './logbook-editor/logbook-entry-row/logbook-entry-row.component';
import { ShareContentModalComponent } from './share-content-modal/share-content-modal.component';
import { PreviousNextComponent } from './previous-next/previous-next.component';
import { ReorderPagesModalComponent } from './admin-book-pages/components/reorder-pages-modal/reorder-pages-modal.component';
import { AdminWidgetEditAudioComponent } from './admin-widget-edit-audio/admin-widget-edit-audio.component';
import {WidgetStyleState} from "./state/widget-style.state";

const CORE_PROVIDERS = [SmartCameraProvider];

const DEPS_MODULES = [
  TranslateModule.forChild(),
  GridsterModule,
  NgxsModule.forFeature([WidgetStyleState])
];

@NgModule({
  declarations: [
    AdminBookVoicePage,
    AdminBookPagesPage,
    AdminPageEditPage,
    AdminPageSettingsPage,
    AdminWidgetEditPage,
    AdminWidgetEditGiphySearchPage,
    AdminWidgetEditImageSearchPage,
    AdminWidgetEditPageSearchPage,
    AdminWidgetEditYoutubeSearchPage,
    Html5CameraPage,
    PictosetPickerComponent,
    PicturePickerComponent,
    AddPageDialogComponent,
    ColorPickerComponent,
    AdminQuickchatComponent,
    AdminScanningComponent,
    AdminScanningModalComponent,
    AdminQuickchatModalComponent,
    LogbookViewerComponent,
    LogbookEditorComponent,
    LogbookEntryModalComponent,
    LogbookEntryRowComponent,
    ShareContentModalComponent,
    DuplicatePageDialogComponent,
    PreviousNextComponent,
    FontPickerComponent,
    ReorderPagesModalComponent,
    AdminWidgetEditAudioComponent
  ],
  imports: [
    MatExpansionModule,
    DragDropModule,
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    ...DEPS_MODULES,
    UISharedModule,
    ApiModule,
    SonorusAudioModule,
    BookEditorRoutingModule
  ],
  providers: [...CORE_PROVIDERS],
  entryComponents: [
    AdminBookVoicePage,
    AddPageDialogComponent,
    AdminPageSettingsPage,
    AdminWidgetEditPage,
    AdminWidgetEditImageSearchPage,
    AdminWidgetEditGiphySearchPage,
    ColorPickerComponent,
    DuplicatePageDialogComponent,
    PictosetPickerComponent,
    AdminWidgetEditPageSearchPage,
    Html5CameraPage,
    AdminWidgetEditYoutubeSearchPage,
    AdminScanningModalComponent,
    AdminQuickchatModalComponent,
    LogbookEntryModalComponent,
    ShareContentModalComponent,
    FontPickerComponent
  ]
})
export class BookEditorModule { }
