import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LogbookEntry } from '@sonorus/api';
import { DeviceDetectorService } from '@sonorus/core';

@Component({
  selector: 'sonorus-logbook-entry-row',
  templateUrl: './logbook-entry-row.component.html',
  styleUrls: ['./logbook-entry-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogbookEntryRowComponent implements OnInit {
  @Input()
  entry: LogbookEntry;
  @Input()
  readonly: boolean;

  @Output()
  edit = new EventEmitter();
  @Output()
  delete = new EventEmitter();
  @Output()
  downloadPdf = new EventEmitter();
  @Output()
  mail = new EventEmitter();

  public usedInBrowser: boolean;

  constructor(
    private sanitizer: DomSanitizer,
    private deviceDetector: DeviceDetectorService,
    private translate: TranslateService,
    private alertCtrl: AlertController
  ) {
    this.usedInBrowser = this.deviceDetector.isWeb();
  }

  ngOnInit() { }

  onEditClick() {
    this.edit.emit();
  }

  async onDeleteClick() {
    const translations = await this.translate
      .get([
        'BOOKS.ADMIN.LOGBOOK.CONFIRMDELETE',
        'NO_BUTTON_TEXT',
        'BOOKS.ADMIN.LOGBOOK.DELETE'
      ])
      .toPromise();

    const alerthandle = await this.alertCtrl.create({
      header: '',
      message: translations['BOOKS.ADMIN.LOGBOOK.CONFIRMDELETE'],
      buttons: [
        {
          text: translations['NO_BUTTON_TEXT'],
          role: 'cancel',
          handler: () => { }
        },
        {
          text: translations['BOOKS.ADMIN.LOGBOOK.DELETE'],
          handler: () => this.delete.emit()
        }
      ]
    });

    alerthandle.present();
  }

  replaceNewlineWithBreaks(content: string) {
    if (!content) {
      return;
    }

    return this.sanitizer.bypassSecurityTrustHtml(
      content.replace(/\n/g, '<br />')
    );
  }
}
