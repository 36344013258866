<!--
  Generated template for the AdminEditBookPage page.

  See http://ionicframework.com/docs/components/#navigation for more info on
  Ionic pages and navigation.
-->
<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'BOOK_EDIT_VOICE' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <form [formGroup]="bookForm">
    <ion-list padding>
      <!-- <ion-list-header *ngIf="isNew">
        <ion-label>{{ 'BOOK_EDIT_ADD_TITLE' | translate }}</ion-label>
      </ion-list-header>
      <ion-list-header *ngIf="!isNew">
        <ion-label>{{ 'BOOK_EDIT_EDIT_TITLE' | translate }}</ion-label>
      </ion-list-header>
      <ion-item>
        <ion-label position="floating">{{ 'BOOK_EDIT_TITLE' | translate }}</ion-label>
        <ion-input type="text"
                   formControlName="title"></ion-input>
      </ion-item> -->
      <ion-item *ngIf="voiceSettings">
        <ion-label position="floating">{{ 'BOOK_EDIT_VOICE' | translate }}</ion-label>
        <ion-select formControlName="bookVoiceSetting">
          <ion-select-option *ngFor="let voiceSetting of voiceSettings"
                             [value]="constructVoiceSettingsId(voiceSetting)">
            {{ voiceSetting.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item>
        <ion-label>{{ 'BOOK_EDIT_VOICE_RATE' | translate }}</ion-label>
        <ion-range min="-100"
                   max="100"
                   step="5"
                   formControlName="bookVoiceRate">
          <ion-icon slot="start"
                    size="small"
                    name="speedometer"></ion-icon>
          <ion-icon slot="end"
                    name="speedometer"></ion-icon>
        </ion-range>
      </ion-item>

      <!-- SLIDER - https://ionicframework.com/docs/components/#range -->
      <ion-item>
        <ion-label>{{ 'BOOK_EDIT_VOICE_PITCH' | translate }}</ion-label>
        <ion-range min="-100"
                   max="100"
                   step="5"
                   formControlName="bookVoicePitch">
          <ion-icon slot="start"
                    size="small"
                    name="arrow-down"></ion-icon>
          <ion-icon slot="end"
                    name="arrow-up"></ion-icon>
        </ion-range>
      </ion-item>

      <ion-item>
        <ion-label position="stacked">{{ 'BOOK_EDIT_TEST_VOICE' | translate }}</ion-label>
        <ion-textarea rows="6"
                      placeholder="{{'BOOK_EDIT_VOICE_PITCH_PLACEHOLDER' | translate}}"
                      formControlName="testSentence">
        </ion-textarea>

        <ion-button slot="end"
                    (click)="testVoice()"
                    type="button">
          <ion-icon name="megaphone"></ion-icon>
        </ion-button>
      </ion-item>
    </ion-list>
    <!--
      <ion-buttons slot="end">
        <ion-button
          color="primary"
          (click)="saveBook()"
          type="submit"
          fill="solid"
          >{{ 'BOOK_EDIT_SAVE' | translate }}</ion-button
        >
        <ion-button
          color="danger"
          (click)="cancel()"
          type="button"
          fill="solid"
          >{{ 'BOOK_EDIT_CANCEL' | translate }}</ion-button
        >
      </ion-buttons>
    -->
  </form>

  <!-- JSON FORM {{bookForm.value | json}} -->
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="primary">
      <ion-button color="primary"
                  [disabled]="!bookForm.valid"
                  (click)="saveBook()"
                  type="submit"
                  fill="solid">{{ 'BOOK_EDIT_SAVE' | translate }}</ion-button>
      <ion-button color="danger"
                  (click)="cancel()"
                  type="button"
                  fill="solid">{{ 'BOOK_EDIT_CANCEL' | translate }}</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
