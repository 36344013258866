import { State, StateContext, Action, Selector } from '@ngxs/store';
import * as _ from 'lodash';
import { tap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  LoadUserById,
  LoadUserByAuth0Id,
  ChangeCulture,
  SaveUser,
  // CancelSubscription,
  // LoadSubscriptionStatusFromStripe,
  // SetUserActionRequiredResolved,
  // RenewSubscription,
  ClearUserState
} from './user.actions';
import {
  UserApiService,
  IUserDTO,
  UserDTO
  // SubscriptionApiService,
  // SubscriptionStatus
} from '@sonorus/api';
// import { ClearDefaultPaymentMethod } from './payment-methods.actions';
import { Injectable } from '@angular/core';

// tslint:disable-next-line:no-empty-interface
export interface UserStateModel extends IUserDTO { }

@State<UserStateModel>({
  name: 'user',
  defaults: {
    id: undefined,
    firstName: undefined,
    lastName: undefined,
    culture: undefined,
    email: undefined,
    mobile: undefined,
    billingAddress: undefined,
    subscription: undefined,
    role: undefined
  }
})
@Injectable()
export class UserState {
  @Selector()
  static culture(state: UserStateModel) {
    return state.culture;
  }

  @Selector()
  static language(state: UserStateModel) {
    return state && state.culture ? state.culture.split('-')[0] : undefined;
  }

  @Selector()
  static subscriptionLevel(state: UserStateModel) {
    if (!state.subscription) {
      return undefined;
    }

    return state.subscription.level;
  }

  @Selector()
  static subscriptionStatus(state: UserStateModel) {
    if (!state.subscription) {
      return undefined;
    }

    return state.subscription.status;
  }

  constructor(
    private userApi: UserApiService
    // private subscriptionApi: SubscriptionApiService
  ) { }

  @Action(ClearUserState)
  clearUserState(ctx: StateContext<UserStateModel>, action: ClearUserState) {
    ctx.setState({});
  }

  @Action(LoadUserById)
  loadUser(ctx: StateContext<UserStateModel>, action: LoadUserById) {
    return this.userApi.get(action.userId).pipe(
      tap(user => {
        ctx.setState({
          ...user
        });
      })
    );
  }

  @Action(LoadUserByAuth0Id)
  loadUserByAuth0Id(
    ctx: StateContext<UserStateModel>,
    action: LoadUserByAuth0Id
  ) {
    return this.userApi.getByAuth0Id(action.auth0Id).pipe(
      tap(user => {
        ctx.setState({
          ...user
        });
      })
    );
  }

  @Action(SaveUser)
  saveUser(ctx: StateContext<UserStateModel>, action: SaveUser) {
    const state = ctx.getState();

    return of(action).pipe(
      switchMap(saveUserAction => {
        if (saveUserAction.userId && saveUserAction.auth0Id) {
          return this.userApi.put(
            saveUserAction.userId,
            new UserDTO(saveUserAction.user)
          );
        } else {
          return this.userApi.post(
            saveUserAction.auth0Id,
            new UserDTO(saveUserAction.user)
          );
        }
      }),
      tap(user =>
        ctx.setState({
          ...state,
          ...user
        })
      )
    );
  }

  @Action(ChangeCulture)
  changeCulture(ctx: StateContext<UserStateModel>, action: ChangeCulture) {
    const state = ctx.getState();

    ctx.setState({ ...state, culture: action.culture });

    if (action.userId) {
      return this.userApi.get(action.userId).pipe(
        switchMap(user => {
          user.culture = action.culture;
          return this.userApi.put(user.id, user);
        })
      );
    }
  }

  // @Action(CancelSubscription)
  // cancelSubscription(
  //   ctx: StateContext<UserStateModel>,
  //   action: CancelSubscription
  // ) {
  //   const state = ctx.getState();

  //   this.subscriptionApi.cancelSubscription(action.userId).subscribe(() => {
  //     const newState = _.cloneDeep(state) as UserStateModel;
  //     newState.subscription.status = SubscriptionStatus.Cancelled;

  //     ctx.setState({
  //       ...newState
  //     });

  //     ctx.dispatch(new ClearDefaultPaymentMethod());
  //   });
  // }

  // @Action(RenewSubscription)
  // renewSubscription(
  //   ctx: StateContext<UserStateModel>,
  //   action: CancelSubscription
  // ) {
  //   const state = ctx.getState();

  //   const newState = _.cloneDeep(state);
  //   newState.subscription.status = undefined;

  //   ctx.setState({
  //     ...newState
  //   });
  // }

  // @Action(LoadSubscriptionStatusFromStripe)
  // loadSubscriptionStatusFromStripe(
  //   ctx: StateContext<UserStateModel>,
  //   action: LoadSubscriptionStatusFromStripe
  // ) {
  //   const state = ctx.getState();

  //   return this.subscriptionApi.getSubscriptionStatus(action.userId).pipe(
  //     tap(stripeSubscriptionStatus => {
  //       const newState = _.cloneDeep(state) as UserStateModel;

  //       newState.subscription.status = stripeSubscriptionStatus.status;
  //       newState.subscription.level = stripeSubscriptionStatus.level;
  //       newState.subscription.paymentIntentClientSecret =
  //         stripeSubscriptionStatus.paymentIntentClientSecret;

  //       ctx.setState({
  //         ...newState
  //       });
  //     })
  //   );
  // }

  // @Action(SetUserActionRequiredResolved)
  // setUserActionRequiredResolved(
  //   ctx: StateContext<UserStateModel>,
  //   action: SetUserActionRequiredResolved
  // ) {
  //   const state = ctx.getState();

  //   return this.subscriptionApi.resolveActionRequired(action.userId).pipe(
  //     tap(result => {
  //       const newState = _.cloneDeep(state) as UserStateModel;

  //       newState.subscription.status = result.status;
  //       newState.subscription.level = result.level;

  //       ctx.setState({
  //         ...newState
  //       });
  //     })
  //   );
  // }
}
