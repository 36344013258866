import 'capacitor-secure-storage-plugin';
import { Plugins } from '@capacitor/core';
import { Injectable } from '@angular/core';
import { StorageBackend } from '@openid/appauth';

const { SecureStoragePlugin } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class AuthSecureStorageWrapper extends StorageBackend {
  async getItem(key: string): Promise<string> {
    const item = await SecureStoragePlugin.get({ key });

    console.log('AuthSecureStorageWrapper - getItem', item);

    if (item.value === '' || item.value === 'ée') {
      item.value = undefined;
    }

    return item.value;
  }

  setItem(key: string, value: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      await SecureStoragePlugin.set({ key, value });

      resolve();
    });
  }

  removeItem(key: string): Promise<void> {
    return SecureStoragePlugin.remove({ key }).then(success => {
      if (!success) {
        throw new Error('Error removing ' + key + ' from secure storage');
      }
    });
  }

  clear() {
    return new Promise<void>(async (resolve, reject) => {
      await SecureStoragePlugin.clear();

      resolve();
    });
  }
}
