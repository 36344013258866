export namespace OfflineBooks {
  export class Load {
    static readonly type = '[Book Player Overview] load offline books state';
    constructor() {}
  }

  export class MarkAsOffline {
    static readonly type = '[Book Player Overview] mark book as offline';
    constructor(public bookId: string) {}
  }

  export class Remove {
    static readonly type =
      '[Book Player Overview] remove book from offline store';
    constructor(public bookId: string) {}
  }

  export class CleanupBookStore {
    static readonly type =
      '[Book Player Overview] clean up books not marked as offline';
    constructor() {}
  }
}
