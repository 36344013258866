import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  TrackByFunction
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { Select, Store } from '@ngxs/store';
import {
  BookDefinitionsState,
  LogbookActions,
  LogbookState,
  LogbookStateModel
} from '@sonorus/state';
import { LogbookEntry } from '@sonorus/api';
import {
  LogbookEntryModalComponent,
  EntryModalProps,
  EntryModalResult
} from './entry-modal/entry-modal.component';

import * as _ from 'lodash';

@Component({
  selector: 'sonorus-logbook-editor',
  templateUrl: './logbook-editor.component.html',
  styleUrls: ['./logbook-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogbookEditorComponent implements OnInit {
  @Select(BookDefinitionsState.title)
  public bookTitle$: Observable<string>;

  @Select(LogbookState.logbook)
  public logbook$: Observable<LogbookStateModel>;

  public isInitialized: boolean;
  public isEmpty: boolean;

  public logbookEntries: LogbookEntry[];

  private bookId: string;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private modalCtrl: ModalController,
    private router: Router
  ) {
    this.isInitialized = false;
  }

  ngOnInit() {
    this.route.params.subscribe(async params => {
      this.bookId = params.bookId;

      const logbookState = this.store.selectSnapshot(LogbookState);
      if (!logbookState || !logbookState.entries) {
        await this.store.dispatch(new LogbookActions.Load(this.bookId));
        this.isInitialized = true;
      }

      this.isInitialized = true;

      this.logbook$.subscribe(logbook => {
        if (!logbook || !logbook.entries || logbook.entries.length === 0) {
          this.isEmpty = true;
          this.logbookEntries = [];
        } else {
          this.isEmpty = false;
          this.logbookEntries = logbook.entries;
        }
        this.changeDetector.markForCheck();
      });
    });
  }

  ngOnDestroy() {
    // this.store.dispatch(new LogbookActions.Clear());
  }

  trackByEntryId: TrackByFunction<LogbookEntry> = (i, item) => {
    return item.id;
  };

  async onAddClicked() {
    await this.onEditClicked();
  }

  async onEditClicked(entry?: LogbookEntry) {
    const modalProps: EntryModalProps = {
      entry: entry
    };

    const modal = await this.modalCtrl.create({
      component: LogbookEntryModalComponent,
      componentProps: { ...modalProps },
      backdropDismiss: false
    });

    modal
      .onDidDismiss()
      .then((result: OverlayEventDetail<EntryModalResult>) =>
        this.onHandleModalDismiss(result.data)
      );

    await modal.present();
  }

  onDeleteClicked(entryId: string) {
    this.store.dispatch(new LogbookActions.DeleteEntry(this.bookId, entryId));
  }

  private onHandleModalDismiss(entryModalResult: EntryModalResult) {
    if (entryModalResult && entryModalResult.entry) {
      const logbookEntry = entryModalResult.entry;

      if (logbookEntry.id) {
        this.store.dispatch(
          new LogbookActions.UpdateEntry(
            this.bookId,
            logbookEntry.id,
            new LogbookEntry(logbookEntry)
          )
        );
      } else {
        this.store.dispatch(
          new LogbookActions.AddEntry(
            this.bookId,
            new LogbookEntry(logbookEntry)
          )
        );
      }
    }
  }
}
