import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
  TrackByFunction
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';

import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

import { LogbookEntry } from '@sonorus/api';
import { DeviceDetectorService, LoaderService } from '@sonorus/core';

import {
  BookDefinitionsState,
  BookDefinitionsStateModel,
  LogbookActions,
  LogbookState,
  LogbookStateModel,
  UserState
} from '@sonorus/state';
import { ShareContentModalComponent, ShareContentModalResponse } from '../share-content-modal/share-content-modal.component';

@Component({
  selector: 'sonorus-logbook-viewer',
  templateUrl: './logbook-viewer.component.html',
  styleUrls: ['./logbook-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogbookViewerComponent implements OnDestroy, OnInit {
  @Select(BookDefinitionsState.title)
  public bookTitle$: Observable<string>;

  @Select(LogbookState.logbook)
  public logbook$: Observable<LogbookStateModel>;

  public isInitialized: boolean = false;
  public isEmpty: boolean;
  public usedInBrowser: boolean;
  public logbookEntries: LogbookEntry[];
  public bookId: string;

  private subs: Subscription;

  constructor(
    private store: Store,
    private deviceDetector: DeviceDetectorService,
    private changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute,
    private actions$: Actions,
    private modalCtrl: ModalController,
    private loader: LoaderService,
    private translate: TranslateService,
    private router: Router
  ) {
    this.isInitialized = false;

    this.usedInBrowser = this.deviceDetector.isWeb();


    this.subs = this.actions$
      .pipe(ofActionSuccessful(LogbookActions.Load))
      .subscribe(() => {
        this.isInitialized = true;
        this.changeDetector.markForCheck();
      });
  }

  ngOnInit() {
    this.route.params.subscribe(async params => {
      this.bookId = params.bookId;
      this.store.dispatch(new LogbookActions.Load(this.bookId));
    });

    this.logbook$.subscribe(logbook => {
      if (!logbook || !logbook.entries || logbook.entries.length === 0) {
        this.isEmpty = true;
        this.logbookEntries = [];
      } else {
        this.isEmpty = false;
        this.logbookEntries = logbook.entries;
      }
      this.changeDetector.markForCheck();
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.store.dispatch(new LogbookActions.Clear());
  }

  trackByEntryId: TrackByFunction<LogbookEntry> = (i, item) => {
    return item.id;
  };

  edit() {
    // go to full logbook editor,
    // from there, offer crud on all entries using modals per entry
    this.router.navigate(['edit'], {
      relativeTo: this.route
    });
  }

  async shareLogbook() {
    const modal = await this.modalCtrl.create({
      component: ShareContentModalComponent,
      backdropDismiss: false
    });

    modal
      .onDidDismiss()
      .then((result: OverlayEventDetail<ShareContentModalResponse>) => {
        if (result && result.data) {
          const userIso2 = this.store.selectSnapshot(UserState.language);

          this.store.dispatch(new LogbookActions.ShareLogbookPdf(this.bookId, result.data.to, result.data.message, userIso2));
        }
      });

    await modal.present();
  }

  async shareEntry(entryId: string) {
    const modal = await this.modalCtrl.create({
      component: ShareContentModalComponent,
      backdropDismiss: false
    });

    modal
      .onDidDismiss()
      .then((result: OverlayEventDetail<ShareContentModalResponse>) => {
        if (result && result.data) {
          const userIso2 = this.store.selectSnapshot(UserState.language);

          this.store.dispatch(new LogbookActions.ShareEntryPdf(this.bookId, entryId, result.data.to, result.data.message, userIso2));
        }
      });

    await modal.present();

  }

  async downloadLogbook() {
    const userIso2 = this.store.selectSnapshot(UserState.language);
    const logbookTranslation = await this.translate.get('BOOKS.ADMIN.LOGBOOK.TITLE').toPromise();

    const filename = (this.store.selectSnapshot(BookDefinitionsState) as BookDefinitionsStateModel).bookDefinition.title;
    this.loader.show();

    await this.store.dispatch(new LogbookActions.DownloadLogbookPdf(this.bookId, userIso2, `${logbookTranslation}_${filename}.pdf`)).toPromise();

    this.loader.hide();
  }

  async downloadEntry(entryId: string) {
    const userIso2 = this.store.selectSnapshot(UserState.language);
    const logbookTranslation = await this.translate.get('BOOKS.ADMIN.LOGBOOK.TITLE').toPromise();

    const filename = (this.store.selectSnapshot(BookDefinitionsState) as BookDefinitionsStateModel).bookDefinition.title;

    this.loader.show();

    await this.store.dispatch(
      new LogbookActions.DownloadEntryPdf(this.bookId, entryId, userIso2, `${logbookTranslation}_${filename}_${entryId}.pdf`)
    ).toPromise();

    this.loader.hide();
  }
}
