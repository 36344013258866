import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import { ScanMode, ScanningOptions } from '@sonorus/api';
import { BorderSize } from '@sonorus/core';
import { ColorPickerService } from '../color-picker/color-picker.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';

export interface ScanningOptionsFormValue {
  enabled: boolean;
  audio: boolean;
  speed: number;
  scanMode: ScanMode;
  rowBorderSize: BorderSize;
  rowBorderColor: string;
  widgetBorderSize: BorderSize;
  widgetBorderColor: string;
}

@Component({
  selector: 'sonorus-admin-scanning-modal',
  templateUrl: './admin-scanning-modal.component.html',
  styleUrls: ['./admin-scanning-modal.component.css']
})
export class AdminScanningModalComponent implements OnInit {
  scanning: ScanningOptions;

  scanningFormGroup: FormGroup;

  readonly SCANMODE1 = ScanMode.OneButtonSimple;
  readonly SCANMODE2 = ScanMode.OneButtonAdvanced;
  readonly SCANMODE3 = ScanMode.TwoButtons;

  readonly BORDERSIZE1 = BorderSize.None;
  readonly BORDERSIZE2 = BorderSize.Small;
  readonly BORDERSIZE3 = BorderSize.Large;

  get rowSelectColor() {
    return (
      this.scanningFormGroup &&
      (<ScanningOptionsFormValue>this.scanningFormGroup.value).rowBorderColor
    );
  }

  get rowSelectionEnabled() {
    if (this.scanningFormGroup && this.scanningFormGroup.value) {
      return (
        (<ScanningOptionsFormValue>this.scanningFormGroup.value).scanMode !==
        this.SCANMODE1
      );
    } else {
      return false;
    }
  }

  get widgetSelectColor() {
    return (
      this.scanningFormGroup &&
      (<ScanningOptionsFormValue>this.scanningFormGroup.value).widgetBorderColor
    );
  }

  constructor(
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private colorPicker: ColorPickerService,
    private modalCtrl: ModalController,
    public sanitizer: DomSanitizer
  ) {
    this.scanningFormGroup = this.fb.group(<ScanningOptionsFormValue>{
      audio: false,
      speed: 1.5,
      scanMode: ScanMode.OneButtonSimple,
      rowBorderColor: this.colorPicker.COLOR_BLACK,
      rowBorderSize: this.BORDERSIZE2,
      widgetBorderColor: this.colorPicker.COLOR_RED,
      widgetBorderSize: this.BORDERSIZE2
    });
  }

  ngOnInit() {
    if (this.scanning) {
      this.scanningFormGroup.patchValue(<ScanningOptionsFormValue>{
        audio: this.scanning.audio,
        speed: this.scanning.speed || 1.5,
        scanMode: this.scanning.mode || ScanMode.OneButtonSimple,
        rowBorderColor:
          this.scanning.rowBorderColor || this.colorPicker.COLOR_BLACK,
        rowBorderSize: this.scanning.rowBorderSize || this.BORDERSIZE2,
        widgetBorderColor:
          this.scanning.widgetBorderColor || this.colorPicker.COLOR_RED,
        widgetBorderSize: this.scanning.widgetBorderSize || this.BORDERSIZE2
      });
    }
  }

  async changeRowSelectColor() {
    const oldColor = (<ScanningOptionsFormValue>this.scanningFormGroup.value)
      .rowBorderColor;

    const newColor = await this.colorPicker.openColorPicker(oldColor);

    this.scanningFormGroup.patchValue(
      {
        rowBorderColor: newColor
      },
      {
        emitEvent: true
      }
    );
    this.changeDetector.markForCheck();
  }

  async changeWidgetSelectColor() {
    const oldColor = (<ScanningOptionsFormValue>this.scanningFormGroup.value)
      .widgetBorderColor;

    const newColor = await this.colorPicker.openColorPicker(oldColor);

    this.scanningFormGroup.patchValue(
      {
        widgetBorderColor: newColor
      },
      {
        emitEvent: true
      }
    );
    this.changeDetector.markForCheck();
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  save() {
    this.modalCtrl.dismiss(this.scanningFormGroup.value);
  }
}
