import {
  BookDefinitionApiService,
  BookDefinition,
  PageDefinitionApiService,
  PageDefinition
} from '@sonorus/api';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { BookDefinitions } from './bookdefinitions.actions';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

export interface BookDefinitionsStateModel {
  bookDefinition: BookDefinition;
}

@State<BookDefinitionsStateModel>({
  name: 'bookDefinitions',
  defaults: {
    bookDefinition: undefined
  }
})
@Injectable()
export class BookDefinitionsState {
  @Selector()
  static title(state: BookDefinitionsStateModel) {
    return state.bookDefinition.title;
  }

  @Selector()
  static bookDefinition(state: BookDefinitionsStateModel) {
    return state.bookDefinition;
  }

  @Selector()
  static pageDefinitions(state: BookDefinitionsStateModel) {
    return state.bookDefinition.pages;
  }

  @Selector()
  static voiceSettings(state: BookDefinitionsStateModel) {
    return state.bookDefinition.voiceParams;
  }

  @Selector()
  static style(state: BookDefinitionsStateModel) {
    return state.bookDefinition.style;
  }

  constructor(
    private bookDefinitionApi: BookDefinitionApiService,
    private pageDefinitionApi: PageDefinitionApiService
  ) { }

  @Action(BookDefinitions.Load)
  loadBookDefinition(
    ctx: StateContext<BookDefinitionsStateModel>,
    action: BookDefinitions.Load
  ) {
    return this.bookDefinitionApi
      .get(action.bookId)
      .pipe(
        tap(bookDefinition => ctx.setState({ bookDefinition: bookDefinition }))
      );
  }

  @Action(BookDefinitions.Delete)
  async handleDelete(
    ctx: StateContext<BookDefinitionsStateModel>,
    action: BookDefinitions.Load
  ) {
    await this.bookDefinitionApi.delete(action.bookId).toPromise();

    const state = ctx.getState();

    if (state.bookDefinition && state.bookDefinition.id === action.bookId) {
      ctx.setState({
        bookDefinition: undefined
      });
    }
  }

  @Action(BookDefinitions.AddPage)
  addPage(
    ctx: StateContext<BookDefinitionsStateModel>,
    action: BookDefinitions.AddPage
  ) {
    const state = ctx.getState();

    return this.pageDefinitionApi
      .createPage(action.bookId, action.pageDefinition)
      .pipe(
        tap(async (savedPage: PageDefinition) => {
          const newState = _.cloneDeep(state) as BookDefinitionsStateModel;

          if (!newState.bookDefinition.pages) {
            newState.bookDefinition.pages = [];
          }
          newState.bookDefinition.pages.push(savedPage);

          ctx.setState(newState);

          if (action.startPage) {
            ctx.dispatch(
              new BookDefinitions.SetStarterPage(action.bookId, savedPage.id)
            );
          }
        })
      );
  }

  @Action(BookDefinitions.DeletePage)
  deletePage(
    ctx: StateContext<BookDefinitionsStateModel>,
    action: BookDefinitions.DeletePage
  ) {
    const state = ctx.getState();

    const newState = _.cloneDeep(state) as BookDefinitionsStateModel;
    newState.bookDefinition.pages = newState.bookDefinition.pages.filter(
      (p: PageDefinition) => p.id !== action.pageId
    );

    if (action.pageId === state.bookDefinition.startPageId) {
      newState.bookDefinition.startPageId =
        state.bookDefinition.pages && state.bookDefinition.pages.length > 0
          ? state.bookDefinition.pages[0].id
          : '';

      ctx.dispatch(
        new BookDefinitions.SetStarterPage(
          newState.bookDefinition.id,
          newState.bookDefinition.startPageId
        )
      );
    }

    return this.saveBook(action.bookId, newState.bookDefinition, ctx);
  }

  @Action(BookDefinitions.EditBookTitle)
  async editBookTitle(
    ctx: StateContext<BookDefinitionsStateModel>,
    action: BookDefinitions.EditBookTitle
  ) {
    const state = ctx.getState();

    const newState = _.cloneDeep(state) as BookDefinitionsStateModel;
    newState.bookDefinition.title = action.title;

    await this.bookDefinitionApi
      .editBookTitle(action.bookId, action.title).toPromise();

    ctx.setState(newState);
  }

  @Action(BookDefinitions.EditBookStyle)
  async editBookStyle(
    ctx: StateContext<BookDefinitionsStateModel>,
    action: BookDefinitions.EditBookStyle
  ) {
    const state = ctx.getState();
    const updatedState = _.cloneDeep(state) as BookDefinitionsStateModel;

    updatedState.bookDefinition.style = action.style;

    await this.bookDefinitionApi.editBookStyle(action.bookId, action.style).toPromise();

    ctx.patchState({
      bookDefinition: updatedState.bookDefinition
    });
  }

  @Action(BookDefinitions.EditBookSettings)
  editBookSettings(
    ctx: StateContext<BookDefinitionsStateModel>,
    action: BookDefinitions.EditBookSettings
  ) { }

  @Action(BookDefinitions.SetStarterPage)
  setStarterPage(
    ctx: StateContext<BookDefinitionsStateModel>,
    action: BookDefinitions.SetStarterPage
  ) {
    const state = ctx.getState();

    const newState = _.cloneDeep(state) as BookDefinitionsStateModel;
    newState.bookDefinition.startPageId = action.pageId;

    return this.bookDefinitionApi
      .setStartPage(
        newState.bookDefinition.id,
        newState.bookDefinition.startPageId
      )
      .pipe(
        tap(() =>
          ctx.setState({
            ...newState
          })
        )
      );
  }

  @Action(BookDefinitions.ChangePageOrder)
  async changePageOrder(ctx: StateContext<BookDefinitionsStateModel>,
    action: BookDefinitions.ChangePageOrder) {
    const state = ctx.getState();

    const pageIndexDictionary = {};
    for (let i = 0; i < action.pageIds.length; i++) {
      pageIndexDictionary[action.pageIds[i]] = i;
    }

    const newBookState: BookDefinition = _.cloneDeep(state.bookDefinition);
    newBookState.pages.sort((a, b) => pageIndexDictionary[a.id] - pageIndexDictionary[b.id]);

    await this.bookDefinitionApi.changePageOrder(action.bookId, action.pageIds).toPromise();

    ctx.patchState({
      bookDefinition: newBookState
    });
  }

  @Action(BookDefinitions.EditPageTitle)
  editPageTitle(
    ctx: StateContext<BookDefinitionsStateModel>,
    action: BookDefinitions.EditPageTitle
  ) {
    const state = ctx.getState();

    if (state) {
      const newState = _.cloneDeep(state) as BookDefinitionsStateModel;
      const impactedPage = newState.bookDefinition.pages.find(
        p => p.id === action.pageId
      );
      impactedPage.title = action.title;

      ctx.setState(newState);
    }
  }

  @Action(BookDefinitions.Clear)
  clearBookDefinition(
    ctx: StateContext<BookDefinitionsStateModel>,
    action: BookDefinitions.Clear
  ) {
    ctx.setState({
      bookDefinition: undefined
    });
  }

  private saveBook(
    bookId: string,
    bookDefinition: BookDefinition,
    ctx: StateContext<BookDefinitionsStateModel>
  ): Observable<BookDefinition> {
    return this.bookDefinitionApi.put(bookId, bookDefinition).pipe(
      tap(bookDefinition =>
        ctx.setState({
          bookDefinition: bookDefinition
        })
      )
    );
  }
}
