import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { ColorPickerOptions, ColorPickerResult } from './color-picker.service';



@Component({
  selector: 'sonorus-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorPickerComponent implements OnInit, ColorPickerOptions {
  currentColor: string;
  colorValues: string[];

  constructor(
    public sanitizer: DomSanitizer,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {}

  chooseColor(color: string) {
    const result: ColorPickerResult = {
      color: color
    };
    this.modalCtrl.dismiss(result);
  }

  cancel() {
    this.modalCtrl.dismiss();
  }
}
