import { Injectable } from '@angular/core';

import { BookStorageDTO, AudioCuesStorageDTO } from '../models';
import { booksDatabase } from './books.database';
import { IBooksRepository } from '../repositories.interfaces';
import { ScanningOptionsStorageDTO } from '../models/scanningoptions.model';
import { QuickChatStorageDTO } from '../models/quickchat.model';

@Injectable({
  providedIn: 'root'
})
export class IndexedDbBooksRepository implements IBooksRepository {
  async exists(bookId: string): Promise<boolean> {
    const count = await booksDatabase.books
      .where('bookId')
      .equals(bookId)
      .count();

    return count > 0;
  }

  add(book: BookStorageDTO) {
    return booksDatabase.transaction('rw', booksDatabase.books, async () => {
      await booksDatabase.books.put(book);
    });
  }

  update(book: BookStorageDTO) {
    return this.add(book);
  }

  getById(bookId: string): Promise<BookStorageDTO> {
    return booksDatabase.books
      .where('bookId')
      .equals(bookId)
      .first();
  }

  async getOfflineBooks(): Promise<BookStorageDTO[]> {
    const books = await booksDatabase.books.toArray();

    return books.filter(b => b.offline);
  }

  async getNotOfflineBooks(): Promise<BookStorageDTO[]> {
    const books = await booksDatabase.books.toArray();

    return books.filter(b => !b.offline);
  }

  getAll(): Promise<BookStorageDTO[]> {
    return booksDatabase.books.toArray();
  }

  async deleteBooksNotOffline() {
    const books = await booksDatabase.books.toArray();
    const bookIdsNotOffline = books.filter(b => !b.offline).map(b => b.bookId);

    return booksDatabase.transaction('rw', booksDatabase.books, async () => {
      for (const bookId in bookIdsNotOffline) {
        await booksDatabase.books
          .where('bookId')
          .equals(bookId)
          .delete();
      }
    });
  }

  remove(bookId: string): Promise<void> {
    return booksDatabase.transaction('rw', booksDatabase.books, async () => {
      await booksDatabase.books
        .where('bookId')
        .equals(bookId)
        .delete();
    });
  }

  addQuickChat(quickChat: QuickChatStorageDTO): Promise<void> {
    return booksDatabase.transaction(
      'rw',
      booksDatabase.quickChat,
      async () => {
        await booksDatabase.quickChat.put(quickChat);
      }
    );
  }

  getQuickChatById(bookId: string): Promise<QuickChatStorageDTO> {
    return booksDatabase.quickChat
      .where('bookId')
      .equals(bookId)
      .first();
  }

  deleteQuickChatById(bookId: string): Promise<void> {
    return booksDatabase.transaction(
      'rw',
      booksDatabase.quickChat,
      async () => {
        await booksDatabase.quickChat
          .where('bookId')
          .equals(bookId)
          .delete();
      }
    );
  }

  addAudioCues(audioCues: AudioCuesStorageDTO): Promise<void> {
    return booksDatabase.transaction(
      'rw',
      booksDatabase.audioCues,
      async () => {
        await booksDatabase.audioCues.put(audioCues);
      }
    );
  }

  getAudioCuesById(bookId: string): Promise<AudioCuesStorageDTO> {
    return booksDatabase.audioCues
      .where('bookId')
      .equals(bookId)
      .first();
  }

  deleteAudioCuesById(bookId: string): Promise<void> {
    return booksDatabase.transaction(
      'rw',
      booksDatabase.audioCues,
      async () => {
        await booksDatabase.audioCues
          .where('bookId')
          .equals(bookId)
          .delete();
      }
    );
  }

  addScanningOptions(
    scanningOptionsDTO: ScanningOptionsStorageDTO
  ): Promise<void> {
    return booksDatabase.transaction(
      'rw',
      booksDatabase.scanningOptions,
      async () => {
        await booksDatabase.scanningOptions.put(scanningOptionsDTO);
      }
    );
  }

  getScanningOptionsById(bookId: string): Promise<ScanningOptionsStorageDTO> {
    return booksDatabase.scanningOptions
      .where('bookId')
      .equals(bookId)
      .first();
  }

  deleteScanningOptionbsById(bookId: string): Promise<void> {
    return booksDatabase.transaction(
      'rw',
      booksDatabase.scanningOptions,
      async () => {
        await booksDatabase.scanningOptions
          .where('bookId')
          .equals(bookId)
          .delete();
      }
    );
  }
}
