import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngxs/store';
import { IVoiceParams, SpeechType } from '@sonorus/api';
import { BookDefinitionsState } from '@sonorus/state';

@Component({
  selector: 'sonorus-admin-widget-edit-audio',
  templateUrl: './admin-widget-edit-audio.component.html',
  styleUrls: ['./admin-widget-edit-audio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminWidgetEditAudioComponent implements OnChanges, OnInit {
  @Input()
  speechType: SpeechType;
  @Input()
  spokenText: string;
  @Input()
  audioUri: string;

  @Output()
  speechTypeChanged: EventEmitter<SpeechType> = new EventEmitter<SpeechType>();
  @Output()
  spokenTextChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  audioChanged: EventEmitter<File> = new EventEmitter<File>();
  @Output()
  audioDataUriChanged: EventEmitter<string> = new EventEmitter<string>();

  readonly TEXTTOSPEECH = SpeechType.TextToSpeech;
  readonly RECORDING = SpeechType.Recording;
  readonly UPLOAD = SpeechType.Upload;
  readonly MUTE = SpeechType.Mute;

  initialRecordingAudioUri: string;
  initialUploadAudioUri: string;
  voiceParams: IVoiceParams;

  constructor(private store: Store) { }

  ngOnChanges(changes: SimpleChanges) {
    console.log('AdminWidgetEditAudioComponent ngOnChanges');
    console.dir(changes);
  }

  ngOnInit(): void {
    if (!this.speechType) {
      this.speechType = this.TEXTTOSPEECH;
    } else {
      if (this.speechType === this.RECORDING && this.audioUri) {
        this.initialRecordingAudioUri = this.audioUri;
      } else if (this.speechType === this.UPLOAD && this.audioUri) {
        this.initialUploadAudioUri = this.audioUri;
      }
    }

    this.voiceParams = this.store.selectSnapshot(BookDefinitionsState.voiceSettings);

    // console.log('AdminWidgetEditAudioComponent ngOnInit');
    // console.log('speechType');
    // console.dir(this.speechType);
    // console.log('audioUri');
    // console.dir(this.audioUri);
    // console.log('initialRecordingAudioUri');
    // console.dir(this.initialRecordingAudioUri);
    // console.log('initialUploadAudioUri');
    // console.dir(this.initialUploadAudioUri);
    // console.log('voiceParams');
    // console.dir(this.voiceParams);
  }

  onSpeechTypeSelected(value: Event) {
    this.speechType = +(<any> value).detail.value;

    switch (this.speechType) {

      case this.TEXTTOSPEECH:
        // immediately set speechtype to text to speech
        this.speechTypeChanged.emit(SpeechType.TextToSpeech);
        break;
      case this.RECORDING:
        // only set speechtype when recording is made
        break;
      case this.UPLOAD:
        // only set speechtype when upload is done
        break;
      case this.MUTE:
        // immediately set speechtype to mute
        this.speechTypeChanged.emit(SpeechType.Mute);
        break;
    }
  }

  onUploadedAudioChanged(audioUri: File) {
    if (!audioUri) {
      this.onAudioDeleted();
    } else {
      this.onAudioAdded(this.UPLOAD, audioUri);
    }
  }

  onRecordedAudioChanged(audioFile: File) {
    if (!audioFile) {
      this.setToTextToSpeech();
    } else {
      this.onAudioAdded(this.RECORDING, audioFile);
    }
  }

  onRecordedDataUriChanged(audioDataUri: string){
    this.audioDataUriChanged.emit(audioDataUri);
  }

  onSpokenTextChanged(text: string) {
    this.speechTypeChanged.emit(SpeechType.TextToSpeech);
    this.spokenTextChanged.emit(text);
  }

  private onAudioDeleted() {
    this.speechType = this.TEXTTOSPEECH;
    this.speechTypeChanged.emit(SpeechType.TextToSpeech);
  }

  private onAudioAdded(speechType: SpeechType, audioFile: File) {
    this.speechTypeChanged.emit(speechType);
    this.audioChanged.emit(audioFile);
  }

  private setToTextToSpeech() { }
}
