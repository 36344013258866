<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>
      {{'BOOKS.ADMIN.LOGBOOK.TITLE' | translate}} {{bookTitle$ | async}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button *ngIf="usedInBrowser"
                  (click)="downloadLogbook()">
        <ion-icon slot="icon-only"
                  src="/assets/svg/fileformats/pdf-file.svg"></ion-icon>
      </ion-button>
      <ion-button *ngIf="usedInBrowser"
                  slot="end"
                  (click)="shareLogbook()">
        <ion-icon slot="icon-only"
                  name="mail"></ion-icon>
      </ion-button>
      <ion-button (click)="edit()">
        <ion-icon slot="icon-only"
                  name="create"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-text *ngIf="isInitialized && isEmpty">
    {{ 'BOOKS.ADMIN.LOGBOOK.EMPTY' | translate }}
  </ion-text>

  <ion-grid *ngIf="isInitialized && !isEmpty"
            class="container">
    <ion-row *ngFor="let entry of logbookEntries; trackBy: trackByEntryId">
      <sonorus-logbook-entry-row [entry]="entry"
                                 [readonly]="true"
                                 (downloadPdf)="downloadEntry(entry.id)"
                                 (mail)="shareEntry(entry.id)">
      </sonorus-logbook-entry-row>
    </ion-row>
  </ion-grid>

</ion-content>
