<!--
  Generated template for the AdminEditBookPage page.

  See http://ionicframework.com/docs/components/#navigation for more info on
  Ionic pages and navigation.
-->
<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'BOOK_EDIT_FONT' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <form [formGroup]="fontForm">
    <ion-list padding>

      <ion-item>
        <ion-label position="stacked">{{ 'BOOK_EDIT_CHOOSE_FONT' | translate }}</ion-label>
        <ion-select formControlName="font">
          <ion-select-option *ngFor="let fontListItem of availableFonts"
                             [value]="fontListItem.value">
            {{ fontListItem.title }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item>
        <div [class]="exampleCssClass"
             [class.text-regular]="true">
          Lorem ipsum sed dolor etc.
        </div>
      </ion-item>
    </ion-list>

  </form>

  <!-- JSON FORM {{bookForm.value | json}} -->
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="primary">
      <ion-button color="primary"
                  [disabled]="!fontForm.valid"
                  (click)="save()"
                  type="submit"
                  fill="solid">{{ 'SAVE_BUTTON_TEXT' | translate }}</ion-button>
      <ion-button color="danger"
                  (click)="cancel()"
                  type="button"
                  fill="solid">{{ 'CANCEL_BUTTON_TEXT' | translate }}</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
