<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'BOOKS.ADMIN.PAGES.ADD_PAGE_TITLE' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <form [formGroup]="addPageForm">
    <ion-list padding>
      <ion-list-header> </ion-list-header>
      <ion-item>
        <ion-label position="floating">{{
          'BOOKS.ADMIN.PAGES.PAGE_TITLE' | translate
        }}</ion-label>
        <ion-input type="text" formControlName="title"></ion-input>
      </ion-item>
      <ion-item>
        <ion-checkbox slot="start" formControlName="startPage"></ion-checkbox>
        <ion-label>{{ 'BOOKS.ADMIN.PAGES.START_PAGE' | translate }}</ion-label>
      </ion-item>
    </ion-list>
  </form>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="primary">
      <ion-button
        color="primary"
        (click)="addPage()"
        type="submit"
        fill="solid"
        >{{ 'BOOKS.ADMIN.PAGES.CREATE_PAGE' | translate }}</ion-button
      >
      <ion-button
        color="danger"
        (click)="cancel()"
        type="button"
        fill="solid"
        >{{ 'CANCEL_BUTTON_TEXT' | translate }}</ion-button
      >
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
