import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

export interface IAddPageFormValue {
  title: string;
  startPage: boolean;
}

@Component({
  selector: 'sonorus-add-page-dialog',
  templateUrl: './add-page-dialog.component.html',
  styleUrls: ['./add-page-dialog.component.css']
})
export class AddPageDialogComponent implements OnInit, IAddPageFormValue {
  title: string;
  startPage: boolean;
  addPageForm: FormGroup;

  constructor(private fb: FormBuilder, private modalCtrl: ModalController) {
    this.addPageForm = this.fb.group({
      title: ['', Validators.required],
      startPage: false
    });
  }

  ngOnInit() {
    const formValue: IAddPageFormValue = this;
    this.addPageForm.patchValue(formValue);
  }

  addPage() {
    if (this.addPageForm.valid) {
      const formValue: IAddPageFormValue = this.addPageForm.value;
      this.modalCtrl.dismiss(formValue);
    }
  }

  cancel() {
    this.modalCtrl.dismiss();
  }
}
