import { InjectionToken } from '@angular/core';

export const CORE_CONFIGURATION = new InjectionToken<CoreConfiguration>(
  'UsersModule settings'
);

export interface CoreConfiguration {
  api: {
    url: string;
    audience: string;
  };
  auth: {
    app: {
      // Needed for Auth0 (capitalization: ID):
      clientID: string;
      // Needed for Auth0Cordova (capitalization: Id):
      clientId: string;
      domain: string;
      // callbackURL: location.href,
      packageIdentifier: string; // config.xml widget ID, e.g., com.auth0.ionic
      scope: string;
      // audience: "https://dev-sonorus-api.azurewebsites.net/"
    };
    browser: {
      clientID: string;
      domain: string;
      responseType: string;
      scope: string;
      redirectUri: string;
    };
  };
  youtubeApiKey: string;
}
