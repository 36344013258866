import { Injectable } from '@angular/core';

// declare var MediaRecorder: any;

@Injectable({
  providedIn: 'root'
})
export class AudioService {

  constructor() {
  }

  startRecording() {
    // TODO: https://github.com/mdn/web-dictaphone/
    // with visualiser and start / stop buttons
    // https://github.com/mdn/web-dictaphone/blob/gh-pages/scripts/app.js

    // https://medium.com/@bryanjenningz/how-to-record-and-play-audio-in-javascript-faa1b2b3e49b
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorder.start();

        const audioChunks = [];

        mediaRecorder.addEventListener("dataavailable", event => {
          audioChunks.push(event.data);
        });

        mediaRecorder.addEventListener("stop", () => {
          const audioBlob = new Blob(audioChunks);
          const audioUrl = URL.createObjectURL(audioBlob);
          const audio = new Audio(audioUrl);
          audio.play();
        });

        setTimeout(() => {
          mediaRecorder.stop();
        }, 3000);
      });
  }
}
