import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GridsterConfig, GridsterItem } from 'angular-gridster2';
import { List } from 'immutable';
import {
  AlertController,
  PopoverController,
  ToastController,
  ModalController
} from '@ionic/angular';
import { WidgetComponent, ButtonWidget, FontFamily, mapFontFamilyToCssClass } from '@sonorus/features';
import {
  copyGridsterItemToWidgetReadOnly,
  copyModelToGridsterItem,
  mapModelToWidget,
  mapWidgetToModel
} from '@sonorus/features';
import {
  BookService,
  LoaderService,
  DeviceDetectorService,
  HapticsService
} from '@sonorus/core';
import {
  WidgetApiService,
  WidgetWithAssets,
  Command,
  PageApiService,
  PageWithAssets,
  BookStyleDefinition,
  Command6,
  IWidgetDefinition,
  Command4
} from '@sonorus/api';
import { BookDefinitions, BookDefinitionsState } from '@sonorus/state';
import { AdminPageSettingsPage } from '../admin-page-settings/admin-page-settings';
import {
  AdminWidgetEditPage,
  WidgetEditorOptions
} from '../admin-widget-edit/admin-widget-edit';
import { ActivatedRoute } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';
import { IMAGE_PLACEHOLDER_DATAURI } from '../constants';
/**
 * Generated class for the GridsterTestPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'admin-page-edit',
  styleUrls: ['./admin-page-edit.scss'],
  templateUrl: 'admin-page-edit.html'
})
export class AdminPageEditPage implements OnInit {
  @ViewChildren(WidgetComponent)
  widgets: QueryList<WidgetComponent>;

  pageTitleForm: FormGroup;

  bookId: string;
  pageWithAssets: PageWithAssets;
  pageId: string;
  pageProductMode: boolean;
  options: GridsterConfig;
  dashboard: List<GridsterItem>;
  fontCssClass: string;

  dirty: boolean;
  saveInProgress: boolean;

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private pageApi: PageApiService,
    private widgetApiService: WidgetApiService,
    private alertCtrl: AlertController,
    private popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    private loader: LoaderService,
    private bookProvider: BookService,
    private deviceDetector: DeviceDetectorService,
    private haptics: HapticsService,
    private translateService: TranslateService,
    private toastCtrl: ToastController,
    private fb: FormBuilder
  ) {
    this.pageTitleForm = this.fb.group({
      title: ['', Validators.required]
    });

    this.options = {
      minRows: 1,
      maxRows: 20,
      minCols: 1,
      maxCols: 20,

      outerMargin: false,
      mobileBreakpoint: 420,

      emptyCellClickCallback: this.createNewWidget,
      enableEmptyCellClick: true,
      // itemInitCallback: this.onItemInitCallback,
      itemChangeCallback: this.itemChange,
      itemResizeCallback: this.itemResize,
      draggable: {
        delayStart: 0, // milliseconds to delay the start of resize, useful for touch interaction
        enabled: true, // enable/disable draggable items
        ignoreContentClass: 'action-buttons', // default content class to ignore the drag event from
        ignoreContent: false, // if true drag will start only from elements from `dragHandleClass`
        dragHandleClass: 'drag-handler', // drag event only from this class. If `ignoreContent` is true.
        stop: this.onDragStop, // callback when dragging an item stops.  Accepts Promise return to cancel/approve drag.
        start: this.onDragStart // callback when dragging an item starts.
        // Arguments: item, gridsterItem, event
      },
      resizable: {
        delayStart: 0, // milliseconds to delay the start of resize, useful for touch interaction
        enabled: true, // enable/disable resizable items
        handles: {
          s: true,
          e: true,
          n: true,
          w: true,
          se: true,
          ne: true,
          sw: true,
          nw: true
        }, // resizable edges of an item
        stop: this.onResizeStop, // callback when resizing an item stops. Accepts Promise return to cancel/approve resize.
        start: this.onResizeStart // callback when resizing an item starts.
        // Arguments: item, gridsterItem, event
      },
      swap: true // allow items to switch position if drop on top of another
    };

    this.deviceDetector.isNative().then(native => {
      if (native) {
        this.options.resizable.delayStart = 500;
        this.options.draggable.delayStart = 500;
      }
    });
  }

  ngOnInit() {
    this.route.params.subscribe(async params => {
      this.bookId = params.bookId;
      this.pageId = params.pageId;
      this.pageProductMode = !!params.productId;

      if (!this.store.selectSnapshot(BookDefinitionsState.bookDefinition)) {
        await this.store
          .dispatch(new BookDefinitions.Load(this.bookId))
          .toPromise();
      }

      await this.loader.show();

      const url = await this.pageApi
        .getReadOnlyUrl(this.bookId, this.pageId)
        .toPromise();
      const response = await fetch(url, {
        cache: 'no-cache'
      });

      let pageWithAssets: PageWithAssets;
      if (response.status === 200) {
        try{
          pageWithAssets = await response.json();
        } catch (e) {
          console.error('error downloading readonly page with assets', e);
        }
      }

      if (!pageWithAssets) {
        pageWithAssets = new PageWithAssets();
      }

      this.initializeForPage(pageWithAssets, this.store.selectSnapshot(BookDefinitionsState.style));
      await this.loader.hide();
    });
  }

  ionViewWillLeave() {
    return this.pageTitleForm.valid;
  }

  deleteWidget = (widgetToDelete: GridsterItem, index: number) => {
    this.translateService
      .get([
        'WIDGET_DELETE_TITLE',
        'WIDGET_DELETE_AREYOUSURE',
        'NO_BUTTON_TEXT',
        'YES_BUTTON_TEXT'
      ])
      .subscribe(async translations => {
        const alert = await this.alertCtrl.create({
          header: translations['WIDGET_DELETE_TITLE'],
          message: translations['WIDGET_DELETE_AREYOUSURE'],
          buttons: [
            {
              text: translations['YES_BUTTON_TEXT'],
              cssClass: 'primary',

              handler: async () => {
                // remove widget
                const indexToDelete = this.dashboard.findIndex(
                  widget =>
                    widgetToDelete.x === widget.x &&
                    widgetToDelete.y === widget.y
                );
                this.dashboard = this.dashboard.delete(indexToDelete);

                if (<IWidgetDefinition> widgetToDelete.pictureUrl) {
                  await this.widgetApiService
                    .deleteWidgetPicture(
                      this.bookId,
                      new Command6({
                        pictureUrl: widgetToDelete.pictureUrl
                      })
                    ).toPromise();
                }

                if ((<IWidgetDefinition> widgetToDelete).speechFileUrl) {
                  await this.widgetApiService.deleteWidgetCustomSpeech(
                    this.bookId,
                    new Command4({
                      customSpeechUrl: widgetToDelete.speechFileUrl
                    })).toPromise();
                }

                this.markAsDirty();
              }
            },
            {
              text: translations['NO_BUTTON_TEXT'],
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                // leave widget be
              }
            }
          ]
        });

        await alert.present();
      });
  };

  changedOptions() {
    this.options.api.optionsChanged();
  }

  removeItem(item) {
    this.dashboard.splice(this.dashboard.indexOf(item), 1);
  }

  openSettings($event: UIEvent) {
    this.popoverCtrl
      .create({
        component: AdminPageSettingsPage,
        componentProps: {
          gridConfig: this.options
        },
        event: $event
      })
      .then(popover => {
        popover.onDidDismiss().then(result => {
          const data = result.data;
          if (data) {
            this.options.minCols = data.columns;
            this.options.minRows = data.rows;
            this.options.api.optionsChanged();
            this.markAsDirty();
          }
        });

        popover.present();
      });
  }

  async save() {
    this.saveInProgress = true;
    await this.loader.show();

    const page = this.pageWithAssets;

    if (page.title !== this.pageTitleForm.get('title').value) {
      page.title = this.pageTitleForm.get('title').value;
      this.store.dispatch(
        new BookDefinitions.EditPageTitle(this.bookId, this.pageId, page.title)
      );
    }
    page.rows = this.options.minRows;
    page.cols = this.options.minCols;
    page.widgets = this.dashboard
      .map(m =>
        mapModelToWidget({
          ...(<ButtonWidget> m)
        })
      )
      .toArray();

    if (this.widgets) {
      this.widgets.forEach(w => w.constructWidget());
    }

    this.bookProvider.savePage(this.bookId, page).subscribe(
      async book => {
        await this.loader.hide();
        console.log('page saved');
        this.saveInProgress = false;
        this.markAsClean();
      },
      async err => {
        console.error('error saving page', err.message, err);
        this.presentToast('Error ' + err.message);
        await this.loader.hide();
        this.saveInProgress = false;
      },
      () => { }
    );
  }

  private initializeForPage(pageWithAssets: PageWithAssets, style: BookStyleDefinition) {
    this.pageWithAssets = pageWithAssets;

    if (style) {
      this.fontCssClass = mapFontFamilyToCssClass(<FontFamily> style.fontFamily)
    };

    this.dashboard =
      pageWithAssets && pageWithAssets.widgetsWithAssets
        ? List(pageWithAssets.widgetsWithAssets.map(w => mapWidgetToModel(w)))
        : List([]);

    this.options.minRows = pageWithAssets.rows;
    this.options.minCols = pageWithAssets.cols;

    this.options.displayGrid = 'always';
    // this.options.minRows = 7;
    // this.options.minCols = 7;
    this.options.gridType = 'fit';

    this.pageTitleForm.patchValue({
      title: pageWithAssets.title
    });

    this.pageTitleForm
      .get('title')
      .valueChanges.pipe(debounceTime(1000))
      .subscribe(value => this.markAsDirty());

    this.options.api.optionsChanged();
  }

  private async onDragStart() {
    console.log('onDragStart');
    if (navigator && navigator.vibrate) {
      navigator.vibrate(200);
    } else if (this.deviceDetector.isMobile()) {
      await this.haptics.hapticsVibrate();
    }
  }

  private onDragStop = () => {
    // console.log('onDragStop');
  };

  private async onResizeStart() {
    // console.log('onResizeStart');

    if (navigator && navigator.vibrate) {
      navigator.vibrate(200);
    } else if (this.deviceDetector.isMobile()) {
      await this.haptics.hapticsVibrate();
    }
  }

  private onResizeStop = () => {
    // console.log('onResizeStop');
    this.markAsDirty();
  };

  private itemChange = (item, itemComponent) => {
    // tslint:disable-next-line:no-console
    // console.info('itemChanged', item, itemComponent);
    this.markAsDirty();
  };

  private itemResize = (item, itemComponent) => {
    // tslint:disable-next-line:no-console
    // console.info('itemResized', item, itemComponent);
  };

  // ADD WIDGET
  private createNewWidget = async (event: MouseEvent, item: GridsterItem) => {
    await this.loader.show();

    const widgetDTO = new WidgetWithAssets();
    copyGridsterItemToWidgetReadOnly(item, widgetDTO);

    this.widgetApiService
      .createWidget(
        this.bookId,
        this.pageId,
        new Command({
          bookId: this.bookId,
          pageId: this.pageId,
          widget: widgetDTO
        })
      )
      .subscribe(async createdWidget => {
        item.id = createdWidget.id;
        const translations = await this.translateService.get(['BOOKS.ADMIN.WIDGETS.PLACEHOLDER_TITLE']).toPromise();
        item.displayText = translations['BOOKS.ADMIN.WIDGETS.PLACEHOLDER_TITLE'];
        item.pictureBase64 = IMAGE_PLACEHOLDER_DATAURI;

        const newWidgetModel = await this.openWidgetModal(item);
        if (newWidgetModel) {
          this.dashboard = this.dashboard.push(newWidgetModel);

          this.markAsDirty();
        }
      });
  };

  // EDIT WIDGET
  editWidget = async (item: GridsterItem, index: number) => {
    const updatedWidget = await this.openWidgetModal(item);

    if (updatedWidget) {
      this.dashboard = this.dashboard.update(index, () => updatedWidget);
      this.markAsDirty();
    }
  };

  private async openWidgetModal(widget: GridsterItem): Promise<GridsterItem> {
    await this.loader.show();

    const modalData: WidgetEditorOptions = {
      bookId: this.bookId,
      pageId: this.pageId,
      widgetData: widget,
      pageProductMode: this.pageProductMode
    };

    const modal = await this.modalCtrl.create({
      component: AdminWidgetEditPage,
      componentProps: _.cloneDeep(modalData),
      backdropDismiss: false,
      cssClass: 'full-screen-modal' // 'big-modal'
    });

    await modal.present();

    await this.loader.hide();

    const result = await modal.onDidDismiss();

    if (result.data) {
      const gridsteritem = copyModelToGridsterItem(result.data, widget);
      return gridsteritem;
    }

    return undefined;
  }

  private presentToast(msg) {
    this.toastCtrl
      .create({
        message: msg,
        duration: 3000,
        position: 'bottom'
      })
      .then(toast => {
        toast.onDidDismiss().then(() => {
          console.log('Dismissed toast');
        });

        toast.present();
      });
  }

  isDirty() {
    return this.dirty;
  }

  private markAsDirty() {
    this.dirty = true;
  }

  private markAsClean() {
    this.dirty = false;
  }
}
