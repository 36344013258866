import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import * as _ from 'lodash';
import { map, tap, mergeMap, switchMap } from 'rxjs/operators';
// import { Book } from "../../models/book";
import { AuthenticationProvider } from '../authentication/authentication';
import { SettingsProvider } from '../settings/settings';
import {
  BookDefinition,
  BookDefinitionApiService,
  IPageDefinition,
  PageDefinition,
  PageDefinitionApiService,
  WidgetDefinition,
  UserBookApiService,
  UserBooks
} from '@sonorus/api';
import { Observable, from, of } from 'rxjs';

// import { FormBuilder } from '@angular/forms';
// import { first } from 'rxjs/operators';

// type DataResponse<T> = {
//   api: boolean;
//   data: T;
// };
/*
  Generated class for the BookProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
// */
// create a sandbox pattern here
// bridge between api and storage
@Injectable({
  providedIn: 'root'
})
export class BookService {
  private readonly USERBOOK_STORAGE_KEY = (userId: string) =>
    `userbook_${userId}`;

  constructor(
    public http: HttpClient,
    public storage: Storage,
    private settings: SettingsProvider,
    public bookDefinitionApiService: BookDefinitionApiService,
    public pageDefinitionApiService: PageDefinitionApiService,
    public userBookApiService: UserBookApiService,
    public auth: AuthenticationProvider
  ) {}

  deleteBook = (id: string): Observable<void> => {
    return this.settings.get().pipe(
      mergeMap(settings => {
        if (settings && settings.offline) {
          throw new Error('CANNOT DELETE BOOK IN OFFLINE MODE');
        }

        return this.bookDefinitionApiService.delete(id);
      })
    );
  };

  saveBook = (book: BookDefinition): Observable<BookDefinition> => {
    // clone book because we want to remove some info to save bandwidth
    const bookToSave = _.cloneDeep(book) as BookDefinition;

    // fetch user and save book
    return this.settings.get().pipe(
      switchMap(settings => {
        if (settings && settings.offline) {
          throw new Error('CANNOT SAVE BOOK IN OFFLINE MODE');
        }

        if (bookToSave.id && book.id !== '') {
          return this.bookDefinitionApiService.put(book.id, bookToSave);
        } else {
          return this.bookDefinitionApiService.post(bookToSave);
        }
      })
    );

    // create provider for userbook with offline / online fetch
  };

  getPage(bookId, pageId) {
    return this.pageDefinitionApiService.getPage(bookId, pageId).toPromise();
  }

  savePage = (
    bookId: string,
    page: IPageDefinition
  ): Observable<BookDefinition> => {
    const pageToSave: IPageDefinition = _.cloneDeep(page);

    const pageDefinition = new PageDefinition({
      ...pageToSave
    });
    pageDefinition.widgets = pageToSave.widgets.map(
      w => new WidgetDefinition({ ...w })
    );

    return this.pageDefinitionApiService.editPage(bookId, pageDefinition);
  };

  getUserBooks = (userId: string): Observable<UserBooks> => {
    let userBooks: UserBooks;
    return this.settings.get().pipe(
      mergeMap(settings => {
        if (!settings || !settings.offline) {
          return this.userBookApiService.getAllForUser(userId).pipe(
            tap((books: UserBooks) => (userBooks = books)),
            mergeMap(books =>
              from(this.storage.set(this.USERBOOK_STORAGE_KEY(userId), books))
            ),
            map(() => userBooks)
          );
        } else {
          return from(this.storage.get(this.USERBOOK_STORAGE_KEY(userId)));
        }
      })
    );
  };
}
