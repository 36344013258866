<ion-item>
  <ion-label position="floating">{{ 'LANGUAGE' | translate }}</ion-label>
  <ion-thumbnail slot="start"
                 class="culture-picker__country-flag">
    <ion-img *ngIf="selectedCulture"
             style="padding-bottom: 10px"
             [src]="'/assets/imgs/flags/24/' + selectedCulture.countryCode + '.png'"></ion-img>
  </ion-thumbnail>
  <ion-select [okText]="'OK_BUTTON_TEXT' | translate"
              [cancelText]="'CANCEL_BUTTON_TEXT' | translate"
              [value]="selectedCultureValue"
              (ionChange)="onCultureChange($event)">
    <ion-select-option *ngFor="let supportedCulture of supportedCultures"
                       [value]="supportedCulture.isoCode">
      {{ 'CULTURES.' + supportedCulture.isoCode | translate }} ({{
      supportedCulture.countryCode
      }})
    </ion-select-option>
  </ion-select>
</ion-item>
