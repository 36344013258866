import { Injectable, Inject, OnDestroy } from '@angular/core';
import * as auth0 from 'auth0-js';
import _ from 'lodash';
import { Store } from '@ngxs/store';

import { LoadUserByAuth0Id } from "@sonorus/state";
import { IAuthenticationProvider } from './authentication.interface';
import {CORE_CONFIGURATION, CoreConfiguration} from '../../config/core-configuration';
import { AuthenticationBase } from './authentication-base';
import { Subscription, of, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

/*
  Generated class for the AuthenticationProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: 'root'
})
export class AuthenticationBrowserProvider extends AuthenticationBase
  implements IAuthenticationProvider, OnDestroy {
  public loading = false;

  private refreshSubscription: Subscription;
  private _idToken: string;
  private _accessToken: string;
  private _expiresAt: number;
  private auth0: auth0.WebAuth;

  // private userProfile: any;

  constructor(
    /*public router: Router*/
    // private userProvider: UserProvider,
    private store: Store,
    @Inject(CORE_CONFIGURATION) private coreConfig: CoreConfiguration
  ) {
    super();

    const authenticated = this.isAuthenticated();
    this.authenticatedSubject.next(authenticated);

    const auth0Config = _.cloneDeep(this.coreConfig.auth.browser);

    let domain = location.protocol + '//' + location.hostname;
    if (location.port) {
      domain += ':' + location.port;
    }

    auth0Config['redirectUri'] =
      domain + this.coreConfig.auth.browser.redirectUri;
    auth0Config['audience'] = this.coreConfig.api.audience;

    this.auth0 = new auth0.WebAuth(auth0Config);
  }

  ngOnDestroy(): void {
    this.authenticatedSubject.complete();
  }

  public login(): void {
    this.auth0.authorize();
  }

  public handleAuthentication(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          window.location.hash = '';
          this.setSession(authResult);
          const userInfo = this.getAuth0UserInfo();

          const auth0Id = userInfo.sub;
          this.store.dispatch(new LoadUserByAuth0Id(auth0Id)).subscribe(() => {
            this.authenticatedSubject.next(true);
            resolve(true);
          });

          // this.router.navigate(["/home"]);
        } else if (err) {
          // this.router.navigate(["/home"]);
          resolve(false);
          console.log(err);
        } else {
          resolve(false);
        }
      });
    });
  }

  private setSession(authResult): void {
    // Set the time that the Access Token will expire at
    const expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', JSON.stringify(expiresAt));

    this._accessToken = authResult.accessToken;
    this._idToken = authResult.idToken;
    this._expiresAt = expiresAt;

    this.scheduleRenewal();
  }

  public logout(): void {
    // retrieve current base url
    let baseUrl = window.location.protocol + '//' + window.location.hostname;
    if (window.location.port) {
      baseUrl += ':' + window.location.port;
    }

    // Remove tokens and expiry time from localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    // Go back to the home route
    // this.router.navigate(["/"]);
    this.authenticatedSubject.next(false);

    debugger;

    this.auth0.logout({
      returnTo: baseUrl,
      clientID: this.coreConfig.auth.browser.clientID
    });
  }

  private scheduleRenewal() {
    if (!this.isAuthenticated()) return;
    this.unscheduleRenewal();

    const expiresAt = this._expiresAt;

    const source = of(expiresAt).pipe(
      mergeMap(expiresAt => {
        const now = Date.now();

        // Use the delay in a timer to
        // run the refresh at the proper time
        return timer(Math.max(1, expiresAt - now));
      })
    );

    // Once the delay time from above is
    // reached, get a new JWT and schedule
    // additional refreshes
    this.refreshSubscription = source.subscribe(() => {
      this.renewTokens().then(success => {
        if (!success) {
          this.logout();
          return;
        }

        this.scheduleRenewal();
      });
    });
  }

  private unscheduleRenewal() {
    if (!this.refreshSubscription) return;
    this.refreshSubscription.unsubscribe();
  }

  private renewTokens(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      this.auth0.checkSession({}, (err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult);

          resolve(true);
        } else if (err) {
          console.error(`Could not get a new token (${err.error}: ${err.error_description}).`);

          resolve(false);
        }
      });

    });
  }
}
