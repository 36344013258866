<!-- initial / no recording -->
<!-- <ion-list *ngIf="(currentState$ | async) === STATE_EMPTY">
    <ion-item button="true"
              color="primary"
              (click)="record()">
        <ion-label>Maak Opname</ion-label>
        <ion-icon slot="end"
                  name="ellipse"
                  color="danger"></ion-icon>
    </ion-item>
</ion-list> -->

<!-- <h2>{{(currentState$ | async)}}</h2> -->

<!-- recording -->
<ion-grid *ngIf="(currentState$ | async) === STATE_RECORDING || (currentState$ | async) === STATE_EMPTY">
    <ion-row>
        <ion-col size="2"
                 size-sm="3"
                 size-md="3"
                 size-lg="2">
            <ion-label>{{audioRecordingSeekFormatted}}</ion-label>
        </ion-col>
        <ion-col>
            <ion-progress-bar style="margin-top: 20px;"
                              color="primary"
                              [value]="audioRecordingProgress"></ion-progress-bar>
        </ion-col>
        <ion-col size="2"
                 size-sm="3"
                 size-md="3"
                 size-lg="2">
            <ion-label>{{maxAudioRecordingDurationFormatted}}</ion-label>
        </ion-col>
        <ion-col size="2"
                 size-sm="3"
                 size-md="3"
                 size-lg="2">
            <ion-button *ngIf="isRecording === false"
                        (click)="record()">
                <ion-icon name="ellipse"
                          color="danger"></ion-icon>
            </ion-button>
            <ion-button *ngIf="isRecording === true"
                        (click)="stopRecording()">
                <ion-icon name="square"></ion-icon>
            </ion-button>
        </ion-col>
    </ion-row>
</ion-grid>

<!-- playback -->
<ion-grid *ngIf="(currentState$ | async) === STATE_PLAYBACK">
    <ion-row>
        <ion-col size="2"
                 size-md="3"
                 size-lg="2">
            <ion-button *ngIf="isPlaying === false"
                        (click)="play()">
                <ion-icon name="play"></ion-icon>
            </ion-button>
            <ion-button *ngIf="isPlaying === true"
                        (click)="stopPlaying()">
                <ion-icon name="square"></ion-icon>
            </ion-button>
        </ion-col>
        <ion-col size="3"
                 size-md="3"
                 size-lg="2">
            <ion-label>{{audioSeekFormatted}}</ion-label>
        </ion-col>
        <ion-col>
            <ion-progress-bar style="margin-top: 20px;"
                              color="primary"
                              [value]="audioPlaybackProgress"></ion-progress-bar>
        </ion-col>
        <ion-col size="2"
                 size-md="3"
                 size-lg="2">
            <ion-label>{{audioDurationFormatted}}</ion-label>
        </ion-col>
        <ion-col size="1"
                 size-md="2"
                 size-lg="1">
            <ion-button (click)="deleteRecording()">
                <ion-icon name="trash"></ion-icon>
            </ion-button>
        </ion-col>
    </ion-row>
</ion-grid>

<!-- no device detected -->
<ion-list *ngIf="(currentState$ | async) === STATE_MISSINGMICROPHONE">
    <ion-item>
        <ion-icon name="warning"
                  slot="start"></ion-icon>
        <ion-label>Geen microfoon gedetecteerd</ion-label>
    </ion-item>
</ion-list>
