import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AudioHelperService {

  readonly allowedFileExtensions = ['.mp3', '.mpeg', '.opus', '.ogg', '.oga', '.wav', '.aac', /*/'.caf',*/ '.m4a', '.m4b', '.mp4', '.weba', '.webm'/*, 'dolby', 'flac'*/];

  constructor() { }

  public getAllowedFileExtensions(): string {
    return this.allowedFileExtensions.join(', ');
  }

  public formatSecondsToTimeString(duration: number) {
    const nonPaddedIntl = Intl.NumberFormat('es', { minimumIntegerDigits: 1 });
    const paddedIntl = Intl.NumberFormat('zh', { minimumIntegerDigits: 2 })

    const [delimiter] = new Date().toLocaleTimeString('en-us').match(/\b[:.]\b/);
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor(duration / 60) % 60;
    const seconds = Math.floor(duration % 60);
    const indexToPad = hours ? 0 : 1;
    const timeFormat =
      [hours, minutes, seconds]
        .map((val, i) => {
          return (val < 10 && i > indexToPad) ? paddedIntl.format(val) : nonPaddedIntl.format(val);
        })
        .filter((val, i) => {
          if (i === 0) {
            return !(val === '00' || val === '0');
          }

          return true;
        })
        .join(delimiter);

    return timeFormat;
  }
}
