import { Injectable } from '@angular/core';
import { ToastController, ModalController } from '@ionic/angular';
import { Plugins, CameraResultType } from '@capacitor/core';
import { Html5CameraPage } from '../../html5-camera/html5-camera';
import { DeviceDetectorService } from '../../../../../core';

const { Camera, App } = Plugins;
/*
  Generated class for the SmartCameraProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class SmartCameraProvider {
  private nativeMode: boolean;

  constructor(
    private deviceDetector: DeviceDetectorService,
    private toastCtrl: ToastController,
    private modalCtrl: ModalController
  ) {
    this.nativeMode = this.deviceDetector.isApp();
    App.addListener('appRestoredResult', appRestoredResult => {
      console.log('CAMERA SERVICE - APP RESTORED RESULT', appRestoredResult);
    });
  }

  getImageFromDevice = (): Promise<string> => {
    return this.nativeMode
      ? this.getImageFromCameraNative()
      : this.getImageFromDeviceHTML5();
  };

  getImageFromCamera = (): Promise<string> => {
    return this.getImageFromCameraNative();
    // return this.nativeMode
    //   ? this.getImageFromCameraNative()
    //   : this.getImageFromCameraHTML5();
  };

  private async getImageFromCameraHTML5(): Promise<string> {
    const modal = await this.modalCtrl.create({
      component: Html5CameraPage,
      cssClass: 'full-screen-modal'
    });

    await modal.present();

    const result = await modal.onDidDismiss();
    const base64Image = result.data;

    if (base64Image) {
      return base64Image;
    }
  }

  private getImageFromDeviceHTML5(): Promise<string> {
    return new Promise((resolve, reject) => resolve('test string'));
  }

  // private getImageFromDeviceNative = () => {
  //   Camera.
  //   const subject$ = new Subject<string>();
  //   const options: CameraOptions = {
  //     quality: 100,
  //     destinationType: this.camera.DestinationType.DATA_URL,
  //     sourceType: this.camera.PictureSourceType.PHOTOLIBRARY, // this.camera.
  //     encodingType: this.camera.EncodingType.JPEG
  //   };

  //   this.camera.getPicture(options).then(
  //     imageData => {
  //       subject$.next(`data:image/jpeg;base64,${imageData}`);
  //       subject$.complete();
  //     },
  //     err => {
  //       console.log(err);
  //       this.presentToast(err);
  //       subject$.complete();
  //     }
  //   );

  //   return subject$.asObservable();
  // };

  private async getImageFromCameraNative() {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.DataUrl
    });

    return image.dataUrl;

    // const subject$ = new Subject<string>();
    // const options: CameraOptions = {
    //   quality: 100,
    //   destinationType: this.camera.DestinationType.DATA_URL,
    //   sourceType: this.camera.PictureSourceType.CAMERA,
    //   encodingType: this.camera.EncodingType.JPEG
    // };

    // this.camera.getPicture(options).then(
    //   imageData => {
    //     subject$.next(`data:image/jpeg;base64,${imageData}`);
    //     subject$.complete();
    //   },
    //   err => {
    //     console.log(err);
    //     subject$.complete();
    //     this.presentToast(err);
    //   }
    // );

    // return subject$.asObservable();
  }

  private presentToast(msg) {
    this.toastCtrl
      .create({
        message: msg,
        duration: 3000,
        position: 'bottom'
      })
      .then(toast => toast.present());
  }
}
