import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesNotificationService {
  constructor(
    private translate: TranslateService,
    private alertCtrl: AlertController
  ) {}
  async confirm() {
    const translations = await this.translate
      .get(['CONFIRM.PAGE.UNSAVED', 'YES_BUTTON_TEXT', 'NO_BUTTON_TEXT'])
      .toPromise();
    const alert = await this.alertCtrl.create({
      message: translations['CONFIRM.PAGE.UNSAVED'],
      buttons: [
        {
          text: translations['NO_BUTTON_TEXT'],
          role: 'stay',
          cssClass: 'secondary'
        },
        {
          text: translations['YES_BUTTON_TEXT'],
          role: 'leave'
        }
      ]
    });
    await alert.present();
    const result: OverlayEventDetail<any> = await alert.onDidDismiss();
    if (result.role === 'stay') {
      return false;
    } else {
      return true;
    }
  }
}
