import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';

export interface IProgressLoaderProps {
  progress$: Observable<number>;
  message$: Observable<string>;
}

@Component({
  selector: 'sonorus-progress-loader',
  templateUrl: './progress-loader.component.html',
  styleUrls: ['./progress-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressLoaderComponent implements OnInit, IProgressLoaderProps {
  progress$: Observable<number>;

  message$: Observable<string>;

  constructor() {}

  ngOnInit() {}
}
