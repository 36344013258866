import { Injectable, OnDestroy } from '@angular/core';
import { TTSApiService, IVoiceParams, Query, VoiceParams } from '@sonorus/api';
import { AssetManagerProvider, SmartAudioProvider } from '@sonorus/core';
import { switchMap, tap } from 'rxjs/operators';
import { of, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TtsSamplerService implements OnDestroy {
  private readonly VOICETEST_KEY = 'test-voice';
  private VOICETEST_PATH = '';
  private sampleLoaded = false;

  constructor(
    private ttsApi: TTSApiService,
    private assetManager: AssetManagerProvider,
    private smartAudio: SmartAudioProvider
  ) {}

  ngOnDestroy(): void {
    if (this.sampleLoaded) {
      this.assetManager
        .removeMediaFile(this.VOICETEST_PATH)
        .then(() => this.smartAudio.unload(this.VOICETEST_KEY));
    }
  }

  playTest(sentence: string, voiceSettings: IVoiceParams) {
    return this.playSample(sentence, voiceSettings);
  }

  private playSample(sentence: string, voiceSettings: IVoiceParams) {
    const playTestChain = this.ttsApi
      .synthesizeToSpeech(
        new Query({
          text: sentence,
          voiceParams: new VoiceParams(voiceSettings)
        })
      )
      .pipe(
        switchMap(fileResponse =>
          this.assetManager.storeMediaFile(
            this.VOICETEST_KEY,
            'mp3',
            fileResponse.data
          )
        ),
        switchMap(filePathOrDataUri => {
          // console.log('play mediafile base64', filePathOrDataUri);
          this.smartAudio.preload(this.VOICETEST_KEY, filePathOrDataUri);
          this.smartAudio.play(this.VOICETEST_KEY);
          this.VOICETEST_PATH = filePathOrDataUri;
          this.sampleLoaded = true;

          return of(true);
        })
      );

    if (this.sampleLoaded) {
      return from(this.assetManager.removeMediaFile(this.VOICETEST_PATH)).pipe(
        tap(() => this.smartAudio.unload(this.VOICETEST_KEY)),
        tap(() => (this.sampleLoaded = false)),
        switchMap(() => playTestChain)
      );
    } else {
      return playTestChain;
    }
  }
}
