import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  AuthenticationProvider,
  PostAuthenticationRedirectService
} from '@sonorus/core';
import { Router } from '@angular/router';

@Injectable()
export class HttpResponseStatusCodeInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthenticationProvider,
    private postAuth: PostAuthenticationRedirectService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do stuff with response if you want
          }
        },
        (err: any) => {
          console.error('Failed HTTP Request', err);
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.postAuth.storeUrl(this.router.url);
              this.auth.login();
              // this.auth.collectFailedRequest(request);
            }
          }
        }
      )
    );
  }
}
