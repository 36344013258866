import {Component, OnInit, Input, ChangeDetectorRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import _ from 'lodash';

import {TranslateService} from '@ngx-translate/core';
import {AlertController, ModalController} from '@ionic/angular';
import {Store} from '@ngxs/store';
import {BookDefinitionsState} from '@sonorus/state';
import {debounceTime} from 'rxjs/operators';

import {LoaderService, BorderSize} from '@sonorus/core';
import {
  AssetsApiService,
  Base64ConversionResult,
  WidgetApiService,
  Query2,
  WidgetActionType,
  Command3,
  IWidgetWithAssets,
  Command5, SpeechType, SpeechMediaFile
} from '@sonorus/api';

import {AdminWidgetEditGiphySearchPage} from '../admin-widget-edit-giphy-search/admin-widget-edit-giphy-search';
import {AdminWidgetEditImageSearchPage} from '../admin-widget-edit-image-search/admin-widget-edit-image-search';
import {AdminWidgetEditPageSearchPage} from '../admin-widget-edit-page-search/admin-widget-edit-page-search';
import {
  AdminWidgetEditYoutubeSearchPage,
  YoutubeSearchModalResult
} from '../admin-widget-edit-youtube-search/admin-widget-edit-youtube-search';
import {ColorPickerService} from '../color-picker/color-picker.service';
import {FontFamily, mapFontFamilyToCssClass, WidgetComponent} from '@sonorus/features';
import {GridsterItem} from 'angular-gridster2';
import {WidgetStyleState} from "../state/widget-style.state";
import {WidgetStyleActions} from "../state/widget-style.actions";

export interface WidgetEditorOptions {
  bookId: string;
  pageId?: string;
  widgetData: IWidgetWithAssets;
  pageProductMode?: boolean;
  quickChatMode?: boolean;
}

interface WidgetFormValue {
  text: string;
  spokenText: string;
  speechType: SpeechType;
  textHidden: boolean;
  clickActionType: WidgetActionType;
  borderSize: BorderSize;
  borderColor: string;
  backgroundColor: string;
  textSize: string;
  textColor: string;
  textAlign: string;
}

/**
 * Generated class for the AddNewWidgetPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'admin-widget-edit',
  styleUrls: ['admin-widget-edit.scss'],
  templateUrl: 'admin-widget-edit.html'
})
export class AdminWidgetEditPage implements OnInit {
  @ViewChild(WidgetComponent)
  widgetComponent: WidgetComponent;

  @Input()
  widgetData: IWidgetWithAssets; //ButtonWidget;
  @Input()
  bookId: string;
  @Input()
  pageId: string;
  @Input()
  pageProductMode: boolean;
  @Input()
  quickChatMode: boolean;

  readonly EMPTY_PAGE_TITLE = this.translate.instant('BOOKS.EMPTY_TITLE');

  widgetForm: FormGroup;

  get gridsterItem() {
    return <GridsterItem><unknown>this.widgetData;
  }

  goToPageId: string;

  showBackdrop = false;

  // AUDIO THINGS
  audioURI: string;
  audioFile: File;

  // IMAGE THINGS
  imageURI: any;
  imageFileName: any;

  // YOUTUBE THINGS
  youtubeVideoId: string;
  youtubeVideoThumbnailUrl: string;

  // PAGE THINGS
  goToPageTitle: string;

  fontCssClass: string;
  isEdit: boolean;
  widgetActionType = WidgetActionType;

  private pictureDirty: boolean;
  private audioDirty: boolean;

  readonly CLICK_ACTION_PAGE = WidgetActionType.GoToPage;
  readonly CLICK_ACTION_YOUTUBE = WidgetActionType.GoToYoutubeVideo;
  readonly CLICK_ACTION_NOTHING = WidgetActionType.Nothing;

  get clickActionYoutube() {
    return (
      this.widgetForm.get('clickActionType').value === this.CLICK_ACTION_YOUTUBE
    );
  }

  get clickActionPage() {
    return (
      this.widgetForm.get('clickActionType').value === this.CLICK_ACTION_PAGE
    );
  }

  get buttonText() {
    return this.widgetForm.get('text').value;
  }

  get spokenText() {
    return this.widgetForm.get('spokenText').value;
  }

  get speechType() {
    return +this.widgetForm.get('speechType').value;
  }

  get buttonTextHidden() {
    return this.widgetForm.get('textHidden').value;
  }

  set buttonTextHidden(val: boolean) {
    this.widgetForm.patchValue({
      textHidden: val
    });
  }

  get borderColor() {
    return this.widgetForm.get('borderColor').value;
  }

  get backgroundColor() {
    return this.widgetForm.get('backgroundColor').value;
  }

  get textColor() {
    return this.widgetForm.get('textColor').value;
  }

  constructor(
    public sanitizer: DomSanitizer,
    private alertCtrl: AlertController,
    private translate: TranslateService,
    private store: Store,
    private widgetApi: WidgetApiService,
    private modalCtrl: ModalController,
    private loader: LoaderService,
    private colorPicker: ColorPickerService,
    private assetsApiService: AssetsApiService,
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef
  ) {
    this.widgetForm = this.fb.group({
      text: ['', Validators.required],
      textHidden: false,
      clickActionType: [
        {value: 0, disabled: this.pageProductMode},
        Validators.required
      ],
      spokenText: '',
      speechType: SpeechType.TextToSpeech,
      borderSize: '',
      borderColor: '',
      backgroundColor: '',
      textSize: '',
      textColor: '',
      textAlign: ''
    });
  }

  async ngOnInit() {
    const defaultStyle = await this.loadWidgetDefaultStyle();

    this.isEdit = this.widgetData.id ? true : false;

    this.audioURI = this.widgetData.speechMediaFile ? this.widgetData.speechMediaFile.base64Content : undefined;
    this.imageURI = this.widgetData.pictureBase64;
    this.youtubeVideoId = this.widgetData.youtubeVideoId;
    this.youtubeVideoThumbnailUrl = this.widgetData.youtubeVideoThumbnailUrl;

    const styleDefinition = this.store.selectSnapshot(BookDefinitionsState.style);
    if (styleDefinition) {
      this.fontCssClass = mapFontFamilyToCssClass(<FontFamily>styleDefinition.fontFamily)
    }

    // fill in here?
    const formValue: WidgetFormValue = {
      text: this.widgetData.displayText,
      textHidden: this.widgetData.displayTextHidden || defaultStyle.displayTextHidden,
      spokenText: !this.widgetData.spokenText || this.widgetData.spokenText === '' ? this.widgetData.displayText : this.widgetData.spokenText,

      speechType: +this.widgetData.speechType,

      clickActionType: this.widgetData.widgetActionType
        ? this.widgetData.widgetActionType
        : 0,
      borderSize: <BorderSize>this.widgetData.borderSize || defaultStyle.borderSize,
      borderColor: this.widgetData.borderColor || defaultStyle.borderColor,
      backgroundColor: this.widgetData.backgroundColor || defaultStyle.backgroundColor,
      textSize: this.widgetData.textSize || defaultStyle.textSize,
      textColor: this.widgetData.textColor || defaultStyle.textColor,
      textAlign: this.widgetData.textAlign || defaultStyle.textAlign
    };

    if (
      this.widgetData.goToPageId &&
      this.widgetData.goToPageId !== '00000000-0000-0000-0000-000000000000'
    ) {
      const pages = this.store.selectSnapshot(
        BookDefinitionsState.pageDefinitions
      );
      this.goToPageId = this.widgetData.goToPageId;
      this.setGoToPageTitle(
        pages.find(p => p.id === this.widgetData.goToPageId).title
      );
    }

    this.widgetForm.patchValue(formValue, {emitEvent: false});
    this.copyStateToWidgetData();
    // }

    this.widgetForm.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => this.copyStateToWidgetData());

    this.widgetForm.get('clickActionType').valueChanges.subscribe(
      clickActionType => {
        if (clickActionType === this.CLICK_ACTION_YOUTUBE) {
          this.searchYoutube();
        } else if (clickActionType === this.CLICK_ACTION_PAGE) {
          this.searchPages();
        }
      }
    );
  }

  async onDeleteImageClicked() {
    const translations = await this.translate.get([
      'WIDGET_PICTURE_DELETE_AREYOUSURE',
      'NO_BUTTON_TEXT',
      'YES_BUTTON_TEXT'
    ]).toPromise();

    const alerthandle = await this.alertCtrl.create({
      header: '',
      message: translations['WIDGET_PICTURE_DELETE_AREYOUSURE'],
      buttons: [
        {
          text: translations['YES_BUTTON_TEXT'],
          cssClass: 'danger',
          handler: () => {
            // delete that
            this.imageURI = undefined;
            this.widgetData.pictureBase64 = undefined;
            this.pictureDirty = true;

            this.widgetComponent.constructWidget();
            this.changeDetector.markForCheck();
          }
        },
        {
          text: translations['NO_BUTTON_TEXT'],
          role: 'cancel',
          handler: () => {
          }
        }]
    });

    await alerthandle.present();
  }

  searchPages() {
    this.modalCtrl
      .create({
        component: AdminWidgetEditPageSearchPage,
        backdropDismiss: true,
        cssClass: 'big-modal'
      })
      .then(async popover => {
        this.showBackdrop = true;
        await popover.present();

        popover.onDidDismiss().then(result => {
          if (result.data) {
            this.goToPageId = result.data.id;
            this.setGoToPageTitle(result.data.title);
          }
          this.showBackdrop = false;
        });
      });
  }

  searchYoutube() {
    this.modalCtrl
      .create({
        component: AdminWidgetEditYoutubeSearchPage,
        componentProps: {},
        backdropDismiss: true,
        cssClass: 'big-modal'
      })
      .then(popover => {
        this.showBackdrop = true;

        popover.present();

        popover.onDidDismiss().then(result => {
          const data = result.data as YoutubeSearchModalResult;
          if (data) {
            this.youtubeVideoId = data.videoId;
            this.youtubeVideoThumbnailUrl = data.thumbnailUrl;

            if (!this.imageURI) {
              this.assetsApiService
                .getExternalImageAsBase64(
                  new Query2({
                    url: this.youtubeVideoThumbnailUrl,
                    maxWidth: 600
                  })
                )
                .subscribe(base64Result => {
                  this.pictureChanged(base64Result.base64Image);
                });
            }
          }

          this.showBackdrop = false;
        });
      });
  }

  async searchImages() {
    const modal = await this.modalCtrl.create({
      component: AdminWidgetEditImageSearchPage,
      backdropDismiss: true,
      cssClass: 'big-modal uber-modal'
    });

    this.showBackdrop = true;

    await modal.present();

    modal.onDidDismiss().then(result => {
      this.showBackdrop = false;

      if (result.data) {
        const data = result.data as Base64ConversionResult;
        this.pictureChanged(data.base64Image);
      }
    });
  }

  async searchGifs() {
    this.showBackdrop = true;

    const modal = await this.modalCtrl.create({
      component: AdminWidgetEditGiphySearchPage,
      backdropDismiss: true,
      cssClass: 'big-modal uber-modal'
    });

    await modal.present();

    modal.onDidDismiss().then(result => {
      this.showBackdrop = false;

      if (result.data) {
        const data = result.data as Base64ConversionResult;
        this.pictureChanged(data.base64Image);
      }
    });
  }

  markPictureAsDirty() {
    this.pictureDirty = true;
  }

  markAudioAsDirty() {
    this.audioDirty = true;
  }

  removeGoToPage() {
    this.goToPageId = undefined;
    this.goToPageTitle = undefined;
    this.copyStateToWidgetData();
  }

  removeVideo() {
    this.youtubeVideoId = undefined;
    this.youtubeVideoThumbnailUrl = undefined;
    this.copyStateToWidgetData();
  }

  chooseYTThumbnailForButton() {
    this.assetsApiService
      .getExternalImageAsBase64(
        new Query2({
          url: this.youtubeVideoThumbnailUrl,
          maxWidth: 600
        })
      )
      .subscribe(base64Result => this.pictureChanged(base64Result.base64Image));
  }

  // async testVoice() {
  //   if (this.buttonText && this.buttonText.length > 0) {
  //     await this.loader.show();

  //     const voiceParams = this.store.selectSnapshot(
  //       BookDefinitionsState.voiceSettings
  //     );

  //     await this.ttsSampler
  //       .playTest(this.buttonText, voiceParams).toPromise();

  //     await this.loader.hide();
  //   }
  // }

  toggleDisplayTextVisibility() {
    this.buttonTextHidden = !this.buttonTextHidden;
    this.copyStateToWidgetData();
  }

  async changeBorderColor() {
    const formValue = this.widgetForm.value as WidgetFormValue;

    this.showBackdrop = true;

    const newColor = await this.colorPicker.openColorPicker(
      formValue.borderColor
    );

    this.showBackdrop = false;

    this.widgetForm.patchValue({
      borderColor: newColor
    });

    this.copyStateToWidgetData();
  }

  async changeBackgroundColor() {
    const formValue = this.widgetForm.value as WidgetFormValue;

    this.showBackdrop = true;

    const newColor = await this.colorPicker.openColorPicker(
      formValue.backgroundColor
    );
    this.showBackdrop = false;

    this.widgetForm.patchValue({
      backgroundColor: newColor
    });

    this.copyStateToWidgetData();
  }

  async changeTextColor() {
    const formValue = this.widgetForm.value as WidgetFormValue;
    this.showBackdrop = true;

    const newColor = await this.colorPicker.openColorPicker(
      formValue.textColor
    );
    this.showBackdrop = false;

    this.widgetForm.patchValue({
      textColor: newColor
    });

    this.copyStateToWidgetData();
  }

  private copyStateToWidgetData() {
    const formValue = this.widgetForm.value as WidgetFormValue;

    // if spokentext empty, replace with displaytext
    // if spokentext same as previous displaytext, replace with new displaytext
    const currentSpokenText = this.spokenText;
    const previousDisplayText = this.widgetData.displayText;
    if (!currentSpokenText
      || currentSpokenText === ''
      || currentSpokenText === previousDisplayText) {
      this.widgetForm.get('spokenText').setValue(formValue.text);
      formValue.spokenText = formValue.text;
      this.changeDetector.markForCheck();
    }

    // if (this.widgetForm.valid) {

    this.widgetData.widgetActionType = formValue.clickActionType;
    this.widgetData.borderSize = formValue.borderSize;
    this.widgetData.borderColor = formValue.borderColor;
    this.widgetData.backgroundColor = formValue.backgroundColor;
    this.widgetData.textSize = formValue.textSize;
    this.widgetData.textColor = formValue.textColor;
    this.widgetData.textAlign = formValue.textAlign;

    // nu widget preview in het midden van scherm, met live update display aanpassingen

    this.widgetData.displayText = this.buttonText;
    this.widgetData.spokenText = formValue.spokenText;
    this.widgetData.speechType = +formValue.speechType;
    this.widgetData.displayTextHidden = this.buttonTextHidden;
    this.widgetData.pictureBase64 = this.imageURI;
    this.widgetData.youtubeVideoId = this.youtubeVideoId;
    this.widgetData.youtubeVideoThumbnailUrl = this.youtubeVideoThumbnailUrl;
    this.widgetData.goToPageId = this.goToPageId;

    this.widgetData = _.cloneDeep(this.widgetData);
    // }
  }

  async save() {
    if (this.widgetForm.valid) {
      this.copyStateToWidgetData();

      this.saveDefaultWidgetStyle();

      if (!this.imageURI) {
        this.widgetData.pictureUrl = '';
      } else if (this.pictureDirty) {
        await this.loader.show();

        const pictureUrl = await this.uploadPicture();

        this.widgetData.pictureUrl = pictureUrl;
        if (!this.widgetData.pictureRevision) {
          this.widgetData.pictureRevision = 0;
        }
        this.widgetData.pictureRevision += 1;

        await this.loader.hide();

      }

      if (this.audioDirty) {
        if (this.widgetData.speechType === SpeechType.Recording || this.widgetData.speechType === SpeechType.Upload) {
          await this.loader.show();

          const uploadedAudioUrl = await this.uploadCustomAudio();
          this.widgetData.speechFileUrl = uploadedAudioUrl;

          await this.loader.hide();
        } else {
          this.widgetData.speechFileUrl = undefined;
        }

        if (!this.widgetData.speechFileRevision) {
          this.widgetData.speechFileRevision = 0;
        }
        this.widgetData.speechFileRevision += 1;
      }

      this.modalCtrl.dismiss(this.widgetData);
    }
  }

  private saveDefaultWidgetStyle() {
    this.store.dispatch(new WidgetStyleActions.Update({
      borderSize: <BorderSize>this.widgetData.borderSize,
      borderColor: this.widgetData.borderColor,
      backgroundColor: this.widgetData.backgroundColor,
      textSize: this.widgetData.textSize,
      textColor: this.widgetData.textColor,
      textAlign: this.widgetData.textAlign
    }))
  }

  private async loadWidgetDefaultStyle() {
    await this.store.dispatch(new WidgetStyleActions.Load()).toPromise();
    return this.store.selectSnapshot(WidgetStyleState.widgetStyle);
  }

  private async uploadCustomAudio() {
    const result = await this.widgetApi.uploadWidgetCustomSpeech(
      this.bookId, this.pageId, this.widgetData.id,
      {data: this.audioFile, fileName: this.audioFile.name}
    ).toPromise();

    return result.url;
  }

  private async uploadPicture() {
    let url: string;

    if (this.quickChatMode) {
      const uploadResponse = await this.widgetApi
        .uploadQuickChatWidgetPicture(
          this.bookId,
          this.widgetData.id,
          new Command5({
            bookId: this.bookId,
            widgetId: this.widgetData.id,
            imageDataUri: this.imageURI
          })
        )
        .toPromise();

      url = uploadResponse.url;
    } else {
      const uploadResponse = await this.widgetApi
        .uploadWidgetPicture(
          this.bookId,
          this.pageId,
          this.widgetData.id,
          new Command3({
            bookId: this.bookId,
            pageId: this.pageId,
            widgetId: this.widgetData.id,
            imageDataUri: this.imageURI
          })
        )
        .toPromise();
      url = uploadResponse.url;
    }

    return url
  }

  async cancel() {
    await this.modalCtrl.dismiss();
  }

  pictureChanged(base64Picture: string) {

    this.showBackdrop = false;

    this.imageURI = base64Picture;

    this.copyStateToWidgetData();

    this.markPictureAsDirty();
  }

  private setGoToPageTitle(pageTitle: string) {
    if (!pageTitle) {
      this.goToPageTitle = undefined;
    } else if (pageTitle && pageTitle !== '') {
      this.goToPageTitle = pageTitle;
    } else {
      this.goToPageTitle = this.EMPTY_PAGE_TITLE;
    }

    this.copyStateToWidgetData();
  }

  public onAudioChanged(audioFile: File) {
    // console.log('AUDIO CHANGED', audioFile);
    this.markAudioAsDirty();
    this.audioFile = audioFile;
  }

  public onAudioDataUriChanged(audioDataUri: string) {
    this.widgetData['mediaFile'] = new SpeechMediaFile({
      base64Content: audioDataUri
    });
  }

  public onSpeechTypeChanged(speechType: SpeechType) {
    // console.log('SPEECH TYPE CHANGED', speechType);
    this.markAudioAsDirty();
    this.widgetForm.get('speechType').setValue(speechType);
  }

  public onSpokenTextChanged(spokenText: string) {
    this.markAudioAsDirty();
    this.widgetForm.get('spokenText').setValue(spokenText);
  }
}
