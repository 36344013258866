import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sonorus-menu-header',
  templateUrl: './sonorus-menu-header.component.html',
  styleUrls: ['./sonorus-menu-header.component.scss']
})
export class SonorusMenuHeaderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
