import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input, Output, EventEmitter, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, timer } from 'rxjs';
import { Howl } from 'howler';
import { takeWhile } from 'rxjs/operators';
import { AudioHelperService } from '../audio-helper.service';

@Component({
  selector: 'sonorus-mini-audio-recorder',
  templateUrl: './mini-audio-recorder.component.html',
  styleUrls: ['./mini-audio-recorder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MiniAudioRecorderComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  audioDataUri: string;

  @Output()
  newAudio = new EventEmitter<File>();
  @Output()
  newDataUri = new EventEmitter<string>();

  currentState$: Observable<MiniAudioRecorderState>;
  recordDisabled: boolean;
  audioDurationFormatted: string;
  audioSeekFormatted: string;
  audioPlaybackProgress: number;
  audioDuration: number;

  audioRecordingProgress: number;
  audioRecordingSeekFormatted: string;
  maxAudioRecordingDurationFormatted: string;

  readonly STATE_EMPTY = MiniAudioRecorderState.Empty;
  readonly STATE_RECORDING = MiniAudioRecorderState.Recording;
  readonly STATE_PLAYBACK = MiniAudioRecorderState.Playback;
  readonly STATE_MISSINGMICROPHONE = MiniAudioRecorderState.MissingMicrophone;
  readonly MAX_RECORDING_DURATION_SECONDS = 120;

  private mediaRecorder: MediaRecorder;
  private currentStateSubject: BehaviorSubject<MiniAudioRecorderState>;
  private chunks: BlobPart[];
  private currentAudio: Howl;

  constructor(private cd: ChangeDetectorRef, private audioHelper: AudioHelperService) {
    this.currentStateSubject = new BehaviorSubject<MiniAudioRecorderState>(this.STATE_EMPTY);
    this.currentState$ = this.currentStateSubject.asObservable();
  }

  async ngOnInit() {
    this.audioRecordingSeekFormatted = this.audioHelper.formatSecondsToTimeString(0);
    this.maxAudioRecordingDurationFormatted = this.audioHelper.formatSecondsToTimeString(this.MAX_RECORDING_DURATION_SECONDS);

    var availableDevices = await navigator.mediaDevices.enumerateDevices();
    console.dir(availableDevices);
    console.log(availableDevices);

    this.currentState$.subscribe(((state: MiniAudioRecorderState) => {
      console.log('changed state: ' + state);

      this.cd.detectChanges();
    }));

    if (navigator.mediaDevices.getUserMedia) {
      console.log('getUserMedia supported.');

      const codecs = [
        'audio/ogg; codecs=opus',
        'audio/ogg; codecs=vorbis',
        'audio/ogg',
        'audio/webm; codecs=vorbis',
        'audio/webm; codecs=opus',
        'audio/webm',
        'audio/mp4'
      ];
      let codec = '';
      let extension = '';
      let codecFound = false;
      for (let i = 0; i < codecs.length; i++) {
        if (!codecFound) {
          if (!MediaRecorder.isTypeSupported(codecs[i])) {
            console.log(codecs[i] + ' not supported');
          } else {
            console.log(codecs[i] + ' SUPPORTED');
            codec = codecs[i];
            if(codecs[i].indexOf('audio/ogg') > -1){
              extension = 'ogg';
            } else {
              extension = codecs[i].indexOf('audio/webm') > -1 ? 'webm' : 'mp4';
            }
            codecFound = true;
          }
        }
      }

      if (!codecFound) {
        window.alert('No codec found, recording is not possible');
      } else {

        const constraints = { audio: true };

        let onSuccess = (stream: MediaStream) => {
          this.chunks = [];

          try {
            this.mediaRecorder = new MediaRecorder(stream, {
              mimeType: codec, // 'audio/ogg; codecs=opus',
            });
          } catch (err) {
            window.alert('Error creating mediarecorder: ' + err);
          }

          this.mediaRecorder.onstop = (e) => {
            console.log("data available after MediaRecorder.stop() called.");

            // console.log('chunks', this.chunks);

            const blob = new Blob(this.chunks, { 'type': codec/*'audio/ogg; codecs=opus'*/ });

            // console.log('blob', blob);

            this.chunks = [];
            const reader = new FileReader();
            reader.onloadend = e => {
              const audioURL = reader.result as string;

              this.newAudio.emit(new File([blob], 'recording.' + extension));
              this.newDataUri.emit(audioURL);
              
              this.load(audioURL);
            };
            reader.readAsDataURL(blob);
          }

          this.mediaRecorder.ondataavailable = (e: BlobEvent) => {
            this.chunks.push(e.data);
          }
        }

        let onError = function (err) {
          window.alert('Could not get recording device: ' + err);
          console.log('The following error occured: ' + err);
        }

        navigator.mediaDevices.getUserMedia(constraints).then(onSuccess, onError);
      }

    } else {
      console.log('getUserMedia not supported on your browser!');
      window.alert('getUserMedia not supported on your browser!');

      this.currentStateSubject.next(MiniAudioRecorderState.MissingMicrophone);
    }
  }

  ngOnDestroy() {
    if (this.isRecording) {
      this.stopRecording();
    }

    if (this.isPlaying) {
      this.stopPlaying();
    }

    if (this.currentAudio && this.currentAudio.state !== 'unloaded') {
      this.currentAudio.unload();
    }

    this.currentStateSubject.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.audioDataUri && changes.audioDataUri.currentValue) {
      this.load(this.audioDataUri);
    }
  }

  private load(audioDataUri: string) {
    if (this.currentAudio && this.currentAudio.state !== 'unloaded') {
      this.currentAudio.unload();
    }

    // console.log('audio data url', audioDataUri);

    this.audioSeekFormatted = '0:00';
    this.currentAudio = new Howl({
      src: audioDataUri,
      onload: () => {

        this.audioDuration = this.currentAudio.duration();
        console.log('audio duration: ', this.audioDuration);
        this.audioDurationFormatted = this.audioHelper.formatSecondsToTimeString(this.audioDuration);
        this.cd.detectChanges();
      },
      onplay: () => {
        this.isPlaying = true;
        console.log('sound started playing');

        // todo: start timer
        timer(0, 100).pipe(takeWhile(() => this.isPlaying)).subscribe(val => {
          console.log(val);
          const amountInSeconds = val / 10;
          this.audioPlaybackProgress = amountInSeconds / this.audioDuration;
          console.log(this.audioPlaybackProgress)
          this.audioSeekFormatted = this.audioHelper.formatSecondsToTimeString(amountInSeconds);
          this.cd.detectChanges();
        })
        // -- update slider
        // -- update start time
        this.cd.detectChanges();
      },
      onstop: () => {
        this.isPlaying = false;
        console.log('sound stopped playing');

        this.cd.detectChanges();
      },
      onend: () => {
        this.isPlaying = false;
        this.audioPlaybackProgress = 1;
        console.log('sound stopped playing');

        this.cd.detectChanges();
      }

    });

    console.log("setting state to PLAYBACK");
    this.currentStateSubject.next(MiniAudioRecorderState.Playback);
  }

  isRecording: boolean = false;
  record() {
    this.isRecording = true;
    this.currentStateSubject.next(MiniAudioRecorderState.Recording);

    timer(0, 100).pipe(takeWhile(() => this.isRecording)).subscribe(val => {
      if (val === 0) {
        try {
          this.mediaRecorder.start();
        } catch (err) {
          window.alert('Error starting recording: ' + err)

          var errJson = JSON.stringify(err);

          window.alert('Full error: ' + errJson);
        }
      }
      console.log(val);
      const timeInSeconds = val / 10;
      if (timeInSeconds === (this.MAX_RECORDING_DURATION_SECONDS - 1)) {
        this.stopRecording();
      }

      this.audioRecordingProgress = timeInSeconds / this.MAX_RECORDING_DURATION_SECONDS;

      this.audioRecordingSeekFormatted = this.audioHelper.formatSecondsToTimeString(timeInSeconds);

      this.cd.detectChanges();
    })
  }

  stopRecording() {
    this.mediaRecorder.stop();
    console.log(this.mediaRecorder.state);
    console.log("recorder stopped");
    this.isRecording = false;
  }

  isPlaying: boolean = false;
  play() {
    if (this.currentAudio && !this.isPlaying) {
      this.currentAudio.play();
    }
  }

  stopPlaying() {
    if (this.currentAudio) {
      this.currentAudio.stop();
    }
  }

  deleteRecording() {
    if (this.currentAudio) {
      this.isPlaying = false;
      if (this.currentAudio && this.currentAudio.state() === 'unloaded') {
        this.currentAudio.unload();
        this.currentAudio = undefined;
      }
      this.audioRecordingSeekFormatted = this.audioHelper.formatSecondsToTimeString(0);
      this.maxAudioRecordingDurationFormatted = this.audioHelper.formatSecondsToTimeString(this.MAX_RECORDING_DURATION_SECONDS);

      this.newAudio.emit(undefined);

      this.currentStateSubject.next(MiniAudioRecorderState.Empty);
    }
  }
}

enum MiniAudioRecorderState {
  Empty = 1,
  Recording,
  Playback,
  MissingMicrophone
}