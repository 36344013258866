<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-toggle>
        <ion-button>
          <ion-icon slot="icon-only" name="menu"></ion-icon>
        </ion-button>
      </ion-menu-toggle>
    </ion-buttons>
    <ion-title>{{ 'INVALIDSUBSCRIPTION.TITLE' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-card>
    <ion-card-header>
      <ion-card-title>
        <ion-icon name="warning" size="large"></ion-icon>
      </ion-card-title>
      <!--
        <ion-card-subtitle>
          <ion-icon name="warning"></ion-icon>
        </ion-card-subtitle>
      -->
    </ion-card-header>
    <ion-card-content>
      <div [innerHTML]="'INVALIDSUBSCRIPTION.MESSAGE' | translate: param"></div>
    </ion-card-content>
  </ion-card>
</ion-content>
