import Dexie from 'dexie';
import {
  BookStorageDTO,
  PageMetadataStorageDTO,
  PageContentStorageDTO,
  AudioCuesStorageDTO
} from '../models';
import { QuickChatStorageDTO } from '../models/quickchat.model';
import { ScanningOptionsStorageDTO } from '../models/scanningoptions.model';

export class BooksDatabase extends Dexie {
  books: Dexie.Table<BookStorageDTO, number>;
  quickChat: Dexie.Table<QuickChatStorageDTO, number>;
  audioCues: Dexie.Table<AudioCuesStorageDTO, number>;
  scanningOptions: Dexie.Table<ScanningOptionsStorageDTO, number>;
  pageMetadatas: Dexie.Table<PageMetadataStorageDTO, number>;
  pageContents: Dexie.Table<PageContentStorageDTO, number>;

  constructor() {
    super('books-store');

    this.version(1).stores({
      books: 'bookId,offline',
      pageMetadatas: 'pageId,bookId',
      pageContents: 'pageId,bookId'
    });

    this.version(2).stores({
      quickChat: 'bookId'
    });

    this.version(3).stores({
      scanningOptions: 'bookId'
    });

    this.version(4).stores({
      audioCues: 'bookId'
    });
  }
}

export const booksDatabase = new BooksDatabase();
