<!--
  Generated template for the AdminWidgetEditPageSearchPage page.

  See http://ionicframework.com/docs/components/#navigation for more info on
  Ionic pages and navigation.
-->
<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'WIDGET_SEARCH_PAGES_TITLE' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-list>
    <ion-item *ngFor="let page of (pages$ | async)"
              detail-none
              button
              (click)="selectPage(page)">
      <ion-label>
        <h4>{{page.title}}</h4>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
