import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './_guards';

import { LoginCallbackComponent } from './login-callback/login-callback.component';
import { InvalidSubscriptionComponent } from './invalid-subscription/invalid-subscription.component';
import { HomePage } from './home/home.page';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomePage
  },
  {
    path: 'user-info',
    loadChildren: () => import('./user-info/user-info.module').then(m => m.UserInfoPageModule)
  },
  {
    path: 'admin/books',
    // canActivate: [AuthGuard, SubscriptionCheckGuard],
    // TODO: Subscription
    canLoad: [AuthGuard /*, SubscriptionCheckGuard*/],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'books',
    canLoad: [AuthGuard],
    loadChildren: () => import('@sonorus/book-viewer').then(m => m.BookViewerModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'login-callback',
    component: LoginCallbackComponent
  },
  {
    path: 'invalid-subscription',
    component: InvalidSubscriptionComponent
  },
  { path: 'labs', loadChildren: () => import('./labs/labs.module').then(m => m.LabsModule) },
  { path: 'text-to-speech', loadChildren: () => import('./labs/text-to-speech/text-to-speech.module').then(m => m.TextToSpeechModule) },
  { path: 'my-pictosets', loadChildren: () => import('./my-pictosets/my-pictosets.module').then(m => m.MyPictosetsModule) },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
