import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  Plugins,
  FilesystemDirectory,
  FilesystemEncoding
} from '@capacitor/core';
import { DeviceDetectorService } from '../device-detector.service';

const { Filesystem } = Plugins;

// import { MediaFile } from '../../models/MediaFile';
/*
  Generated class for the AssetManagerProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class AssetManagerProvider {
  private readonly baseDirectory = FilesystemDirectory.Data;
  private readonly assetsDirectory = 'sonorus_assets';

  constructor(
    public http: HttpClient,
    private deviceDetector: DeviceDetectorService
  ) {
    console.log('Hello AssetManagerProvider Provider');
  }

  storeMediaFile = (
    name: string,
    extension: string,
    blob: Blob
  ): Promise<string> => {
    // if (this.deviceDetector.isApp()) {
    //   return this.storeBlobAsFile(name, extension, blob);
    // } else {
      return this.storeBlobAsBase64(blob);
    // }
  };

  async removeMediaFile(path: string): Promise<boolean> {
    // if (this.deviceDetector.isApp()) {
    //   console.log('not desktop');
    //   return new Promise((resolve, reject) => {
    //     try {
    //       Filesystem.deleteFile({
    //         path: path,
    //         directory: this.baseDirectory
    //       }).then(() => resolve(true));
    //     } catch (err) {
    //       reject(false);
    //     }
    //   });
    // } else {
    //   console.log('desktop');
      return new Promise((resolve, reject) => resolve(true));
    // }
  }

  dataURLtoBlob(dataurl): Blob {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  // **blob to dataURL**
  blobToDataURL(blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const a = new FileReader();
      a.onload = function(e) {
        resolve((<any>e.target).result);
      };
      a.readAsDataURL(blob);
    });
  }

  private async storeBlobAsFile(
    name: string,
    extension: string,
    blob: Blob
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileName = `${name}.${extension}`;
      this.ensureDirectoryExists(this.assetsDirectory)
        // .then(() => this.ensureDirectoryExists(pageDirectory))
        .then(async () => {
          const dataUri = await this.blobToDataURL(blob);
          return Filesystem.writeFile({
            path: `${this.assetsDirectory}/${fileName}`,
            data: dataUri,
            directory: this.baseDirectory,
            encoding: FilesystemEncoding.UTF8
          });
        })
        .then(result => {
          resolve(`${this.baseDirectory}${fileName}`);
        })
        .catch(err => {
          console.log('error saving file', this.baseDirectory, fileName);
          console.error(err);
          reject(err);
        });
    });
  }

  private storeBlobAsBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.addEventListener('loadend', () => {
          resolve(reader.result.toString());
        });
        reader.readAsDataURL(blob);
      } catch (err) {
        reject(err);
      }
    });
  }

  private async ensureDirectoryExists(directory: string) {
    try {
      let ret = await Filesystem.mkdir({
        path: directory,
        directory: this.baseDirectory,
        recursive: false // like mkdir -p
      });
    } catch (e) {
      console.error('Unable to make directory', e);
    }
  }
}
