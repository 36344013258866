<ion-content>
  <div
    style="height: 100%;width: 100%; display: flex; align-items: center; justify-content: center;"
  >
    <ion-list style="width: 50%;">
      <ion-list-header>
        <ion-label> {{ message$ | async }} </ion-label>
      </ion-list-header>
      <ion-progress-bar [value]="progress$ | async"></ion-progress-bar>
    </ion-list>
  </div>
</ion-content>
