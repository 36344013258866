import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppBootstrapService } from '../app-bootstrap/app-bootstrap.service';

@Injectable({
  providedIn: 'root'
})
export class PostAuthenticationRedirectService {
  constructor(
    private router: Router,
    private appBootstrapService: AppBootstrapService
  ) {}

  storeUrl(url: string) {
    if (window && window.localStorage) {
      window.localStorage.setItem('redirect', url);
    }
  }

  async redirect() {
    // debugger;
    if (window && window.localStorage) {
      await this.appBootstrapService.initialized$;
      const redirectUrl = window.localStorage.getItem('redirect');
      if (redirectUrl) {
        // debugger;
        return this.router.navigateByUrl(redirectUrl).then(
          () => {
            // debugger;
            window.localStorage.removeItem('redirect');
          },
          err => {
            // debugger;
            console.error('DEBUG redirect failed', err);
          }
        );
      } else {
        // debugger;
        console.log('111');
        return this.router.navigateByUrl('/');
      }
    } else {
      console.log('222');
      return this.router.navigateByUrl('/');
    }
  }
}
