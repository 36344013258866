import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ILogbook, LogBookApiService, LogBookSharingApiService, ShareByEmailDTO } from '@sonorus/api';
import { LoaderService } from '@sonorus/core';

import { LogbookActions } from './logbooks.actions';

import * as _ from 'lodash';
import { saveAs } from 'file-saver';
import { Injectable } from '@angular/core';


export interface LogbookStateModel extends ILogbook { }

const createEmptyState = () => {
  return {
    bookId: undefined,
    entries: undefined
  };
};

@State<LogbookStateModel>({
  name: 'logbookState',
  defaults: createEmptyState()
})
@Injectable()
export class LogbookState {
  @Selector()
  static logbook(state: LogbookStateModel) {
    return state;
  }

  constructor(private logbookApi: LogBookApiService,
    private logbookShareApi: LogBookSharingApiService
  ) { }

  @Action(LogbookActions.Load)
  async loadState(
    ctx: StateContext<LogbookStateModel>,
    action: LogbookActions.Load
  ) {
    const logbook = await this.logbookApi
      .getLogbookForBook(action.bookId)
      .toPromise();

    if (logbook) {
      return ctx.setState({
        bookId: logbook.bookId,
        entries: logbook.entries
      });
    }
  }

  @Action(LogbookActions.Clear)
  clearState(
    ctx: StateContext<LogbookStateModel>,
    _action: LogbookActions.Clear
  ) {
    ctx.setState(createEmptyState());
  }

  @Action(LogbookActions.AddEntry)
  async onAddEntry(
    ctx: StateContext<LogbookStateModel>,
    action: LogbookActions.AddEntry
  ) {
    const state = ctx.getState();

    action.entry.bookId = action.bookId;

    const savedEntry = await this.logbookApi
      .createLogbookEntry(action.bookId, action.entry)
      .toPromise();

    if (!state) {
      const logbook = await this.logbookApi
        .getLogbookForBook(action.bookId)
        .toPromise();

      ctx.setState({
        bookId: logbook.bookId,
        entries: logbook.entries
      });
    } else {
      const clonedLogbook = _.cloneDeep(state) as LogbookStateModel;
      clonedLogbook.entries = [savedEntry, ...clonedLogbook.entries];

      ctx.patchState({
        entries: clonedLogbook.entries
      });
    }
  }

  @Action(LogbookActions.UpdateEntry)
  async onUpdateEntry(
    ctx: StateContext<LogbookStateModel>,
    action: LogbookActions.UpdateEntry
  ) {
    const state = ctx.getState();

    const updateIndex = state.entries.findIndex(
      entry => entry.id === action.entryId
    );

    const updatedEntry = await this.logbookApi
      .updateLogbookEntry(action.bookId, action.entry)
      .toPromise();

    const updatedState = _.cloneDeep(state) as LogbookStateModel;
    updatedState.entries[updateIndex] = updatedEntry;

    ctx.setState(updatedState);
  }

  @Action(LogbookActions.DeleteEntry)
  async onDeleteEntry(
    ctx: StateContext<LogbookStateModel>,
    action: LogbookActions.DeleteEntry
  ) {
    const state = ctx.getState();

    await this.logbookApi
      .deleteLogbookEntry(action.bookId, action.entryId)
      .toPromise();

    const updatedState = _.cloneDeep(state) as LogbookStateModel;
    updatedState.entries = updatedState.entries.filter(
      e => e.id !== action.entryId
    );

    ctx.setState(updatedState);
  }

  @Action(LogbookActions.DownloadLogbookPdf)
  async onDownloadLogbookPdf(
    _ctx: StateContext<LogbookStateModel>,
    action: LogbookActions.DownloadLogbookPdf
  ) {
    const pdfFileResult = await this.logbookShareApi.generatePdfForLogbook(action.bookId).toPromise();

    saveAs(pdfFileResult.data, action.filename);
  }

  @Action(LogbookActions.DownloadEntryPdf)
  async onDownloadEntryPdf(
    _ctx: StateContext<LogbookStateModel>,
    action: LogbookActions.DownloadEntryPdf
  ) {
    const pdfFileResult = await this.logbookShareApi.generatePdfForEntry(action.bookId, action.entryId).toPromise();

    saveAs(pdfFileResult.data, action.filename);
  }

  @Action(LogbookActions.ShareLogbookPdf)
  async onShareLogbookPdf(
    _ctx: StateContext<LogbookStateModel>,
    action: LogbookActions.ShareLogbookPdf
  ) {
    await this.logbookShareApi.shareLogbookByEmail(action.bookId, new ShareByEmailDTO({
      emailAddresses: [action.email],
      message: action.message
    })).toPromise();
  }

  @Action(LogbookActions.ShareEntryPdf)
  async onShareEntryPdf(
    _ctx: StateContext<LogbookStateModel>,
    action: LogbookActions.ShareEntryPdf
  ) {
    await this.logbookShareApi.shareEntryByEmail(action.bookId, action.entryId, new ShareByEmailDTO({
      emailAddresses: [action.email],
      message: action.message
    })).toPromise();
  }
}
