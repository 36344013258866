<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'BOOKS.ADMIN.PICTOSET-PICKER.TITLE' | translate }}</ion-title>

    <ion-searchbar slot="end"
                   class="ion-no-padding"
                   animated="true"
                   [debounce]="1000"
                   (ionChange)="filterPictos($event?.target?.value)"
                   (ionClear)="filterPictos('')"></ion-searchbar>
    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-list *ngIf="loaded">
    <ion-item>
      <ion-label position="stacked">{{'WIDGET_PICTURE_PICTOSET' | translate}}</ion-label>
      <ion-select [okText]="'OK_BUTTON_TEXT' | translate"
                  [cancelText]="'CANCEL_BUTTON_TEXT' | translate"
                  [value]="pictosetValue"
                  (ionChange)="onPictosetChanged($event)">

        <ion-select-option *ngFor="let listItem of userPictosetListItems;let last = last"
                           [value]="listItem.id"
                           [class.item-divider]="last">
          {{listItem.title}}
        </ion-select-option>

        <ion-select-option *ngFor="let listItem of pictosetListItems"
                           [value]="listItem.id">
          {{listItem.title || listItem.id}}
        </ion-select-option>
      </ion-select>
    </ion-item>

    <ion-item *ngIf="pictos">
      <ion-grid>
        <ion-row>
          <ion-col *ngFor="let picto of pictos; let i = index"
                   size="12"
                   size-md="4">
            <div class="picto-container">
              <div class="picto-image">
                <img [src]="picto.url"
                     [alt]="picto.name"
                     class="clickable"
                     (click)="choosePicto(picto, i)"/>
              </div>
              <div class="picto-title">
                <ion-label>{{picto.name}}</ion-label>
              </div>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <sonorus-previous-next [hasPrevious]="hasPreviousPage"
                                   [hasNext]="hasNextPage"
                                   (previous)="getPrevious()"
                                   (next)="getNext()"></sonorus-previous-next>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-item>
  </ion-list>

  <canvas #c
          style="display:none"></canvas>
</ion-content>
