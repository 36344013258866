<form [formGroup]="ttsForm">
    <ion-item class="widget-text">
        <ion-label position="stacked">{{ 'WIDGET_BUTTON_TEXT' | translate }}</ion-label>
        <ion-input type="text"
                   formControlName="text"></ion-input>
        <ion-button slot="end"
                    (click)="testVoice()">
            <ion-icon name="play"></ion-icon>
        </ion-button>
    </ion-item>
</form>
