import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ColorPickerComponent } from './color-picker.component';

export interface ColorPickerOptions {
  currentColor: string;
  colorValues: string[];
}

export interface ColorPickerResult {
  color: string;
}

export const COLOR_BLACK = '#000000';
export const COLOR_WHITE = '#FFFFFF';
export const COLOR_RED = '#FF0000';
export const COLOR_BLUE = '#0000FF';
export const COLOR_YELLOW = '#FFFF00';
export const COLOR_GREEN = '#008000';
export const COLOR_PINK = '#FF1493';

@Injectable({
  providedIn: 'root'
})
export class ColorPickerService {
  readonly COLOR_BLACK = COLOR_BLACK;
  readonly COLOR_WHITE = COLOR_WHITE;
  readonly COLOR_RED = COLOR_RED;
  readonly COLOR_BLUE = COLOR_BLUE;
  readonly COLOR_YELLOW = COLOR_YELLOW;
  readonly COLOR_GREEN = COLOR_GREEN;
  readonly COLOR_PINK = COLOR_PINK;

  readonly FITZGERALD_COLOR_VALUES = [
    '#87cefa', // Blauw – adjectieven
    '#98fb98', // Groen – werkwoorden
    '#fdfd96', // Geel – voornaamwoorden en personen
    '#ff7f50', // Oranje – object
    '#FFFFFF', // Wit – vervoegingen
    '#ffb3de', // Roze – voornaamwoorden en sociale woorden
    '#df73ff', // Paars – vragen
    '#a0522d', // Bruin – beschrijvende woorden
    '#ff4500', // Rood – belangrijke en functionele woorden, noodgevallen
    '#c4c3d0' // Grijs – aanwijswoorden
  ];

  readonly COLOR_VALUES = [
    this.COLOR_BLACK, // black
    // this.COLOR_WHITE, // white
    this.COLOR_RED, // red
    this.COLOR_BLUE, // blue
    this.COLOR_YELLOW, // yellow
    this.COLOR_GREEN, // green
    this.COLOR_PINK, // pink
    ...this.FITZGERALD_COLOR_VALUES
  ];

  constructor(private modalCtrl: ModalController) { }

  public async openColorPicker(currentColor?: string): Promise<string> {
    const colorPickerProps: ColorPickerOptions = {
      colorValues: this.COLOR_VALUES,
      currentColor: currentColor
    };
    const modal = await this.modalCtrl.create({
      component: ColorPickerComponent,
      componentProps: colorPickerProps
    });

    await modal.present();

    const colorPickerResult = await modal.onDidDismiss();
    if (colorPickerResult.data) {
      return (colorPickerResult.data as ColorPickerResult).color;
    } else {
      return currentColor;
    }
  }
}
