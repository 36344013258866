import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanLoad,
  UrlSegment,
  Route
} from '@angular/router';
import {
  AuthenticationProvider,
  PostAuthenticationRedirectService,
  AppBootstrapService
} from '@sonorus/core';
import { tap, switchMap, first } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(
    private auth: AuthenticationProvider,
    private postAuthRedirect: PostAuthenticationRedirectService,
    private appBootstrapService: AppBootstrapService,
    private router: Router
  ) {}

  // can activate route
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.authCheck(state.url);
  }

  // can load module in memory
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.authCheck('/' + route.path);
  }

  private authCheck(url: string) {
    return this.appBootstrapService.initialized$.pipe(
      first(),
      switchMap(() => of(this.auth.isAuthenticated())),
      tap(authenticated => {
        if (!authenticated) {
          // debugger;
          this.postAuthRedirect.storeUrl(url);
          this.auth.login();

          
          // this.router.navigate(['/login']);
        }
      })
    );
  }
}
