export class LoggedIn {
  static readonly type = '[Logged In] set login state';
  constructor(
    public userId: string,
    public auth0Id: string,
    public auth0Email: string
  ) {}
}

export class ClearLoginState {
  static readonly type = '[Logged Out] clear login state';
  constructor() {}
}
