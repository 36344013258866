import { LogbookEntry } from '@sonorus/api';

export namespace LogbookActions {
  export class Load {
    static readonly type = '[Logbook] load logbook';
    constructor(public bookId: string) { }
  }

  export class Clear {
    static readonly type = '[Logbook] clear state';
  }

  export class AddEntry {
    static readonly type = '[Logbook] add entry to logbook';
    constructor(public bookId: string, public entry: LogbookEntry) { }
  }

  export class UpdateEntry {
    static readonly type = '[Logbook] update entry to logbook';
    constructor(
      public bookId: string,
      public entryId: string,
      public entry: LogbookEntry
    ) { }
  }

  export class DeleteEntry {
    static readonly type = '[Logbook] delete entry to logbook';
    constructor(public bookId: string, public entryId: string) { }
  }

  export class DownloadLogbookPdf {
    static readonly type = '[Logbook] download logbook pdf';
    constructor(
      public bookId: string,
      public iso2: string,
      public filename: string
    ) { }
  }

  export class DownloadEntryPdf {
    static readonly type = '[Logbook] download logbook entry pdf';
    constructor(
      public bookId: string,
      public entryId: string,
      public iso2: string,
      public filename: string) { }
  }

  export class ShareLogbookPdf {
    static readonly type = '[Logbook] share logbook pdf';
    constructor(
      public bookId: string,
      public email: string,
      public message: string,
      public iso2: string) { }
  }

  export class ShareEntryPdf {
    static readonly type = '[Logbook] share logbook entry pdf';
    constructor(
      public bookId: string,
      public entryId: string,
      public email: string,
      public message: string,
      public iso2: string
    ) { }
  }
}
