<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title> {{ 'LOGIN.TITLE' | translate }} </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding sonorus-overview">

  <ion-grid class="container">
    <ion-row>
      <ion-col size="12">
        <ion-card>
          <ion-card-header *ngIf="authenticated && userStateModel">
            <ion-card-title>
              {{ userStateModel.firstName }} {{ userStateModel.lastName }}
            </ion-card-title>
          </ion-card-header>
          <ion-card-header *ngIf="!authenticated">
            <ion-card-title>
              <p [innerHTML]="'LOGIN.WELCOME' | translate"></p>
              <ion-button (click)="login()">
                {{ 'LOGIN.LOGIN_BUTTON' | translate }}
              </ion-button>
            </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <p *ngIf="clock"
               [translate]="'LOGIN.MESSAGE'"
               [translateParams]="{ value: clock }"></p>

            <ion-list *ngIf="showInstallButton">
              <ion-item button
                        (click)="install()">
                <ion-thumbnail slot="start">
                  <ion-img src="/assets/svg/general/software-installer.svg"></ion-img>
                </ion-thumbnail>
                <ion-label>
                  {{ 'LOGIN.INSTALL' | translate }}
                </ion-label>
              </ion-item>
            </ion-list>
            <!-- 

            <button type="button"
                    (click)="install()">Cl</button> -->
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
