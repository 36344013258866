import { IPagesRepository } from '../repositories.interfaces';
import { PageContentStorageDTO, PageMetadataStorageDTO } from '../models';
import { FileSystemHelper } from './filesystem.helpers';

export class PagesFileSystemRepository implements IPagesRepository {
  private readonly DIR_SUFFIX = 'pages';
  private readonly METADATA_SUFFIX = 'page.metadata';
  private readonly CONTENT_SUFFIX = 'page.content';

  private fileHelper: FileSystemHelper;

  constructor() {
    this.fileHelper = new FileSystemHelper();
  }

  private dirName = (bookId: string) => `${this.DIR_SUFFIX}_${bookId};`;

  private contentFileName = (pageId: string) =>
    `${this.CONTENT_SUFFIX}.${pageId}.json`;

  private metadataFileName = (pageId: string) =>
    `${this.METADATA_SUFFIX}.${pageId}.json`;

  async addPageContent(pageContent: PageContentStorageDTO): Promise<void> {
    console.log('enter addPageContent');

    await this.fileHelper.mkdir(this.dirName(pageContent.bookId));

    await this.fileHelper.fileWrite(
      this.dirName(pageContent.bookId),
      this.contentFileName(pageContent.pageId),
      JSON.stringify(pageContent)
    );
  }

  async updatePageContent(pageContent: PageContentStorageDTO): Promise<void> {
    console.log('enter updatePageContent');

    const fileName = this.contentFileName(pageContent.pageId);

    await this.fileHelper.fileDelete(pageContent.bookId, fileName);
    await this.fileHelper.fileWrite(
      this.dirName(pageContent.bookId),
      fileName,
      JSON.stringify(pageContent)
    );
  }

  async addPageMetaData(pageMetadata: PageMetadataStorageDTO): Promise<void> {
    console.log('enter addPageMetaData');

    await this.fileHelper.mkdir(this.dirName(pageMetadata.bookId));

    await this.fileHelper.fileWrite(
      this.dirName(pageMetadata.bookId),
      this.metadataFileName(pageMetadata.pageId),
      JSON.stringify(pageMetadata)
    );
  }

  async updatePageMetaData(
    pageMetadata: PageMetadataStorageDTO
  ): Promise<void> {
    console.log('enter updatePageMetaData');

    const fileName = this.metadataFileName(pageMetadata.pageId);

    await this.fileHelper.fileDelete(
      this.dirName(pageMetadata.bookId),
      fileName
    );
    await this.fileHelper.fileWrite(
      this.dirName(pageMetadata.bookId),
      fileName,
      JSON.stringify(pageMetadata)
    );
  }

  async getPageContentById(
    bookId: string,
    pageId: string
  ): Promise<PageContentStorageDTO> {
    console.log('enter getPageContentById');

    const fileReadResult = await this.fileHelper.fileRead(
      this.dirName(bookId),
      this.contentFileName(pageId)
    );

    if (!fileReadResult) {
      return undefined;
    }

    return JSON.parse(fileReadResult.data);
  }

  async getPageMetadataById(
    bookId: string,
    pageId: string
  ): Promise<PageMetadataStorageDTO> {
    console.log('enter getPageMetadataById');

    const fileReadResult = await this.fileHelper.fileRead(
      this.dirName(bookId),
      this.metadataFileName(pageId)
    );

    console.log('leave getPageMetadataById');

    if (!fileReadResult) {
      return undefined;
    }

    return JSON.parse(fileReadResult.data);
  }

  async getBookPageContents(bookId: string): Promise<PageContentStorageDTO[]> {
    console.log('enter getBookPageContents');

    const bookDir = this.dirName(bookId);
    const readDirResult = await this.fileHelper.readdir(bookDir);
    if (!readDirResult.files) {
      return [];
    }

    const filenames = readDirResult.files.filter(
      filename => filename.indexOf(this.CONTENT_SUFFIX) > -1
    );

    const pageContents = [];
    let readResult;
    for (const filename of filenames) {
      readResult = await this.fileHelper.fileRead(bookDir, filename);
      if (readResult && readResult.data) {
        pageContents.push(JSON.parse(readResult.data));
      }
    }

    return pageContents;
  }

  async getBookPageMetadata(bookId: string): Promise<PageMetadataStorageDTO[]> {
    console.log('enter getBookPageMetadata');

    const bookDir = this.dirName(bookId);

    const readDirResult = await this.fileHelper.readdir(bookDir);
    if (!readDirResult.files) {
      return [];
    }

    const filenames = readDirResult.files.filter(
      filename => filename.indexOf(this.METADATA_SUFFIX) > -1
    );

    const pageContents = [];
    let readResult;
    for (const filename of filenames) {
      readResult = await this.fileHelper.fileRead(bookDir, filename);
      if (readResult && readResult.data) {
        pageContents.push(JSON.parse(readResult.data));
      }
    }

    return pageContents;
  }

  deleteBookPages(bookId: string): Promise<void> {
    console.log('enter deleteBookPages');

    return this.fileHelper.rmdir(this.dirName(bookId));
  }

  async exists(bookId: string, pageId: string): Promise<boolean> {
    console.log('enter exists');

    const contentReadResult = await this.fileHelper.fileRead(
      this.dirName(bookId),
      this.contentFileName(pageId)
    );

    const metadataReadResult = await this.fileHelper.fileRead(
      this.dirName(bookId),
      this.metadataFileName(pageId)
    );

    const contentExists =
      contentReadResult &&
      contentReadResult.data &&
      contentReadResult.data.length > 0;

    const metadataExists =
      metadataReadResult &&
      metadataReadResult.data &&
      metadataReadResult.data.length > 0;

    return contentExists && metadataExists;
  }

  async isOutdated(
    bookId: string,
    pageId: string,
    lastModified: number
  ): Promise<boolean> {
    console.log('enter isOutdated');

    const metadata = await this.getPageMetadataById(bookId, pageId);

    if (!metadata) {
      return true;
    }

    return metadata.lastModified < lastModified;
  }
}
