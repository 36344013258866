import { CultureModel } from "./culture.model";

export const SUPPORTED_CULTURES: CultureModel[] = [
    {
        isoCode: 'nl-BE',
        languageCode: 'nl',
        countryCode: 'BE'
    },
    {
        isoCode: 'fr-BE',
        languageCode: 'fr',
        countryCode: 'BE'
    },
    {
        isoCode: 'nl-NL',
        languageCode: 'nl',
        countryCode: 'NL'
    },
    {
        isoCode: 'de-DE',
        languageCode: 'de',
        countryCode: 'DE'
    },
    {
        isoCode: 'en-GB',
        languageCode: 'en',
        countryCode: 'GB'
    },
    {
        isoCode: 'bg-BG',
        languageCode: 'bg',
        countryCode: 'BG'
    }
];