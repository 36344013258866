<!--
  Generated template for the AdminBookPagesPage page.

  See http://ionicframework.com/docs/components/#navigation for more info on
  Ionic pages and navigation.
-->

<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>
      <!-- <ion-icon name="pencil"></ion-icon> -->
      <!-- {{title}} -->
      <form [formGroup]="titleForm">
        <ion-input placeholder="{{'BOOK_EDIT_TITLE' | translate}}"
                   formControlName="title"></ion-input>
      </form>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button *ngIf="usedInBrowser"
                  [title]="'BOOKS.ADMIN.LOGBOOK.TITLE' | translate"
                  (click)="goToLogbook()">
        <ion-icon slot="icon-only"
                  name="list-circle-outline"></ion-icon>
      </ion-button>
      <ion-button *ngIf="usedInBrowser"
                  [title]="'BOOK_GENERATE_PDF' | translate"
                  (click)="generateAndDownloadPdf()">
        <ion-icon slot="icon-only"
                  src="/assets/svg/fileformats/pdf-file.svg"></ion-icon>
      </ion-button>
      <ion-button (click)="showFontDialog()"
                  [title]="'BOOK_EDIT_FONT' | translate">
        <ion-icon slot="icon-only"
                  src="/assets/svg/general/text_fields-24px.svg"></ion-icon>
      </ion-button>
      <ion-button (click)="showVoiceSettingsDialog()"
                  [title]="'BOOK_EDIT_VOICE' | translate">
        <ion-icon slot="icon-only"
                  src="/assets/svg/general/talk.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

  <!-- <p>BOOK {{book | json}}</p> -->
  <ion-grid class="container">
    <ion-row>
      <ion-col size="8"
               sizeMd="6"
               sizeSm="12"
               sizeXs="12">
        <ion-grid>
          <ion-row>
            <ion-col size="9"
                     sizeLg="9"
                     sizeMd="7"
                     sizeSm="9"
                     sizeXs="8">
              <h2>{{ 'BOOKS.ADMIN.PAGES.TITLE' | translate }}</h2>

              <ion-text *ngIf="book && (!book.pages || book.pages.length === 0)">
                {{ 'BOOKS.ADMIN.PAGES.EMPTY' | translate }}
              </ion-text>
            </ion-col>
            <ion-col>
              <ion-button style="margin-top: 14px;"
                          [title]="'BOOKS.ADMIN.PAGES.REORDER' | translate"
                          (click)="onReorderPages()">
                <ion-icon name="swap-vertical-outline"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="book">
            <ion-col>
              <ion-item [formGroup]="startPageForm">
                <ion-label>
                  <ion-icon name="bookmark-outline"></ion-icon>
                  {{ 'BOOKS.ADMIN.PAGES.START_PAGE' | translate }}
                </ion-label>
                <ion-select [placeholder]="'BOOKS.ADMIN.PAGES.CHOOSE_START_PAGE' | translate"
                            [okText]="'OK_BUTTON_TEXT' | translate"
                            [cancelText]="'CANCEL_BUTTON_TEXT' | translate"
                            [value]="startPageId"
                            (ionChange)="onStartPageChanged($event)">
                  <ion-select-option *ngFor="let page of book?.pages"
                                     [value]="page.id">
                    {{ page.title }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row *ngFor="let page of book?.pages">
            <ion-col size="9"
                     sizeLg="9"
                     sizeMd="7"
                     sizeSm="9"
                     sizeXs="8">
              <ion-item>
                <ion-label (click)="editPage($event, page)"
                           class="clickable">
                  <ion-icon name="create-outline"
                            slot="start"></ion-icon>
                  {{ page.title }}
                  <ion-icon *ngIf="page.id === startPageId"
                            name="bookmark-outline"></ion-icon>
                </ion-label>
              </ion-item>
            </ion-col>
            <ion-col>
              <ion-button (click)="onDuplicatePage(page)"
                          color="primary"
                          fill="solid"
                          [title]="'DUPLICATE.PAGE.TITLE' | translate">
                <ion-icon name="copy"></ion-icon>
              </ion-button>
              <ion-button (click)="deletePage(page)"
                          color="danger"
                          fill="solid"
                          [title]="'PAGE_DELETE_TITLE' | translate">
                <ion-icon name="trash"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>


        <ion-list *ngIf="book">
          <ion-item button="true"
                    color="primary"
                    (click)="addPage()">
            <ion-icon name="add"
                      slot="start"></ion-icon>
            <ion-label class="ion-text-wrap">
              {{ 'BOOKS.ADMIN.PAGES.ADD_PAGE_TITLE' | translate }}
            </ion-label>
          </ion-item>
        </ion-list>



      </ion-col>
      <ion-col>
        <ion-card>
          <ion-card-content>
            <sonorus-admin-scanning [bookId]="book?.id"
                                    [scanning]="book?.scanning"></sonorus-admin-scanning>
            <sonorus-admin-quickchat [bookId]="book?.id"
                                     [quickChat]="book?.quickChat"
                                     [styleDefinition]="book?.style"></sonorus-admin-quickchat>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
