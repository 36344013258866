import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';
import { Observable, of, from } from 'rxjs';

/*
  Generated class for the SettingsProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class SettingsProvider {
  private readonly SETTINGS_KEY = 'SETTINGS';

  constructor(
    public http: HttpClient,
    public storage: Storage,
    public plt: Platform
  ) {
    console.log('Hello SettingsProvider Provider');
  }

  public get = (): Observable<Settings> => {
    if (this.plt.is('pwa')) {
      return of(JSON.parse(localStorage.getItem(this.SETTINGS_KEY)));
    } else {
      return from(this.storage.get(this.SETTINGS_KEY));
    }
  };

  public set = (settings: Settings) => {
    if (this.plt.is('pwa')) {
      return of(
        localStorage.setItem(this.SETTINGS_KEY, JSON.stringify(settings))
      );
    } else {
      return from(this.storage.set(this.SETTINGS_KEY, settings));
    }
  };
}

export interface Settings {
  offline: boolean;
  offlineBookIds: string[];
}
