import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { debounceTime, filter, mergeMap, tap } from 'rxjs/operators';
import {
  AssetsApiService,
  Base64ConversionResult,
  PagedImagesResult,
  Query2,
  SearchEngineImageResult
} from '@sonorus/api';
import { LoaderService } from '@sonorus/core';

interface IState {
  search: string;
  offset: number;
}

/**
 * Generated class for the AdminWidgetEditGiphySearchPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-admin-widget-edit-giphy-search',
  templateUrl: 'admin-widget-edit-giphy-search.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminWidgetEditGiphySearchPage implements OnInit {
  searchForm: FormGroup;
  images: SearchEngineImageResult[];

  private readonly limit = 9;
  private offset = 0;

  get hasNextPage() {
    return this.images && this.images.length === this.limit;
  }

  get hasPreviousPage() {
    return this.offset > 0;
  }

  get searchValue() {
    return this.searchForm.get('search').value;
  }

  constructor(
    private loader: LoaderService,
    private modalCtrl: ModalController,
    private assetsApiService: AssetsApiService,
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef
  ) { }

  async ngOnInit() {
    this.searchForm = this.fb.group({
      search: ''
    });

    this.searchForm
      .get('search')
      .valueChanges.pipe(
        debounceTime(1000),
        filter((v: string) => v && v.length >= 3),
        tap(async () => await this.loader.show()),
        mergeMap(v => this.assetsApiService.searchGiphyImages(v, this.limit, this.offset))
      )
      .subscribe(async (response: PagedImagesResult) => {
        this.images = response.images;
        this.changeDetector.detectChanges();
        await this.loader.hide();
      });

    await this.restoreState();
  }

  async selectImage(image: SearchEngineImageResult) {
    await this.loader.show();

    const result: Base64ConversionResult = await this.assetsApiService
      .getExternalImageAsBase64(
        new Query2({
          maxWidth: 500,
          url: image.imageUrl
        })
      ).toPromise();

    this.storeState();
    await this.loader.hide();
    this.modalCtrl.dismiss(result);
  }

  cancel() {
    this.storeState();
    this.modalCtrl.dismiss();
  }

  async getPrevious() {
    await this.loader.show();

    this.offset = this.offset - this.limit;
    const response: PagedImagesResult = await this.assetsApiService.searchGiphyImages(this.searchValue, this.limit, this.offset).toPromise();
    this.images = response.images;

    this.changeDetector.markForCheck();

    await this.loader.hide();
  }

  async getNext() {
    await this.loader.show();

    this.offset = this.offset + this.limit;
    const response: PagedImagesResult = await this.assetsApiService.searchGiphyImages(this.searchValue, this.limit, this.offset).toPromise();
    this.images = response.images;

    this.changeDetector.markForCheck();

    await this.loader.hide();
  }

  private storeState() {
    const state: IState = {
      search: this.searchForm.value.search,
      offset: this.offset
    };

    window.localStorage.setItem('AdminWidgetEditGiphySearchPage', JSON.stringify(state));
  }

  private async restoreState() {
    const restoredState = JSON.parse(window.localStorage.getItem('AdminWidgetEditGiphySearchPage')) as IState;
    if (restoredState) {
      // pagination
      this.offset = restoredState.offset;

      // trigger search
      this.searchForm.patchValue({
        search: restoredState.search
      }, { emitEvent: false });

      const searchResult = await this.assetsApiService.searchGiphyImages(restoredState.search, this.limit, this.offset).toPromise();
      this.images = searchResult.images;

      this.changeDetector.markForCheck();
    }
  }
}
