import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  LoadingController,
  PopoverController,
  ModalController
} from '@ionic/angular';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';
import URI from 'urijs';

import {
  YoutubeSearchResponse,
  YoutubeSearchResponseItem,
  YoutubeProvider,
  LoaderService
} from '@sonorus/core';

/**
 * Generated class for the WidgetEditorYoutubeSearchResultsPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'admin-widget-edit-youtube-search',
  templateUrl: 'admin-widget-edit-youtube-search.html'
})
export class AdminWidgetEditYoutubeSearchPage implements OnInit {
  ytResults: YoutubeSearchResponse;

  searchForm: FormGroup;

  constructor(
    private modalCtrl: ModalController,
    private loader: LoaderService,
    private youtubeProvider: YoutubeProvider,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      search: ''
    });

    this.searchForm
      .get('search')
      .valueChanges.pipe(
        debounceTime(1000),
        filter((v: string) => v && v.length >= 3)
      )
      .subscribe(async v => {
        if (this.detectIfYtUrl(v)) {
          const parsedResult = this.parseUnlistedYtUrl(v);
          if (parsedResult && parsedResult.videoId && parsedResult.videoId.length > 0) {
            this.modalCtrl.dismiss(parsedResult);
          }
        } else {
          await this.loader.show();
          this.ytResults = await this.youtubeProvider.searchVideos(v);
          await this.loader.hide();
        }
      });
  }

  selectVideo = (video: YoutubeSearchResponseItem) => {
    const result: YoutubeSearchModalResult = {
      videoId: video.id.videoId,
      thumbnailUrl: video.snippet.thumbnails.medium.url
    }
    this.modalCtrl.dismiss(result);
  };

  cancel() {
    this.modalCtrl.dismiss();
  }

  private detectIfYtUrl(val: string) {
    const result = URI.parse(val);
    if (result && result.hostname) {
      switch ((<string> result.hostname).toLowerCase()) {
        case 'www.youtube.com':
        case 'youtu.be':
          return true;
        default:
          return false;
      }
    } else {
      return false;
    }
  }

  private parseUnlistedYtUrl(url: string): YoutubeSearchModalResult {
    // https://www.youtube.com/watch?v=dFKEj-Pb4Ys
    // https://youtu.be/dFKEj-Pb4Ys
    // https://i.ytimg.com/vi_webp/dFKEj-Pb4Ys/maxresdefault.webp
    // https://i.ytimg.com/vi/dFKEj-Pb4Ys/mqdefault.jpg
    const createMediumThumbnailUrl = (id: string) => `https://i.ytimg.com/vi/${id}/mqdefault.jpg`;

    const result = URI.parse(url);
    let ytId: string;

    if (result && result.hostname) {
      switch ((<string> result.hostname).toLowerCase()) {
        case 'www.youtube.com':
          const queryParts = URI.parseQuery(`?${result.query}`);
          ytId = queryParts.v;
          return {
            videoId: ytId,
            thumbnailUrl: createMediumThumbnailUrl(ytId)
          };
          break;
        case 'youtu.be':
          ytId = result.path.substr(1);
          return {
            videoId: ytId,
            thumbnailUrl: createMediumThumbnailUrl(ytId)
          };
        default:
          return undefined;
      }
    } else {
      return undefined;
    }
  }
}

export interface YoutubeSearchModalResult {
  videoId: string,
  thumbnailUrl: string
}
