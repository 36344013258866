import { State, StateContext, Action, Selector } from '@ngxs/store';
import * as _ from 'lodash';

import {
  UserBookApiService,
  IUserBooks,
  BookDefinitionApiService,
  QuickChatDefinition,
  UserBook
} from '@sonorus/api';
import { UserBooks } from './userbooks.actions';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

export interface UserBooksStateModel extends IUserBooks {}

@State<UserBooksStateModel>({
  name: 'userBooks',
  defaults: {
    id: undefined,
    userId: undefined,
    books: []
  }
})
@Injectable()
export class UserBooksState {
  constructor(
    private userBookApi: UserBookApiService,
    private bookDefinitionApi: BookDefinitionApiService
  ) {}

  @Action(UserBooks.Load)
  loadUserBooks(
    ctx: StateContext<UserBooksStateModel>,
    action: UserBooks.Load
  ) {
    return this.userBookApi.getAllForUser(action.userId).pipe(
      tap(userBooks => {
        ctx.setState({
          ...userBooks
        });
      })
    );
  }

  @Action(UserBooks.Edit)
  async editUserBooks(
    ctx: StateContext<UserBooksStateModel>,
    action: UserBooks.Edit
  ) {
    const oldState = ctx.getState();

    let bookDefinition;
    if (!action.bookDefinition.id) {
      action.bookDefinition.product = false;
      action.bookDefinition.pages = [];
      action.bookDefinition.quickChat = new QuickChatDefinition();

      bookDefinition = await this.bookDefinitionApi
        .post(action.bookDefinition)
        .toPromise();
    } else {
      const ogBookDef = await this.bookDefinitionApi
        .get(action.bookDefinition.id)
        .toPromise();

      ogBookDef.voiceParams = action.bookDefinition.voiceParams;
      ogBookDef.title = action.bookDefinition.title;

      bookDefinition = await this.bookDefinitionApi
        .put(ogBookDef.id, ogBookDef)
        .toPromise();
    }

    const newState = _.cloneDeep(oldState) as UserBooksStateModel;

    let bookToUpdate = newState.books.find(
      userBook => userBook.bookId === bookDefinition.id
    );

    if (!bookToUpdate) {
      bookToUpdate = new UserBook({
        bookId: bookDefinition.id,
        pageTitles: [],
        title: bookDefinition.title
      });
      newState.books.push(bookToUpdate);
    } else {
      bookToUpdate.title = bookDefinition.title;
    }

    ctx.setState({
      ...newState
    });
  }
}
