
export namespace BookPlayer {
  export class Load {
    static readonly type = '[Book Player] load book player state';
    constructor(public bookId: string) {}
  }

  export class SetCurrentPage {
    static readonly type = '[Book Player] set current page';
    constructor(public pageId: string) {}
  }

  export class LoadPage {
    static readonly type = '[Book Player] load page';
    constructor(public bookId: string, public pageId: string) {}
  }

  export class Clear {
    static readonly type = '[Book Player] clear state';
    constructor() {}
  }
}
