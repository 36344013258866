<!--
  Generated template for the GridsterTestPage page.

  See http://ionicframework.com/docs/components/#navigation for more info on
  Ionic pages and navigation.
-->
<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>

    <ion-title>
      <!-- {{title}} -->
      <form [formGroup]="pageTitleForm">
        <ion-input placeholder="{{'PAGE_EDIT_PAGETITLE' | translate}}"
                   formControlName="title"></ion-input>
      </form>
    </ion-title>

    <ion-buttons slot="end">
      <ion-button (click)="openSettings($event)"
                  [title]="'PAGE_EDIT_STRUCTURE' | translate">
        <ion-icon slot="icon-only"
                  name="grid-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <!-- <ion-fab top right edge> -->
  <!--
    <ion-fab left
             top>
      <button ion-fab
              (click)="openSettings($event)">
        <ion-icon name="settings"></ion-icon>
      </button>
    </ion-fab>
  -->

  <gridster [options]="options">
    <gridster-item *ngFor="let item of dashboard?.toArray(); let i = index"
                   [item]="item">
      <!-- your content here -->
      <widget [item]="item"
              [fontCssClass]="fontCssClass"
              [editMode]="true"
              (edit)="editWidget(item, i)"
              (delete)="deleteWidget(item, i)"></widget>
    </gridster-item>
  </gridster>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button *ngIf="dirty"
                  color="success"
                  fill="solid"
                  [disabled]="saveInProgress"
                  (click)="save()">
        <ion-icon name="save"
                  slot="start"></ion-icon>
        {{'SAVE_BUTTON_TEXT' | translate}}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
