interface Array<T> {
  toDictionary(keySelector: (obj: T) => string | number): Dictionary<T>;
  toDictionaryKeyValue<V>(keySelector: (obj: T) => string | number, valueSelector: (obj: T) => V): Dictionary<V>;
  sortBy(keySelector: (obj: T) => string | number): Array<T>;
  unique(): Array<T>;
  uniqueBy(keySelector: (obj: T) => string | number): Array<T>;
}

interface Dictionary<T> {
  [key: string]: T;
}

if (!Array.prototype.hasOwnProperty('toDictionary')) {
  Array.prototype.toDictionary = function <T>(keySelector: (obj: T) => string | number): Dictionary<T> {
    const dictionary = {};

    this.forEach((val) => (dictionary[keySelector(val).toString()] = val));

    return dictionary;
  };
}

if (!Array.prototype.hasOwnProperty('toDictionaryKeyValue')) {
  Array.prototype.toDictionaryKeyValue = function <T, U>(
    keySelector: (obj: T) => string | number,
    valueSelector: (obj: T) => U
  ): Dictionary<T> {
    const dictionary = {};

    this.forEach((val) => (dictionary[keySelector(val).toString()] = valueSelector(val)));

    return dictionary;
  };
}

if (!Array.prototype.hasOwnProperty('sortBy')) {
  Array.prototype.sortBy = function <T>(keySelector: (obj: T) => string | number) {
    this.sort((a, b) => {
      const valueA = keySelector(a);
      const valueB = keySelector(b);

      return valueA < valueB ? -1 : valueA === valueB ? 0 : 1;
    });

    return this;
  };
}

if (!Array.prototype.hasOwnProperty('unique')) {
  Array.prototype.unique = function () {
    const a = this.concat();
    for (let i = 0; i < a.length; ++i) {
      for (let j = i + 1; j < a.length; ++j) {
        if (a[i] === a[j]) a.splice(j--, 1);
      }
    }

    return a;
  };
}

if (!Array.prototype.hasOwnProperty('uniqueBy')) {
  Array.prototype.uniqueBy = function <T>(keySelector: (obj: T) => string | number) {
    const a = this.concat();
    for (let i = 0; i < a.length; ++i) {
      for (let j = i + 1; j < a.length; ++j) {
        if (keySelector(a[i]) === keySelector(a[j])) a.splice(j--, 1);
      }
    }

    return a;
  };
}

interface MouseEvent {
  clickedInsideOfCssClass: (cssClass: string) => boolean;
}

if (!MouseEvent.prototype.hasOwnProperty('clickedInsideOfCssClass')) {
  MouseEvent.prototype.clickedInsideOfCssClass = function (cssClass: string) {
    const eventTargetList = this.composedPath();
    const clickedInsideElementContainingCssClass = eventTargetList.some((eventTarget) =>
      eventTarget instanceof Element ? (eventTarget as Element).classList.contains(cssClass) : false
    );

    return clickedInsideElementContainingCssClass;
  };
}
