import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { FontFamily, mapFontFamilyToCssClass } from '@sonorus/features';

@Component({
  selector: 'sonorus-font-picker',
  templateUrl: './font-picker.component.html',
  styleUrls: ['./font-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FontPickerComponent implements OnInit, OnDestroy, FontDialogParams {

  readonly availableFonts: { title: string, value: FontFamily }[] = [
    { title: 'Roboto', value: 'Roboto' },
    { title: 'Century Gothic', value: 'CenturyGothic' },
    { title: 'Courgette', value: 'Courgette' },
    { title: 'Source Code Pro', value: 'SourceCodePro' }
  ];

  fontForm: FormGroup;
  font: FontFamily;

  exampleCssClass: string;

  private sub: Subscription;

  constructor(
    private fb: FormBuilder,
    private modalCtrl: ModalController,
    private changeDetector: ChangeDetectorRef
  ) {
    this.fontForm = this.fb.group({
      font: 'Roboto'
    });

    this.sub = this.fontForm.get('font').valueChanges.subscribe(val => {
      this.exampleCssClass = mapFontFamilyToCssClass(val);
      this.changeDetector.markForCheck();
    });
  }

  ngOnInit() {
    if (this.font) {
      this.fontForm.patchValue({
        font: this.font
      });
    }
  }

  ngOnDestroy() {
    if (this.sub && !this.sub.closed) {
      this.sub.unsubscribe();
    }
  }

  save() {
    const result: FontDialogResult = {
      font: this.fontForm.value.font
    };

    this.modalCtrl.dismiss(result)
  }

  cancel() {
    this.modalCtrl.dismiss()
  }
}

export interface FontDialogParams {
  font: FontFamily;
}

export interface FontDialogResult {
  font: FontFamily
}