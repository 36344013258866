import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { IonicSelectableModule } from 'ionic-selectable';

import { WidgetButtonComponent } from './widget-button/widget-button';
import { WidgetComponent } from './widget/widget';
import { CulturePickerComponent } from './culture-picker/culture-picker.component';
import { CountryPickerComponent } from './country-picker/country-picker.component';
import { NgxsModule } from '@ngxs/store';
import { SonorusMenuHeaderComponent } from './sonorus-menu-header/sonorus-menu-header.component';
import { ProgressLoaderComponent } from './progress-loader/progress-loader.component';
// import { YoutubeVideoPlayerComponent } from './youtube-video-player/youtube-video-player';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    IonicSelectableModule,
    NgxsModule.forFeature([]),
    TranslateModule.forChild()
  ],
  declarations: [
    SonorusMenuHeaderComponent,
    WidgetComponent,
    WidgetButtonComponent,
    CulturePickerComponent,
    CountryPickerComponent,
    ProgressLoaderComponent
  ],
  entryComponents: [
    WidgetComponent,
    WidgetButtonComponent,
    CulturePickerComponent,
    CountryPickerComponent,
    ProgressLoaderComponent
  ],
  exports: [
    SonorusMenuHeaderComponent,
    WidgetComponent,
    WidgetButtonComponent,
    CulturePickerComponent,
    CountryPickerComponent,
    ProgressLoaderComponent
  ]
  // schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule {}
