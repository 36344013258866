import { OfflineBooks } from './offline-books.actions';
import { StateContext, Action, State, Selector } from '@ngxs/store';
import { StorageService } from '../_hybrid-wrappers/storage';
import { Injectable } from '@angular/core';

// https://capacitor.ionicframework.com/docs/apis/storage/
// https://capacitor.ionicframework.com/docs/
// https://capacitor.ionicframework.com/docs/getting-started/
export interface OfflineBooksStateModel {
  bookIds: string[];
}

@State<OfflineBooksStateModel>({
  name: 'offlineBooks',
  defaults: {
    bookIds: []
  }
})
@Injectable()
export class OfflineBooksState {
  @Selector()
  static bookIds(state: OfflineBooksStateModel) {
    return state.bookIds;
  }

  constructor(private storage: StorageService) {}

  @Action(OfflineBooks.Load)
  async loadOfflineBooks(
    ctx: StateContext<OfflineBooksStateModel>,
    action: OfflineBooks.Load
  ) {
    const booksRepo = this.storage.getBooksRepo();
    const offlineBooks = await booksRepo.getOfflineBooks();
    ctx.setState({
      bookIds: offlineBooks.map(b => b.bookId)
    });
  }

  @Action(OfflineBooks.Remove)
  async removeBook(
    ctx: StateContext<OfflineBooksStateModel>,
    action: OfflineBooks.Remove
  ) {
    const state = ctx.getState();

    const newBookIds = state.bookIds.filter(id => id !== action.bookId);
    const newState = {
      bookIds: [...newBookIds]
    };

    const booksRepo = this.storage.getBooksRepo();
    await booksRepo.remove(action.bookId);
    await booksRepo.deleteQuickChatById(action.bookId);
    await booksRepo.deleteAudioCuesById(action.bookId);
    await booksRepo.deleteScanningOptionbsById(action.bookId);

    const pagesRepo = this.storage.getPagesRepo();
    await pagesRepo.deleteBookPages(action.bookId);

    return ctx.setState(newState);
  }

  @Action(OfflineBooks.MarkAsOffline)
  async markAsOffline(
    ctx: StateContext<OfflineBooksStateModel>,
    action: OfflineBooks.MarkAsOffline
  ) {
    const state = ctx.getState();

    const booksRepo = this.storage.getBooksRepo();
    const book = await booksRepo.getById(action.bookId);

    if (book) {
      const newState = {
        bookIds: [...state.bookIds]
      };
      newState.bookIds.push(action.bookId);
      ctx.setState(newState);

      book.offline = true;
      await this.storage.getBooksRepo().add(book);
    }
  }

  @Action(OfflineBooks.CleanupBookStore)
  async cleanupBooksNotMarkedAsOffline(
    ctx: StateContext<OfflineBooksStateModel>,
    action: OfflineBooks.CleanupBookStore
  ) {
    const booksToDelete = await this.storage
      .getBooksRepo()
      .getNotOfflineBooks();
    for (const book of booksToDelete) {
      await this.storage.getPagesRepo().deleteBookPages(book.bookId);
    }
    await this.storage.getBooksRepo().deleteBooksNotOffline();
  }
}
