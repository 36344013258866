import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GridsterConfig } from 'angular-gridster2';
import { NavParams, PopoverController } from '@ionic/angular';

/**
 * Generated class for the GridsterTestSettingsPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'admin-page-settings',
  templateUrl: 'admin-page-settings.html'
})
export class AdminPageSettingsPage implements OnInit {
  settingsForm: FormGroup;
  gridConfig: GridsterConfig;

  constructor(
    private navParams: NavParams,
    private viewCtrl: PopoverController,
    private fb: FormBuilder
  ) {
  }

  ngOnInit() {
    if (this.navParams.data) {
      this.gridConfig = this.navParams.data.gridConfig;

      this.settingsForm = this.fb.group({
        columns: [0, Validators.compose([
          Validators.min(1),
          Validators.max(this.gridConfig.maxCols)])],
        rows: [0, Validators.compose([
          Validators.min(1),
          Validators.max(this.gridConfig.maxRows)])]
      });

      this.settingsForm.get('columns').valueChanges.subscribe((value) => {
        this.gridConfig.minCols = value;
        this.gridConfig.api.optionsChanged();
      });

      this.settingsForm.get('rows').valueChanges.subscribe((value) => {
        this.gridConfig.minRows = value;
        this.gridConfig.api.optionsChanged();
      });

      this.initializeSettingsForm();
    }
  }

  save() {
    this.viewCtrl.dismiss({
      columns: this.settingsForm.get('columns').value,
      rows: this.settingsForm.get('rows').value
    });
  }

  cancel() {
    this.viewCtrl.dismiss();
  }

  private initializeSettingsForm = () => {
    this.settingsForm.patchValue({
      columns: this.gridConfig.minCols,
      rows: this.gridConfig.minRows
    });
  };
}
