import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController, ModalController } from '@ionic/angular';
// import { Page } from '../../models/page';
import { BookService } from '@sonorus/core';
import { PageDefinition, PageDefinitionApiService } from '@sonorus/api';
import { Select } from '@ngxs/store';
import { BookDefinitionsState } from '@sonorus/state';
import { Observable } from 'rxjs';
// import { PageProvider } from "../../providers/page/page";

/**
 * Generated class for the AdminWidgetEditPageSearchPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-admin-widget-edit-page-search',
  templateUrl: 'admin-widget-edit-page-search.html'
})
export class AdminWidgetEditPageSearchPage implements OnInit {
  @Select(BookDefinitionsState.pageDefinitions)
  pages$: Observable<PageDefinition[]>;

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}

  selectPage = (page: PageDefinition) => {
    this.modalCtrl.dismiss(page);
  };

  cancel() {
    this.modalCtrl.dismiss();
  }
}
