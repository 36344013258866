<ion-card>
  <ion-item lines="none">
    <ion-icon name="person"
              slot="start"></ion-icon>
    <ion-label>{{entry?.author}}</ion-label>
    <ion-button *ngIf="!readonly"
                slot="end"
                (click)="onDeleteClick()">
      <ion-icon slot="icon-only"
                name="trash">
      </ion-icon>
    </ion-button>
    <ion-button *ngIf="!readonly"
                slot="end"
                (click)="onEditClick()">
      <ion-icon slot="icon-only"
                name="create">
      </ion-icon>
    </ion-button>
    <ion-button *ngIf="readonly && usedInBrowser"
                slot="end"
                (click)="downloadPdf.emit()">
      <ion-icon slot="icon-only"
                src="/assets/svg/fileformats/pdf-file.svg"></ion-icon>
    </ion-button>
    <ion-button *ngIf="readonly && usedInBrowser"
                slot="end"
                (click)="mail.emit()">
      <ion-icon slot="icon-only"
                name="mail"></ion-icon>
    </ion-button>
  </ion-item>

  <ion-item lines="none">
    <ion-icon name="time"
              slot="start"></ion-icon>
    <ion-label>{{entry?.createdOn | date: 'dd/MM/yyyy HH:mm'}}</ion-label>
  </ion-item>

  <ion-card-content>
    <p [innerHTML]="replaceNewlineWithBreaks(entry?.content)"></p>
  </ion-card-content>
</ion-card>
