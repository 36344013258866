import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  AppBootstrapService,
  PostAuthenticationRedirectService
} from '@sonorus/core';
import { Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@Component({
  selector: 'sonorus-login-callback',
  templateUrl: './login-callback.component.html',
  styleUrls: ['./login-callback.component.scss']
})
export class LoginCallbackComponent implements OnInit, OnDestroy {
  private sub: Subscription;

  constructor(
    private appBootstrapService: AppBootstrapService,
    private postAuthRedirect: PostAuthenticationRedirectService
  ) {
    this.sub = this.appBootstrapService.initialized$
      .pipe(
        filter(initialized => initialized === true),
        first()
      )
      .subscribe(async initialized => {
        if (initialized) {
          await this.postAuthRedirect.redirect();
        }
      });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
