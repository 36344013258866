<ion-header>
  <ion-toolbar>
    <ion-title>{{'DUPLICATE.PAGE.TITLE' | translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="duplicatePageForm">
    <ion-list padding>
      <ion-item>
        <ion-label position="stacked">{{'DUPLICATE.PAGE.NEWTITLE' | translate }}</ion-label>
        <ion-input placeholder="{{'DUPLICATE.PAGE.NEWTITLE' | translate}}"
                   formControlName="title"></ion-input>
      </ion-item>
    </ion-list>
  </form>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="primary">
      <ion-button color="primary"
                  [disabled]="!duplicatePageForm.valid"
                  (click)="duplicatePage()"
                  type="submit"
                  fill="solid">{{ 'CONFIRM_BUTTON_TEXT' | translate }}</ion-button>
      <ion-button color="danger"
                  (click)="cancel()"
                  type="button"
                  fill="solid">{{ 'CANCEL_BUTTON_TEXT' | translate }}</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
