<ion-header>
  <ion-toolbar>
    <ion-title>{{'BOOKS.ADMIN.WIDGETS.SCANNING_TITLE' | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

  <form [formGroup]="scanningFormGroup">
    <ion-list>
      <ion-item>
        <ion-label position="stacked">
          {{ 'BOOKS.ADMIN.WIDGETS.SCANNING_MODE' | translate }}
        </ion-label>
        <!-- <ion-icon name="cog"
                  slot="start"></ion-icon> -->
        <!-- <label position="floating">&nbsp;</label> -->
        <ion-select formControlName="scanMode"
                    interface="alert"
                    [okText]="'OK_BUTTON_TEXT' | translate"
                    [cancelText]="'CANCEL_BUTTON_TEXT' | translate">
          <ion-select-option [value]="SCANMODE1">{{'BOOKS.ADMIN.WIDGETS.SCANNING_MODE1' | translate}}
          </ion-select-option>
          <ion-select-option [value]="SCANMODE2">{{'BOOKS.ADMIN.WIDGETS.SCANNING_MODE2' | translate}}
          </ion-select-option>
          <ion-select-option [value]="SCANMODE3">{{'BOOKS.ADMIN.WIDGETS.SCANNING_MODE3' | translate}}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item>
        <ion-label>
          {{'BOOKS.ADMIN.WIDGETS.SCANNING_AUDIO' | translate}}
        </ion-label>
        <ion-toggle formControlName="audio"
                    color="primary"></ion-toggle>
        <ion-icon name="volume-high"
                  slot="end"></ion-icon>
      </ion-item>

      <ion-item>
        <ion-label>
          {{'BOOKS.ADMIN.WIDGETS.SCANNING_SPEED' | translate}}
        </ion-label>
        <ion-input type="number"
                   formControlName="speed"
                   inputmode="decimal"
                   min="0.5"
                   step="0.5"></ion-input>
        <ion-icon name="speedometer"
                  slot="end"></ion-icon>
      </ion-item>

      <ion-item-group *ngIf="rowSelectionEnabled">
        <ion-item-divider>
          <ion-label>
            {{ 'BOOKS.ADMIN.WIDGETS.SCANNING_ROW_SELECTION' | translate }}
          </ion-label>
        </ion-item-divider>
        <ion-item>
          <ion-label>{{ 'BOOKS.ADMIN.WIDGETS.BORDER_SIZE_PLACEHOLDER' | translate }}</ion-label>
          <ion-icon name="square-outline"
                    slot="end"></ion-icon>
          <ion-select formControlName="rowBorderSize"
                      interface="alert"
                      [okText]="'OK_BUTTON_TEXT' | translate"
                      [cancelText]="'CANCEL_BUTTON_TEXT' | translate"
                      [placeholder]="'BOOKS.ADMIN.WIDGETS.BORDER_SIZE_PLACEHOLDER' | translate">
            <ion-select-option [value]="BORDERSIZE2">
              {{'BOOKS.ADMIN.WIDGETS.BORDER_SIZE_2' | translate}}
            </ion-select-option>
            <ion-select-option [value]="BORDERSIZE3">
              {{'BOOKS.ADMIN.WIDGETS.BORDER_SIZE_3' | translate}}
            </ion-select-option>
          </ion-select>
        </ion-item>

        <ion-item button="true"
                  (click)="changeRowSelectColor()">
          <ion-label>
            {{ 'BOOKS.ADMIN.WIDGETS.SCANNING_COLOR' | translate}}
          </ion-label>
          <ion-icon name="color-palette"
                    slot="end"></ion-icon>
          &nbsp;&nbsp;&nbsp;
          <ion-button
                      [style]="sanitizer.bypassSecurityTrustStyle('--background: ' + rowSelectColor + '; --background-hover: ' + rowSelectColor + '; --box-shadow: 1px 1px 1px #AAAAAA;')">
            &nbsp;&nbsp;&nbsp;&nbsp;
          </ion-button>
        </ion-item>
      </ion-item-group>

      <ion-item-divider>
        <ion-label>
          {{ 'BOOKS.ADMIN.WIDGETS.SCANNING_WIDGET_SELECTION' | translate }}
        </ion-label>
      </ion-item-divider>
      <ion-item>
        <ion-label>
          {{ 'BOOKS.ADMIN.WIDGETS.BORDER_SIZE_PLACEHOLDER' | translate }}
        </ion-label>
        <ion-icon name="square-outline"
                  slot="end"></ion-icon>
        <ion-select formControlName="widgetBorderSize"
                    interface="alert"
                    [okText]="'OK_BUTTON_TEXT' | translate"
                    [cancelText]="'CANCEL_BUTTON_TEXT' | translate"
                    [placeholder]="'BOOKS.ADMIN.WIDGETS.BORDER_SIZE_PLACEHOLDER' | translate">
          <ion-select-option [value]="BORDERSIZE2">
            {{'BOOKS.ADMIN.WIDGETS.BORDER_SIZE_2' | translate}}
          </ion-select-option>
          <ion-select-option [value]="BORDERSIZE3">
            {{'BOOKS.ADMIN.WIDGETS.BORDER_SIZE_3' | translate}}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item button="true"
                (click)="changeWidgetSelectColor()">
        <ion-label>
          {{ 'BOOKS.ADMIN.WIDGETS.SCANNING_COLOR' | translate}}
        </ion-label>
        <ion-icon name="color-palette"
                  slot="end"></ion-icon>
        &nbsp;&nbsp;&nbsp;
        <ion-button
                    [style]="sanitizer.bypassSecurityTrustStyle('--background: ' + widgetSelectColor + '; --background-hover: ' + widgetSelectColor + '; --box-shadow: 1px 1px 1px #AAAAAA;')">
          &nbsp;&nbsp;&nbsp;&nbsp;
        </ion-button>
      </ion-item>
    </ion-list>
  </form>
  <!--
  {{scanningFormGroup.value | json}} -->
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="primary">
      <ion-button color="primary"
                  [disabled]="!scanningFormGroup.valid"
                  (click)="save()"
                  fill="solid">{{ 'SAVE_BUTTON_TEXT' | translate }}</ion-button>
      <ion-button color="danger"
                  (click)="cancel()"
                  fill="solid">
        {{ 'CANCEL_BUTTON_TEXT' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
