<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>
      {{'BOOKS.ADMIN.LOGBOOK.TITLE' | translate}} {{bookTitle$ | async}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onAddClicked()">
        <ion-icon slot="icon-only"
                  name="add"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-text *ngIf="isInitialized && isEmpty">
    {{ 'BOOKS.ADMIN.LOGBOOK.EMPTY' | translate }}
  </ion-text>

  <ion-grid *ngIf="isInitialized && !isEmpty"
            class="container">
    <ion-row *ngFor="let entry of logbookEntries; trackBy: trackByEntryId">
      <sonorus-logbook-entry-row [entry]="entry"
                                 (edit)="onEditClicked(entry)"
                                 (delete)="onDeleteClicked(entry.id)">
      </sonorus-logbook-entry-row>
    </ion-row>
  </ion-grid>

  <ion-fab vertical="bottom"
           horizontal="end"
           slot="fixed">
    <ion-fab-button (click)="onAddClicked()">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>

</ion-content>
