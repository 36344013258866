import { Component, ElementRef, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DeviceDetectorService, LoaderService } from '@sonorus/core';
import { timer, Subscription } from 'rxjs';

/**
 * Generated class for the Html5CameraPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-html5-camera',
  templateUrl: 'html5-camera.html'
})
export class Html5CameraPage {
  public isSmartphone: boolean;
  @ViewChild('myCamera', { static: true })
  video: ElementRef<HTMLVideoElement>;
  @ViewChild('myImage', { static: true })
  img: ElementRef<HTMLImageElement>;
  @ViewChild('myImage2', { static: true })
  imgSmartphoneCamera: ElementRef<HTMLImageElement>;
  @ViewChild('myCanvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('smartphoneCamera', { static: true })
  smartphoneCamera: ElementRef;

  public base64Image: string;
  public webcamCount: number;
  private currentDeviceId = '';
  public countdown = 5;
  private videoDeviceIds: string[];
  private webcamStream: MediaStream;
  private timerSub: Subscription;

  constructor(
    private modalCtrl: ModalController,
    private deviceDetector: DeviceDetectorService,
    private loader: LoaderService
  ) {
    this.isSmartphone = this.deviceDetector.isMobile();
  }

  // https://davidwalsh.name/browser-camera
  // https://www.html5rocks.com/en/tutorials/getusermedia/intro/

  ionViewWillEnter() {
    if (!this.isSmartphone) {
      this.loader
        .show()
        .then(async () => {
          // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
          const preferredWebcamStream = await navigator.mediaDevices.getUserMedia(
            {
              video: {
                width: { ideal: 1280 },
                height: { ideal: 1024 }
              }
            }
          );

          this.assignStreamToUI(preferredWebcamStream);
          this.currentDeviceId = preferredWebcamStream
            .getVideoTracks()[0]
            .getSettings().deviceId;

          this.videoDeviceIds = (await navigator.mediaDevices.enumerateDevices())
            .filter(device => device.kind === 'videoinput')
            .map(device => device.deviceId);
          this.webcamCount = this.videoDeviceIds.length;

          await this.loader.hide();
        })
        .catch(async err => {
          console.error('Error initializing video from camera', err);
          await this.loader.hide();
        });
    }
  }

  ionViewWillLeave() {
    if (this.webcamStream) {
      this.cleanupStream(this.webcamStream);
    }
    this.resetTimer();
  }

  reset = () => {
    const videoElement = this.video.nativeElement;
    const imageElement = this.img.nativeElement;

    this.base64Image = undefined;
    imageElement.src = undefined;
    imageElement.hidden = true;

    videoElement.hidden = undefined;
  };

  takeTimerPicture = () => {
    this.resetTimer();

    this.timerSub = timer(1000, 1000).subscribe(number => {
      const elapsedSeconds = number + 1;
      this.countdown = 5 - elapsedSeconds;
      if (elapsedSeconds === 5) {
        this.takePicture();
      }
    });
  };

  takePicture = () => {
    this.resetTimer();
    const canvasElement = this.canvas.nativeElement;
    const videoElement = this.video.nativeElement;
    const imageElement = this.img.nativeElement;

    canvasElement.width = videoElement.videoWidth;
    canvasElement.height = videoElement.videoHeight;
    canvasElement.getContext('2d').drawImage(videoElement, 0, 0);

    // Other browsers will fall back to image/png
    this.base64Image = canvasElement.toDataURL('image/jpeg');

    imageElement.src = this.base64Image;
    imageElement.hidden = false;

    videoElement.hidden = true;
  };

  approvePicture = () => {
    this.modalCtrl.dismiss(this.base64Image);
  };

  private resetTimer() {
    if (this.timerSub && !this.timerSub.closed) {
      this.timerSub.unsubscribe();
      this.countdown = 5;
    }
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  smartphonePictureTaken = $event => {
    const file: File = $event.target.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = e => {
      this.base64Image = myReader.result as string;

      (this.imgSmartphoneCamera
        .nativeElement as HTMLImageElement).src = this.base64Image;
    };
    myReader.readAsDataURL(file);
  };

  async onSwitchCamera() {
    const nextDeviceId = this.getNextDeviceId(this.currentDeviceId);
    let skip1DeviceId, skip2DeviceId;

    try {
      await this.switchCameraToDevice(nextDeviceId);
    } catch (err) {
      try {
        skip1DeviceId = this.getNextDeviceId(nextDeviceId);
        await this.switchCameraToDevice(skip1DeviceId);
      } catch (err) {
        skip2DeviceId = this.getNextDeviceId(skip1DeviceId);
        await this.switchCameraToDevice(skip2DeviceId);
      }
    }
  }

  private switchCameraToDevice = async deviceId => {
    const newStream = await navigator.mediaDevices.getUserMedia({
      video: {
        deviceId: deviceId,
        width: { ideal: 1280 },
        height: { ideal: 1024 }
      }
    });

    this.cleanupStream(this.webcamStream);
    this.assignStreamToUI(newStream);

    this.currentDeviceId = deviceId;
  };

  private getNextDeviceId(deviceId: string) {
    const currentIndex = this.videoDeviceIds.indexOf(deviceId);
    const nextIndex =
      currentIndex === this.videoDeviceIds.length - 1 ? 0 : currentIndex + 1;

    return this.videoDeviceIds[nextIndex];
  }

  private assignStreamToUI(stream: MediaStream) {
    this.video.nativeElement.srcObject = stream;
    this.webcamStream = stream;
  }

  private cleanupStream(stream: MediaStream) {
    const tracks = stream.getTracks();
    if (tracks && tracks.length > 0) {
      tracks.forEach(track => track.stop());
    }
  }
}
