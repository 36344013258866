import { NgModule, APP_INITIALIZER } from '@angular/core';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { NgxsModule, Store } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import {
  UserState,
  LoginState,
  UserBooksState,
  BookDefinitionsState,
  OfflineBooksState,
  BookPlayerState,
  appInitializerFactory,
  LogbookState,
  BookTemplatesState
} from '@sonorus/state';

// import { GridsterModule } from 'angular-gridster2';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {
  AssetManagerProvider,
  YoutubeProvider,
  SettingsProvider,
  BookService,
  AuthenticationProvider,
  AuthenticationModule
} from '@sonorus/core';

import { HomePageModule } from './home/home.module';
import { environment } from './../environments/environment';

import { API_BASE_URL_SONORUS, ApiModule } from '@sonorus/api';
import { UISharedModule } from '@sonorus/features';
import { BookEditorModule } from '@sonorus/book-editor';
import { CORE_CONFIGURATION } from '@sonorus/core';
import { LoginCallbackComponent } from './login-callback/login-callback.component';
import { InvalidSubscriptionComponent } from './invalid-subscription/invalid-subscription.component';
import { HttpResponseStatusCodeInterceptor } from './_interceptors/http-response-status-code.interceptor';

const NGXS_MODULES = [
  NgxsModule.forRoot(
    [
      UserState,
      LoginState,
      UserBooksState,
      BookDefinitionsState,
      BookTemplatesState,
      BookPlayerState,
      LogbookState,
      OfflineBooksState
    ],
    {
      developmentMode: !environment.production
    }
  ),
  NgxsStoragePluginModule,
  NgxsRouterPluginModule.forRoot(),
  NgxsReduxDevtoolsPluginModule.forRoot({
    name: 'SONORUS NGXS',
    disabled: environment.production
  }),
  NgxsLoggerPluginModule.forRoot({ disabled: environment.production })
];

const EXTERNAL_MODULES = [
  ...NGXS_MODULES,
  IonicModule.forRoot({
    mode: 'md',
    swipeBackEnabled: false
  }),
  IonicStorageModule.forRoot(),
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: createTranslateLoader,
      deps: [HttpClient]
    }
  })
];

const CORE_MODULES = [AuthenticationModule];
const CORE_PROVIDERS = [
  {
    provide: CORE_CONFIGURATION,
    useValue: environment
  },
  AuthenticationProvider,
  SettingsProvider,
  AssetManagerProvider,
  YoutubeProvider,
  BookService
];

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './../assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginCallbackComponent,
    InvalidSubscriptionComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ...EXTERNAL_MODULES,
    ...CORE_MODULES,
    ApiModule.forRoot(),
    HomePageModule,
    BookEditorModule,
    UISharedModule,
    AppRoutingModule
  ],
  providers: [
    {
      provide: API_BASE_URL_SONORUS,
      useValue: environment.api.url
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpResponseStatusCodeInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [Store],
      multi: true
    },
    ...CORE_PROVIDERS,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
