import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { TranslateService } from '@ngx-translate/core';
import {
  AlertController,
  LoadingController,
  ModalController
} from '@ionic/angular';
import { LoaderService, DeviceDetectorService } from '@sonorus/core';
import {
  BookDefinition,
  PageDefinition,
  BookPdfApiService,
  PageDefinitionApiService,
  BookStyleDefinition
} from '@sonorus/api';
import { debounceTime } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import {
  AddPageDialogComponent,
  IAddPageFormValue
} from './components/add-page-dialog/add-page-dialog.component';
import { OverlayEventDetail } from '@ionic/core';
import { Store } from '@ngxs/store';
import {
  BookDefinitions,
  BookDefinitionsState,
  UserBooks,
  UserState
} from '@sonorus/state';
import { Subscription } from 'rxjs';
import { AdminBookVoicePage } from '../admin-book-voice/admin-book-voice';
import * as _ from 'lodash';
import { saveAs } from 'file-saver';
import { DuplicatePageDialogComponent, DuplicatePageDialogParams, DuplicatePageDialogResult } from './components/duplicate-page-dialog/duplicate-page-dialog.component';
import { FontDialogParams, FontDialogResult, FontPickerComponent } from './components/font-picker/font-picker.component';
import { ReorderPagesModalComponent, ReorderPagesModalProps, ReorderPagesModalResult } from './components/reorder-pages-modal/reorder-pages-modal.component';

/**
 * Generated class for the AdminBookPagesPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-admin-book-pages',
  templateUrl: 'admin-book-pages.html'
})
export class AdminBookPagesPage implements OnInit, OnDestroy {
  bookId: string;
  productId: string;

  book: BookDefinition;
  startPageId: string;
  titleForm: FormGroup;
  startPageForm: FormGroup;

  public usedInBrowser: boolean;

  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private loader: LoaderService,
    private bookPdfApi: BookPdfApiService,
    private pageDefinitionApi: PageDefinitionApiService,
    private deviceDetector: DeviceDetectorService,
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef
  ) {
    this.usedInBrowser = this.deviceDetector.isWeb();
  }

  // ionViewWillEnter() {
  //   console.log('admin-book-pages - ionViewWillEnter');
  // }

  // ionViewDidEnter() {
  //   console.log('admin-book-pages - ionViewDidEnter');
  // }

  // ionViewWillLeave() {
  //   console.log('admin-book-pages - ionViewWillLeave');
  // }

  // ionViewDidLeave() {
  //   console.log('admin-book-pages - ionViewDidLeave');
  // }

  ngOnInit() {
    console.log('ngOnInit');

    this.setupForms();

    this.route.params.subscribe(async params => {
      this.productId = params.productId;
      this.bookId = params.bookId;

      await this.loader.show();

      await this.store
        .dispatch(new BookDefinitions.Load(this.bookId))
        .toPromise();

      await this.loader.hide();
    });

    const sub = this.store
      .select(BookDefinitionsState)
      .subscribe(async bookState => {
        if (!bookState) {
          return;
        }

        if (!bookState.bookDefinition) {
          return;
        }

        await this.loader.show();

        const book = _.cloneDeep(bookState.bookDefinition);

        this.book = book;
        this.startPageId = book.startPageId;
        this.initializeForms(book);

        this.changeDetector.markForCheck();
        await this.loader.hide();
      });
    this.subscriptions.push(sub);
  }

  ngOnDestroy() {
    this.store.dispatch(new BookDefinitions.Clear(this.bookId));

    if (this.subscriptions) {
      for (const sub of this.subscriptions) {
        if (!sub.closed) {
          sub.unsubscribe();
        }
      }
    }
  }

  addPage = async () => {
    const bookDefinition = this.store.selectSnapshot(
      BookDefinitionsState.bookDefinition
    );

    const dialogProps: IAddPageFormValue = {
      title: '',
      startPage:
        !bookDefinition.pages ||
        bookDefinition.pages.length === 0 ||
        !bookDefinition.startPageId
    };
    const modal = await this.modalCtrl.create({
      component: AddPageDialogComponent,
      componentProps: { ...dialogProps },
      backdropDismiss: false
    });

    modal
      .onDidDismiss()
      .then(async (result: OverlayEventDetail<IAddPageFormValue>) => {
        if (!result || !result.data) {
          return;
        }

        const newPage: PageDefinition = new PageDefinition();
        newPage.title = result.data.title;
        newPage.cols = 4;
        newPage.rows = 4;

        await this.loader.show();

        this.store
          .dispatch(
            new BookDefinitions.AddPage(
              this.bookId,
              newPage,
              result.data.startPage
            )
          )
          .subscribe(async () => {
            await this.loader.hide();
          });
      });

    await modal.present();
  };

  editPage = ($event, page: PageDefinition) => {
    console.log($event)
    this.router.navigate([page.id], { relativeTo: this.route });
  };

  onReorderPages = async () => {
    const modalProps: ReorderPagesModalProps = {
      pages: this.book.pages.map(p => { return { id: p.id, title: p.title } })
    };

    const modal = await this.modalCtrl.create({
      component: ReorderPagesModalComponent,
      componentProps: modalProps
    });

    await modal.present();

    const result: OverlayEventDetail<ReorderPagesModalResult> = await modal.onDidDismiss();

    if (result.data) {
      this.store.dispatch(new BookDefinitions.ChangePageOrder(this.book.id, result.data.pages.map(p => p.id)));
    }
  };

  onDuplicatePage = async (page: PageDefinition) => {
    const modalProps: DuplicatePageDialogParams = {
    };

    const modal = await this.modalCtrl.create({
      component: DuplicatePageDialogComponent,
      componentProps: { ...modalProps },
      backdropDismiss: false
    });

    modal
      .onDidDismiss()
      .then(async (result: OverlayEventDetail<DuplicatePageDialogResult>) => {
        if (result && result.data) {
          const translation = await this.translate.get('DUPLICATE.PAGE.INPROGRESS').toPromise();
          const alert = await this.alertCtrl.create({
            header: '',
            message: translation,
            buttons: [],
            backdropDismiss: false,
            keyboardClose: false
          });

          await alert.present();

          await this.pageDefinitionApi.duplicatePage(this.bookId, page.id, result.data.title).toPromise();

          await this.store.dispatch(new BookDefinitions.Load(this.bookId));

          await alert.dismiss();
        }
      }
      );

    await modal.present();
  }

  deletePage = (page: PageDefinition) => {
    this.translate
      .get([
        'PAGE_DELETE_TITLE',
        'PAGE_DELETE_MESSAGE',
        'PAGE_DELETE_OK',
        'PAGE_DELETE_CANCEL'
      ])
      .subscribe(texts => {
        // vertalingen + delete, daarna pagina admin maken
        this.alertCtrl
          .create({
            header: texts.PAGE_DELETE_TITLE,
            message: texts.PAGE_DELETE_MESSAGE,
            buttons: [
              {
                text: texts.PAGE_DELETE_CANCEL,
                role: 'cancel',
                handler: () => {
                  console.log('Cancel clicked');
                }
              },
              {
                text: texts.PAGE_DELETE_OK,
                handler: () => {
                  this.store.dispatch(
                    new BookDefinitions.DeletePage(this.bookId, page.id)
                  );
                  console.log('Delete clicked');
                }
              }
            ]
          })
          .then(alert => alert.present())
          .then(() => { });
      });
  };

  onStartPageChanged(event: any) {
    const startPageId = event.detail.value;
    if (startPageId !== this.book.startPageId) {
      this.startPageForm.patchValue(
        {
          startPage: startPageId
        },
        {
          emitEvent: true
        }
      );
    }
  }

  goToLogbook() {
    this.router.navigate(['../logbook'], {
      relativeTo: this.route
    });
  }

  async showFontDialog() {
    this.ensureBookInstantiated();

    const modalHandle = await this.modalCtrl.create({
      component: FontPickerComponent,
      componentProps: {
        font: this.book.style ? this.book.style.fontFamily : ''
      } as FontDialogParams, backdropDismiss: false
    });

    await modalHandle.present();

    modalHandle.onDidDismiss().then(async result => {
      if (result && result.data) {
        this.loader.show();
        const fontSettings = result.data as FontDialogResult;
        if (!this.book.style) {
          this.book.style = new BookStyleDefinition();
        }

        this.book.style.fontFamily = fontSettings.font;
        await this.store.dispatch(new BookDefinitions.EditBookStyle(
          this.bookId,
          this.book.style
        )).toPromise();
        this.loader.hide();
      }
    });
  }

  showVoiceSettingsDialog() {
    this.ensureBookInstantiated();

    this.modalCtrl
      .create({
        component: AdminBookVoicePage,
        componentProps: {
          book: _.cloneDeep(this.book)
        },
        backdropDismiss: false
      })
      .then(modal => {
        modal.present();

        modal.onDidDismiss().then(async result => {
          if (result.data) {
            await this.loader.show();
            await this.store
              .dispatch(new UserBooks.Edit(result.data))
              .toPromise();
            this.book.voiceParams = result.data.voiceParams;
            await this.loader.hide();
          }
        });
      });
  }

  private ensureBookInstantiated() {
    if (!this.book) {
      this.book = new BookDefinition({
        product: false
      });
    }
  }

  async generateAndDownloadPdf() {
    if (!this.usedInBrowser) {
      console.error('Not supported in app');
      return;
    }

    this.loader.show();

    const userIso2 = this.store.selectSnapshot(UserState.language);
    const pdfFileResult = await this.bookPdfApi
      .get(this.bookId, userIso2)
      .toPromise();

    saveAs(pdfFileResult.data, `${encodeURI(this.book.title)}.pdf`);

    this.loader.hide();
  }

  drop(event: CdkDragDrop<string[]>) {

    moveItemInArray(this.book.pages, event.previousIndex, event.currentIndex);
  }

  private setupForms() {
    this.titleForm = this.fb.group({
      title: ['', Validators.required]
    });
    this.startPageForm = this.fb.group({
      startPage: [undefined, Validators.required]
    });

    this.titleForm
      .get('title')
      .valueChanges.pipe(debounceTime(1000))
      .subscribe(value =>
        this.store.dispatch(
          new BookDefinitions.EditBookTitle(this.bookId, value)
        )
      );
    this.startPageForm
      .get('startPage')
      .valueChanges.pipe(debounceTime(1000))
      .subscribe(value =>
        this.store.dispatch(
          new BookDefinitions.SetStarterPage(this.bookId, value)
        )
      );
  }

  private initializeForms(book: BookDefinition) {
    this.titleForm.patchValue(
      {
        title: book.title
      },
      {
        emitEvent: false
      }
    );
    this.startPageForm.patchValue(
      {
        startPage: book.startPageId
      },
      {
        emitEvent: false
      }
    );
  }
}
