<!-- Generated template for the WidgetButtonComponent component -->
<div (click)="executeActionClick()"
     (dblclick)="editClick()"
     class="widget-button-container"
     [class.preview-widget]="previewMode"
     [class.quickchat-widget]="quickChatMode"
     [class.border-small]="model.borderSize === 'small'"
     [class.border-large]="model.borderSize === 'large'"
     [style.border-color]="model.borderColor"
     [style.background-color]="model.backgroundColor"
     [style.cursor]="editMode ? 'move' : 'auto'">
  <div class="widget-title"
       [class.text-small]="model.textSize === 'small'"
       [class.text-regular]="model.textSize === 'regular' || model.textSize === '' || !model.textSize"
       [class.text-big]="model.textSize === 'big'"
       [style.color]="model.textColor"
       [style.order]="titleOrder"
       [hidden]="model.displayTextHidden">
    {{ model.displayText }}
  </div>
  <img class="widget-image"
       *ngIf="model.pictureBase64"
       [style.order]="imageOrder"
       [src]="model.pictureBase64 || ''" />
  <div class="action-buttons"
       *ngIf="editMode">
    <ion-fab-button size="small"
                    [title]="'WIDGET_EDIT' | translate"
                    (click)="editClick()">
      <ion-icon name="open"></ion-icon>
    </ion-fab-button>
    <ion-fab-button size="small"
                    [title]="'WIDGET_DELETE' | translate"
                    color="danger"
                    (click)="deleteClick()">
      <ion-icon name="trash"></ion-icon>
    </ion-fab-button>
  </div>
</div>
