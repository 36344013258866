import { Injectable } from '@angular/core';
import { Observable, Subject, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppBootstrapService {
  initialized$: Observable<boolean>;
  userLoaded$: Observable<boolean>;

  private initializedSubject: Subject<boolean>;
  private userDataLoadedSubject: Subject<boolean>;

  constructor() {
    this.initializedSubject = new ReplaySubject<boolean>(1);
    this.initialized$ = this.initializedSubject.asObservable();

    this.userDataLoadedSubject = new ReplaySubject<boolean>(1);
    this.userLoaded$ = this.userDataLoadedSubject.asObservable();
  }

  markAsInitialized() {
    this.initializedSubject.next(true);
  }

  markUserDataLoaded() {
    this.userDataLoadedSubject.next(true);
  }
}
