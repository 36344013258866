import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ModalController } from '@ionic/angular';

export interface ReorderPagesModalProps {
  pages: { id: string, title: string }[]
}

export interface ReorderPagesModalResult {
  pages: { id: string, title: string }[]
}

@Component({
  selector: 'sonorus-reorder-pages-modal',
  templateUrl: './reorder-pages-modal.component.html',
  styleUrls: ['./reorder-pages-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReorderPagesModalComponent implements OnInit, ReorderPagesModalProps {

  pages: { id: string; title: string; }[];

  constructor(private modalCtrl: ModalController) { }

  ngOnInit(): void {
  }

  drop(event: CdkDragDrop<{ id: string; title: string; }[]>) {
    moveItemInArray(this.pages, event.previousIndex, event.currentIndex);
  }

  save() {
    const response: ReorderPagesModalResult = {
      pages: this.pages
    };

    this.modalCtrl.dismiss(response);
  }

  cancel() {
    this.modalCtrl.dismiss();
  }
}
