import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { first, filter } from 'rxjs/operators';
import { Select, Store, Actions, ofActionSuccessful } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { Platform, Config, MenuController } from '@ionic/angular';

// import '@byteowls/capacitor-oauth2';
import { OAuth2Client } from '@byteowls/capacitor-oauth2';
import {
  Plugins,
  StatusBarStyle,
  AppState,
  registerWebPlugin,
  AppRestoredResult
} from '@capacitor/core';

import { HomePage } from './home/home.page';
import {
  AuthenticationProvider,
  AppBootstrapService,
  DeviceDetectorService,
  CultureModel
} from '@sonorus/core';
import {
  UserState,
  UserStateModel,
  ChangeCulture,
  ClearLoginState,
  LoadUserById,
  LoadUserByAuth0Id,
  BookTemplatesActions
} from '@sonorus/state';
import {
  Router,
  NavigationStart,
  Event as NavigationEvent
} from '@angular/router';
import { SubscriptionLevel, SubscriptionStatus } from '@sonorus/api';

const { SplashScreen, StatusBar } = Plugins;
const { App, Toast } = Plugins;

@Component({
  selector: 'sonorus-root',
  styles: [`
    .bottom-list {
      position: fixed;
      bottom: 0;
      width: 100%;
    }
  `],
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {
  @Select(UserState.culture)
  culture$: Observable<string>;
  @Select(state => state.user)
  user$: Observable<UserStateModel>;
  @Select(UserState.subscriptionLevel)
  subscriptionLevel$: Observable<SubscriptionLevel>;
  @Select(UserState.subscriptionStatus)
  subscriptionStatus$: Observable<SubscriptionStatus>;

  rootPage: any = HomePage;

  pages: Array<{ title: string; component: any }> = [];

  unauthPages;
  authPages;

  authenticated: boolean;

  private isStatusBarLight = true;

  constructor(
    public auth: AuthenticationProvider,
    private config: Config,
    private platform: Platform,
    private translate: TranslateService,
    private menuCtrl: MenuController,
    private appBootstrapService: AppBootstrapService,
    private deviceDetector: DeviceDetectorService,
    private router: Router,
    private store: Store,
    private changeDetector: ChangeDetectorRef,
    private actions$: Actions
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(() => {
        this.menuCtrl.close('mainMenu');
      });

    // Listen for serious plugin errors
    (App as any).addListener('pluginError', async (info: any, err: any) => {
      console.error('There was a serious error with a plugin', err, info);
      await this.showToast('plugin', info);
    });

    App.addListener('appStateChange', async (state: AppState) => {
      // console.log('appStateChange', state);

      /*TODO: this crashes the plugin after logging in, probably because it calls logout or something
      find a more graceful solution*/

      // const amIAuth = this.auth.isAuthenticated();
      // await this.showToast('appStateChange - logged in - ' + amIAuth, state);
      if (state.isActive) {
        // switched back to app?
        // check if token still valid?
        // if (!amIAuth) {
        //   this.auth.logout();
        // }
      }
    });

    // App.addListener('appUrlOpen', async (state: AppUrlOpen) => {
    //   console.log('appUrlOpen', state);
    //   await this.showToast('appUrlOpen', state);
    // });

    // App.addListener('backButton', async (state: AppUrlOpen) => {
    //   console.log('backButton', state);
    //   await this.showToast('backButton', state);

    //   window.history.back();
    // });

    App.addListener('appRestoredResult', async (data: AppRestoredResult) => {
      console.log('Restored state:', data);
      await this.showToast('appRestoredResult', data);
    });

    if (window && window.onerror) {
      window.onerror = (message, url, linenumber) => {
        this.showToast(
          'global error caught - url:' + url + ' - line:' + linenumber,
          message
        );
        console.error(
          `GLOBAL JS ERROR - url:${url} - line:${linenumber}`,
          message
        );
        return true;
      };
    }

    this.authPages = [
      { title: 'HOME.MENU_TITLE', url: '/', icon: 'home' },
      // { title: 'MENU_MY_PROFILE', url: '/user-info', icon: 'person' },
      { title: 'MENU_MY_BOOKS', url: '/books', icon: 'library' },
      {
        title: 'MENU_ADMIN_MY_BOOKS',
        url: '/admin/books',
        icon: 'create'
      },
      {
        title: 'MENU_ADMIN_MY_PICTOSETS',
        url: '/my-pictosets',
        icon: 'images'
      }
    ];

    this.initializeTranslations();
  }

  async ngOnInit() {
    registerWebPlugin(OAuth2Client);

    this.actions$
      .pipe(ofActionSuccessful(LoadUserById))
      .subscribe(() => this.onUserLoaded());
    this.actions$
      .pipe(ofActionSuccessful(LoadUserByAuth0Id))
      .subscribe(() => this.onUserLoaded());

    await this.initializeApp();

    await this.auth.handleAuthentication();

    this.auth.authenticated$.subscribe(authenticated => {
      console.log('app startup - authenticated - ', authenticated);
      if (this.authenticated && !authenticated) {
        this.store.dispatch(new ClearLoginState());
      }
      this.authenticated = authenticated;
      if (authenticated) {
        this.store.dispatch(new LoadUserByAuth0Id(this.auth.getAuth0Id()));
      } else {
        this.appBootstrapService.markAsInitialized();
        this.appBootstrapService.markUserDataLoaded();
      }

      this.changeDetector.markForCheck();
    });
  }

  async initializeApp() {
    await this.platform.ready();
    // await this.auth.handleAuthentication();
    if (this.deviceDetector.isApp()) {
      StatusBar.setStyle({
        style: this.isStatusBarLight
          ? StatusBarStyle.Dark
          : StatusBarStyle.Light
      });
      SplashScreen.hide();
    }
  }

  private initializeTranslations() {
    this.translate.setDefaultLang('en');

    // this.translate.onLangChange.subscribe(() => {
    //   this.translate.get(['BACK_BUTTON_TEXT']).subscribe(values => {
    //     // this.config.set('backButtonText', values.BACK_BUTTON_TEXT);
    //   });
    // });

    this.culture$.subscribe(culture => {
      if (culture) {
        const languageCode = culture.split('-')[0];
        this.translate.use(languageCode);
        if (window && localStorage) {
          localStorage.setItem('culture', culture);
          localStorage.setItem('language', languageCode);
        }
      }
    });

    if (window && localStorage) {
      this.translate.use(localStorage.getItem('language') || 'en'); // Set your language here
    }
  }

  onCultureChanged(culture: CultureModel) {
    this.user$.pipe(first()).subscribe(user => {
      this.store.dispatch(
        new ChangeCulture(user ? user.id : undefined, culture.isoCode)
      );
    });
  }

  onLogoutClicked() {
    this.store.dispatch(new ClearLoginState());
    this.auth.logout();
    this.router.navigate(['/']);
  }

  private onUserLoaded() {
    this.appBootstrapService.markUserDataLoaded();
    this.appBootstrapService.markAsInitialized();

    this.store.dispatch(new BookTemplatesActions.LoadIntoState());
    // TODO: Subscription
    // console.log('user loaded');
    // this.subscriptionLevel$
    //   .pipe(withLatestFrom(this.subscriptionStatus$))
    //   .subscribe(subscriptionInfo => {
    //     const [level, status] = subscriptionInfo;

    //     if (
    //       level === SubscriptionLevel.Regular ||
    //       level === SubscriptionLevel.Professional
    //     ) {
    //       if (
    //         status === SubscriptionStatus.Active ||
    //         status === SubscriptionStatus.Trial
    //       ) {
    //         // console.info('Can use application', status, level);
    //       } else {
    //         console.warn('Cannot use application', status, level);
    //         this.router.navigate(['invalid-subscription']);
    //       }
    //     } else {
    //       console.warn('Cannot use application', status, level);
    //       this.router.navigate(['invalid-subscription']);
    //     }
    //   });
  }

  // App.addListener('appStateChange', (state: AppState) => {
  //   // state.isActive contains the active state
  //   console.log('App state changed. Is active?', state.isActive);
  // });

  private showToast(message: string, obj: any) {
    return Toast.show({
      text: 'CAPACITOR App -  ' + message + ' - ' + JSON.stringify(obj)
    });
  }
}
