import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AudioService } from './audio.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'sonorus-audio-recorder',
  template: `
  <ion-grid>
    <ion-row>
      <ion-col>
        <canvas #visualizer height="60px"></canvas>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <!-- <ion-button color="primary"
                    (click)="play()">
            <ion-icon name="play-outline"></ion-icon>
        </ion-button> -->
        <ion-button color="danger"
                    (click)="record()"
                    [disabled]="recordDisabled">
            <ion-icon name="ellipse-outline"></ion-icon>
        </ion-button>
        <ion-button color="primary"
                    (click)="stopRecording()"
                    [disabled]="!recordDisabled">
            <ion-icon name="square-outline"></ion-icon>
        </ion-button>
        <!-- <ion-button color="warning"
                    (click)="deleteRecording()">
            <ion-icon slot="icon-only"
                      name="trash-outline"></ion-icon>
        </ion-button> -->
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <section #soundClips>
        </section>
      </ion-col>
    </ion-row>
  </ion-grid>
  `,
  styles: []
})
export class AudioComponent implements OnInit, AfterViewInit {

  @ViewChild('soundClips')
  soundClips: ElementRef<HTMLElement>;

  @ViewChild('visualizer')
  visualizer: ElementRef<HTMLCanvasElement>;

  recordDisabled: boolean;

  private audioCtx;
  private canvasCtx;
  private mediaRecorder: MediaRecorder;
  private chunks: BlobPart[];

  constructor(private audio: AudioService) { }

  ngOnInit() {
    if (navigator.mediaDevices.getUserMedia) {
      console.log('getUserMedia supported.');
      if (!MediaRecorder.isTypeSupported('audio/ogg; codecs=opus')) {
        throw new Error('audio/ogg; codecs=opus not supported');
      }

      const constraints = { audio: true };

      let onSuccess = (stream: MediaStream) => {
        this.chunks = [];

        this.mediaRecorder = new MediaRecorder(stream, {
          mimeType: 'audio/ogg; codecs=opus',
        });

        this.visualize(stream);

        this.mediaRecorder.onstop = (e) => {
          console.log("data available after MediaRecorder.stop() called.");

          const clipName = prompt('Enter a name for your sound clip?', 'My unnamed clip');

          const clipContainer = document.createElement('article');
          const clipLabel = document.createElement('p');
          const audio = document.createElement('audio');
          const deleteButton = document.createElement('button');

          clipContainer.classList.add('clip');
          audio.setAttribute('controls', '');
          deleteButton.textContent = 'Delete';
          deleteButton.className = 'delete';

          if (clipName === null) {
            clipLabel.textContent = 'My unnamed clip';
          } else {
            clipLabel.textContent = clipName;
          }

          clipContainer.appendChild(audio);
          clipContainer.appendChild(clipLabel);
          clipContainer.appendChild(deleteButton);
          this.soundClips.nativeElement.appendChild(clipContainer);

          audio.controls = true;
          const blob = new Blob(this.chunks, { 'type': 'audio/ogg; codecs=opus' });
          this.chunks = [];
          const audioURL = window.URL.createObjectURL(blob);
          audio.src = audioURL;
          console.log("recorder stopped");

          saveAs(blob, 'test.ogg');

          deleteButton.onclick = function (e: any) {
            let evtTgt = e.target;
            evtTgt.parentNode.parentNode.removeChild(evtTgt.parentNode);
          }

          clipLabel.onclick = function () {
            const existingName = clipLabel.textContent;
            const newClipName = prompt('Enter a new name for your sound clip?');
            if (newClipName === null) {
              clipLabel.textContent = existingName;
            } else {
              clipLabel.textContent = newClipName;
            }
          }
        }

        this.mediaRecorder.ondataavailable = (e: BlobEvent) => {
          debugger;
          this.chunks.push(e.data);
        }
      }

      let onError = function (err) {
        console.log('The following error occured: ' + err);
      }

      navigator.mediaDevices.getUserMedia(constraints).then(onSuccess, onError);

    } else {
      console.log('getUserMedia not supported on your browser!');
    }
  }

  ngAfterViewInit() {
    this.canvasCtx = this.visualizer.nativeElement.getContext('2d');
  }

  play() { }

  record() {
    this.mediaRecorder.start();
    console.log(this.mediaRecorder.state);
    console.log("recorder started");
    // record.style.background = "red";

    this.recordDisabled = true;
  }

  stopRecording() {
    this.mediaRecorder.stop();
    console.log(this.mediaRecorder.state);
    console.log("recorder stopped");
    // record.style.background = "";
    // record.style.color = "";
    // mediaRecorder.requestData();

    // stop.disabled = true;
    this.recordDisabled = false;
  }

  deleteRecording() { }

  visualize(stream) {
    if (!this.audioCtx) {
      this.audioCtx = new AudioContext();
    }

    const source = this.audioCtx.createMediaStreamSource(stream);

    const analyser = this.audioCtx.createAnalyser();
    analyser.fftSize = 2048;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    source.connect(analyser);
    //analyser.connect(audioCtx.destination);

    const draw = () => {
      const WIDTH = this.visualizer.nativeElement.width;
      const HEIGHT = this.visualizer.nativeElement.height;

      requestAnimationFrame(draw);

      analyser.getByteTimeDomainData(dataArray);

      this.canvasCtx.fillStyle = 'rgb(200, 200, 200)';
      this.canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

      this.canvasCtx.lineWidth = 2;
      this.canvasCtx.strokeStyle = 'rgb(0, 0, 0)';

      this.canvasCtx.beginPath();

      let sliceWidth = WIDTH * 1.0 / bufferLength;
      let x = 0;


      for (let i = 0; i < bufferLength; i++) {

        let v = dataArray[i] / 128.0;
        let y = v * HEIGHT / 2;

        if (i === 0) {
          this.canvasCtx.moveTo(x, y);
        } else {
          this.canvasCtx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      this.canvasCtx.lineTo(WIDTH, HEIGHT / 2);
      this.canvasCtx.stroke();

    }

    draw();

  }
}
