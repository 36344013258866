import { Injectable } from '@angular/core';
import { Plugins /*, HapticsImpactStyle */ } from '@capacitor/core';
import { DeviceDetectorService } from '../../services/device-detector.service';

const { Haptics } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class HapticsService {
  //https://capacitor.ionicframework.com/docs/apis/haptics/
  constructor(private deviceDetector: DeviceDetectorService) {}

  // hapticsImpact(style = HapticsImpactStyle.Heavy) {
  //   Haptics.impact({
  //     style: style
  //   });
  // }

  // hapticsImpactMedium(style) {
  //   this.hapticsImpact(HapticsImpactStyle.Medium);
  // }

  // hapticsImpactLight(style) {
  //   this.hapticsImpact(HapticsImpactStyle.Light);
  // }

  async hapticsVibrate() {
    if (await this.deviceDetector.isNative()) {
      Haptics.vibrate();
    } else if (navigator && navigator.vibrate) {
      navigator.vibrate(200);
    }
  }

  // hapticsSelectionStart() {
  //   Haptics.selectionStart();
  // }

  // hapticsSelectionChanged() {
  //   Haptics.selectionChanged();
  // }

  // hapticsSelectionEnd() {
  //   Haptics.selectionEnd();
  // }
}
